var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"programme-top"},[_c('div',{class:['title', { title2: _vm.$i18n.locale === 'en' }]},[_vm._v(" "+_vm._s(_vm.$t("programme.page1.title1"))+" ")]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("programme.page1.tips1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("programme.page1.tips2")))]),_c('button',[_vm._v(_vm._s(_vm.$t("programme.page1.btn")))])]),_c('div',{staticClass:"programme-second"},_vm._l((8),function(item){return _c('div',{staticClass:"card-item"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require(`@/assets/programme/card${item}.png`)}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(`programme.page2.title${item}`)))])]),_c('div',{staticClass:"tips-box"},[_vm._v(_vm._s(_vm.$t(`programme.page2.tips${item}`)))])])}),0),_c('div',{staticClass:"demand"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("survey.page4.title1")))]),_c('div',{staticClass:"boxs"},[_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '请输入企业名称'
            : 'Please enter the company name'
        }`},model:{value:(_vm.input1),callback:function ($$v) {_vm.input1=$$v},expression:"input1"}}),_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn' ? '请输入姓名' : 'Please enter your name'
        }`},model:{value:(_vm.input2),callback:function ($$v) {_vm.input2=$$v},expression:"input2"}}),_c('el-select',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn' ? '请选择行业' : 'Please select an industry'
        }`},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$i18n.locale === 'zn' ? item.label : item.elabel,"value":item.value}})}),1)],1),_c('div',{staticClass:"boxs"},[_c('el-select',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn' ? '请选择地区' : 'Please select a region'
        }`},model:{value:(_vm.value2),callback:function ($$v) {_vm.value2=$$v},expression:"value2"}},_vm._l((_vm.options2),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$i18n.locale === 'zn' ? item.label : item.elabel,"value":item.value}})}),1),_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '请输入手机号'
            : 'Please enter your phone number'
        }`},model:{value:(_vm.input3),callback:function ($$v) {_vm.input3=$$v},expression:"input3"}}),_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '请输入邮箱'
            : 'Please enter your email address'
        }`},model:{value:(_vm.input4),callback:function ($$v) {_vm.input4=$$v},expression:"input4"}})],1),_c('div',{staticClass:"boxs"},[_c('el-input',{staticClass:"textarea",attrs:{"type":"textarea","rows":2,"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '留下您的需求，获取解决方案'
            : 'Leave your needs and obtain solutions'
        }`},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}})],1),_c('button',{attrs:{"disabled":_vm.disabled},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$i18n.locale === "zn" ? "立即提交" : "Submit")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }