var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:['herder', { 'herder-white': _vm.scrollTop }]},[(!_vm.scrollTop)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo2.png")},on:{"click":function($event){return _vm.goUrl('/home', 1)}}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png")},on:{"click":function($event){return _vm.goUrl('/home', 1)}}}),_c('div',{staticClass:"navigation"},[_vm._l((_vm.navigation),function(item,index){return _c('div',{key:index,class:[
          'navigation-item',
          { 'navigation-img': item.type === 1 },
          {
            'navigation-language': item.type === 2,
          },
        ],on:{"click":function($event){return _vm.changeNavigation(item)}}},[(item.type === 2 && !_vm.scrollTop)?_c('img',{attrs:{"src":require("@/assets/language.png")}}):_vm._e(),(item.type === 2 && _vm.scrollTop)?_c('img',{attrs:{"src":require("@/assets/language2.png")}}):_vm._e(),(item.type !== 2)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(`navigation.${item.id}`)))]):_vm._e(),(item.type === 2)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("lang")))]):_vm._e(),(item.type === 1 && !_vm.scrollTop)?_c('img',{attrs:{"src":require("@/assets/arrow.png")}}):_vm._e(),(item.type === 1 && _vm.scrollTop)?_c('img',{attrs:{"src":require("@/assets/arrow2.png")}}):_vm._e(),(item.name === '产品中心')?_c('div',{class:['product-box', { 'product-box2': _vm.$i18n.locale === 'en' }]},_vm._l((_vm.product),function(Citem,CIndex){return _c('div',{key:CIndex,class:[
              'product-item',
              { 'product-active': _vm.history === Citem.path },
            ],on:{"click":function($event){$event.stopPropagation();return _vm.goUrl(Citem.path, 3)}}},[_c('svg-icon',{staticClass:"svg",attrs:{"icon-class":Citem.url}}),_c('span',[_vm._v(_vm._s(_vm.$i18n.locale === "zn" ? Citem.name : Citem.ename))])],1)}),0):_vm._e(),(item.name === '关于脉问')?_c('div',{class:['about-box', { 'about-box2': _vm.$i18n.locale === 'en' }]},_vm._l((_vm.about),function(Citem,Cindex){return _c('div',{key:Cindex,class:[
              'about-item',
              { 'about-active': _vm.history === Citem.path },
            ],on:{"click":function($event){$event.stopPropagation();return _vm.goUrl(Citem.path, 6)}}},[_c('svg-icon',{staticClass:"svg",attrs:{"icon-class":Citem.url}}),_c('span',[_vm._v(_vm._s(_vm.$i18n.locale === "zn" ? Citem.name : Citem.ename))])],1)}),0):_vm._e()])}),_c('div',{class:['line', `line-active${_vm.active}`]})],2)]),_c('div',{class:['mobile-terminal', { 'mobile-terminal-white': _vm.scrollTop }]},[(!_vm.scrollTop)?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo2.png")},on:{"click":function($event){return _vm.goUrl('/home', 1)}}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png")},on:{"click":function($event){return _vm.goUrl('/home', 1)}}}),_c('div',{on:{"click":function($event){$event.stopPropagation();_vm.mobileShow = true}}},[_c('svg-icon',{staticClass:"storage",attrs:{"icon-class":"storage"}})],1),(_vm.mobileShow)?_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-top"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("move.name")))]),_c('img',{staticClass:"close",attrs:{"src":require("@/assets/close.png")},on:{"click":function($event){$event.stopPropagation();_vm.mobileShow = false}}})]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"sidebar-box"},_vm._l((_vm.navigation),function(item,index){return (item.type !== 2)?_c('div',{key:index,staticClass:"sidebar-item",on:{"click":function($event){return _vm.changeNavigation(item)}}},[_c('div',{staticClass:"name-box",on:{"click":function($event){return _vm.changeRotate(index)}}},[_c('div',{class:['name', { 'name-active': _vm.active === item.active }]},[_vm._v(" "+_vm._s(_vm.$t(`navigation.${item.id}`))+" ")]),(item.type === 1)?_c('img',{class:['name-arrow', { 'name-arrow-rotate': item.rotate }],attrs:{"src":require("@/assets/move-arrow.png")}}):_vm._e()]),(item.name === '产品中心')?_c('div',{class:[
              'sidebar-product',
              { 'sidebar-product-zhan': item.rotate },
            ]},_vm._l((_vm.product),function(cItem,cIndex){return _c('div',{key:cIndex,class:[
                'product-item',
                { 'product-active': _vm.history === cItem.path },
              ],on:{"click":function($event){$event.stopPropagation();return _vm.goUrl(cItem.path, 3)}}},[_c('span',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.$i18n.locale === "zn" ? cItem.name : cItem.ename))])])}),0):_vm._e(),(item.name === '关于脉问')?_c('div',{class:[
              'sidebar-product',
              { 'sidebar-product-zhan': item.rotate },
            ]},_vm._l((_vm.about),function(cItem,cIndex){return _c('div',{key:cIndex,class:[
                'product-item',
                { 'product-active': _vm.history === cItem.path },
              ],on:{"click":function($event){$event.stopPropagation();return _vm.goUrl(cItem.path, 6)}}},[_c('span',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.$i18n.locale === "zn" ? cItem.name : cItem.ename))])])}),0):_vm._e()]):_vm._e()}),0),_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.changeLang},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e(),(_vm.mobileShow)?_c('div',{staticClass:"zhezhao",on:{"click":function($event){$event.stopPropagation();_vm.mobileShow = false}}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }