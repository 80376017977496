<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="news-top" >
      <div class="title">{{ $t("news.page1.title1") }}</div>
      <div class="absolute-box" v-if="this.superList.length > 0">
        <div
          class="left"
          :style="`background-image: url(${
            superList[0].thumbUrl
              ? superList[0].thumbUrl
              : require('@/assets/news/img1.png')
          });`"
          @click="jump(superList[0].id)"
        >
          <img class="img1" src="@/assets/news/img1.png" />

          <div class="synopsis-read">
            <div class="synopsis">{{ this.superList[0].title }}</div>
            <div class="read">{{ $t("news.page1.read") }}</div>
          </div>
        </div>
        <div class="right">
          <div
            class="line-box"
            v-for="(item, index) in this.superList"
            :key="index"
            @click="jump(item.id)"
          >
            <div class="time">{{ item.updateTime }}</div>
            <div class="content">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二屏 -->
    <div class="news-bottom">
      <el-tabs v-model="activeName">
        <el-tab-pane
          :label="$i18n.locale === 'zn' ? item.name : item.ename"
          :name="item.index"
          v-for="item in tabsList"
          :key="item.index"
        >
          <div class="card-boxs">
            <div
              class="card-item"
              v-for="(item, index) in cardList"
              :key="index"
              @click="jump(item.id)"
            >
              <img class="img2" v-if="item.thumbUrl" :src="item.thumbUrl" />
              <img class="img2" v-else src="@/assets/news/img2.png" />
              <div class="card-name">
                {{ item.title }}
              </div>
              <div class="card-time">
                {{ $t("news.page1.title2") }}: {{ item.updateTime }}
              </div>
              <div class="read-box">
                <div class="read">
                  {{ $t("news.page1.title3") }}
                </div>
                <img class="arrow" src="@/assets/news/arrow.png" />
              </div>
            </div>

            <el-empty
              v-if="cardList.length === 0"
              description="暂无数据"
            ></el-empty>
          </div>
          <div class="pagination-box" v-if="cardList.length !== 0">
            <el-pagination
              :hide-on-single-page="total <= 9"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="size"
              :current-page.sync="page"
              @size-change="getNews"
              @current-change="getNews"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getBlogs, getSuper } from "@/api/app";
export default {
  watch: {
    activeName(to, from) {
      console.log("to", to);
      if (to == 1) {
        this.year = "";
      } else {
        const yearBox = this.tabsList.find((item) => {
          return to == item.index;
        });
        this.year = yearBox.name;
        this.page = 1;
        this.size = 9;
        console.log("yearBox", yearBox);
      }

      this.getNews();
    },
  },
  data() {
    return {
      activeName: "1",
      tabsList: [
        {
          name: "全部",
          ename: "All",
          index: "1",
        },
        {
          name: "2023",
          ename: "2023",
          index: "2",
        },
        {
          name: "2022",
          ename: "2022",
          index: "3",
        },
        {
          name: "2021",
          ename: "2021",
          index: "4",
        },
        {
          name: "2020",
          ename: "2020",
          index: "5",
        },
        {
          name: "2019",
          ename: "2019",
          index: "6",
        },
        {
          name: "2018",
          ename: "2018",
          index: "7",
        },
      ],
      total: 100,
      cardList: [],
      superList: [],
      page: 1,
      size: 9,
      year: "",
      key: "",
    };
  },
  created() {
    this.getNews();
    getSuper().then((res) => {
      console.log("getSuper", res);
      if (res.status === 200) {
        this.superList = res.response;
      }
    });
  },
  methods: {
    getNews() {
      const params = {
        page: this.page,
        size: this.size,
        year: this.year,
        key: "",
      };
      getBlogs(params).then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          this.cardList = res.response.data;
          this.total = res.response.dataCount;
          this.page = res.response.page;
        }
      });
    },
    jump(id) {
      this.$router.push(`/newsDetails?id=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  .news-top {
    width: 100%;
    height: 422px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/news/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    .title {
      font-size: 38px;
      color: #fff;
      text-align: center;
      padding-top: 126px;
    }
    .absolute-box {
      position: absolute;
      top: 247px;
      left: 50%;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
      border-radius: 8px 8px 8px 8px;
      transform: translateX(-50%);
      padding: 50px 35px 56px 52px;
      display: flex;
      .left {
        width: 554px;
        height: 340px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        .synopsis-read {
          position: absolute;
          bottom: 100px;
          left: 77px;
          .read {
            margin-top: 10px;
          }
        }
        .img1 {
          display: none;
        }
      }
      .right {
        width: 311px;
        margin-left: 41px;
        .line-box {
          width: 311px;
          border-bottom: 1px solid #ededed;
          height: 33%;
          cursor: pointer;
          .time {
            font-size: 16px;
            color: #0b7cff;
            padding-top: 17px;
          }
          .time-first {
            padding: 0;
          }
          .content {
            margin-top: 14px;
            font-size: 14px;
            color: #232323;
          }
        }
      }
    }
  }
  .news-bottom {
    margin-top: 303px;
    width: 994px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 100px;
    .pagination-box {
      display: flex;
      .el-pagination {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .card-boxs {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-top: 24px;
      .card-item {
        width: 252px;
        min-height: 304px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #f4f4f4;
        flex-shrink: 0;
        margin-bottom: 45px;
        padding: 26px;
        margin-right: calc((100% - 756px) / 2);
        box-sizing: border-box;
        .img2 {
          width: 100%;
          height: 108px;
        }
        .card-name {
          margin-top: 34px;
          font-size: 14px;
          color: #232323;
          line-height: 22px;
        }
        .card-time {
          font-size: 14px;
          color: #999;
          margin-top: 9px;
        }
        .read-box {
          display: flex;
          justify-content: space-between;
          margin-top: 34px;
          color: #0b7cff;
          font-size: 14px;
          align-items: center;
          .arrow {
            width: 20px;
          }
        }
      }
      .card-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .el-empty {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
<style scoped>
.main >>> .el-tabs__active-bar {
  background-color: #0b7cff;
}
.main >>> .el-tabs__item.is-active {
  color: #0b7cff;
}
.main >>> .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #ededed;
}

.main >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0b7cff;
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1000px) {
  .main {
    .news-top {
      .title {
        font-size: 38px;
        color: #fff;
        text-align: center;
        padding-top: 126px;
      }
      .absolute-box {
        position: absolute;
        top: 247px;
        left: 50%;
        background: #ffffff;
        box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
        border-radius: 8px 8px 8px 8px;
        transform: translateX(-50%);
        padding: 50px 50px 50px 50px;
        display: block;
        flex-direction: column;
        .left {
          width: 554px;
          height: 340px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          font-size: 14px;
          color: #fff;
          position: relative;

          .synopsis-read {
            position: absolute;
            bottom: 25px;
            left: 27px;
            .read {
              margin-top: 10px;
            }
          }
        }
        .right {
          width: 100%;
          margin-left: 0;
          .line-box {
            width: 100%;
            border-bottom: 1px solid #ededed;
            height: 33%;
            .time {
              font-size: 16px;
              color: #0b7cff;
              padding-top: 17px;
            }
            .time-first {
              padding: 0;
            }
            .content {
              margin-top: 14px;
              font-size: 14px;
              color: #232323;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .news-bottom {
      margin-top: 600px;
      width: 654px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 100px;
      .pagination-box {
        display: flex;
        .el-pagination {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .card-boxs {
        display: flex;
        flex-wrap: wrap;
        padding-top: 24px;
        box-sizing: border-box;
        .card-item {
          width: 252px;
          min-height: 304px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #f4f4f4;
          flex-shrink: 0;
          margin-bottom: 45px;
          padding: 26px;
          margin-right: calc(100% - 504px);
          .img2 {
            width: 100%;
            height: 108px;
          }
          .card-name {
            margin-top: 34px;
            font-size: 14px;
            color: #232323;
            line-height: 22px;
          }
          .card-time {
            font-size: 14px;
            color: #999;
            margin-top: 9px;
          }
          .read-box {
            display: flex;
            justify-content: space-between;
            margin-top: 34px;
            color: #0b7cff;
            font-size: 14px;
            align-items: center;
            .arrow {
              width: 20px;
            }
          }
        }
        .card-item:nth-child(3n) {
          margin-right: calc(100% - 504px);
        }
        .card-item:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .main {
    .news-top {
      .title {
        font-size: 20px;
        color: #fff;
        text-align: center;
        padding-top: 126px;
      }
      .absolute-box {
        position: absolute;
        top: 247px;
        left: 50%;
        background: #ffffff;
        box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
        border-radius: 8px 8px 8px 8px;
        transform: translateX(-50%);
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        .left {
          width: 100%;
          height: auto;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          font-size: 14px;
          color: #fff;
          position: relative;
          .synopsis-read {
            position: absolute;
            bottom: 25px;
            left: 27px;
            .read {
              margin-top: 10px;
            }
          }
          .img1 {
            display: block;
            width: 100%;
            height: auto;
          }
        }
        .right {
          width: 100%;
          margin-left: 0;
          .line-box {
            width: 100%;
            border-bottom: 1px solid #ededed;
            height: 33%;
            .time {
              font-size: 16px;
              color: #0b7cff;
              padding-top: 17px;
            }
            .time-first {
              padding: 0;
            }
            .content {
              margin-top: 14px;
              font-size: 14px;
              color: #232323;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .news-bottom {
      margin-top: 600px;
      width: calc(100% - 80px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 10px;
      .pagination-box {
        .el-pagination {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .card-boxs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 24px;
        .card-item {
          width: 252px;
          height: auto;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #f4f4f4;
          flex-shrink: 0;
          margin-bottom: 45px;
          padding: 26px;
          margin-right: 0;
          .img2 {
            width: 100%;
            height: 108px;
          }
          .card-name {
            margin-top: 34px;
            font-size: 14px;
            color: #232323;
            line-height: 22px;
          }
          .card-time {
            font-size: 14px;
            color: #999;
            margin-top: 9px;
          }
          .read-box {
            display: flex;
            justify-content: space-between;
            margin-top: 34px;
            color: #0b7cff;
            font-size: 14px;
            align-items: center;
            .arrow {
              width: 20px;
            }
          }
        }
        .card-item:nth-child(3n) {
          margin-right: 0;
        }
        .card-item:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 711px) {
  .main {
    .news-bottom {
      margin-top: 530px;
      width: calc(100% - 100px);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 10px;
      .card-boxs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 24px;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .main {
    .news-bottom {
      margin-top: 500px;
    }
  }
}
@media only screen and (max-width: 550px) {
  .main {
    .news-bottom {
      margin-top: 450px;
    }
  }
}
@media only screen and (max-width: 450px) {
  .main {
    .news-bottom {
      margin-top: 400px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .main {
    .news-bottom {
      margin-top: 350px;
    }
  }
}
</style>