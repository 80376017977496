<template>
  <div class="main">
    <!-- 样本库 -->
    <div class="home-top">
      <!-- <img class="bg1" src="@/assets/home/bg1.png" /> -->
      <div class="home-box">
        <div class="title-blod">{{ $t("home.page1.title1") }}</div>
        <div class="title-tips">
          {{ $t("home.page1.title2") }}
        </div>
        <div class="buttons">
          <button @click="jump('/contactUs')">{{ $t("home.page1.btn1") }}</button>
          <button @click="jump('/contactUs')" class="btn-right">{{ $t("home.page1.btn2") }}</button>
        </div>
      </div>
    </div>
    <!-- 关于我们的服务 -->
    <div class="about-service">
      <div class="title">{{ $t("home.about.title1") }}</div>
      <div class="langen" v-if="$i18n.locale === 'zn'">ABOUT OUR SERVICES</div>
      <div class="card-box">
        <div class="card-item" v-for="(item, index) in cardList" :key="index">
          <svg-icon class="svg" :icon-class="item.icon"></svg-icon>
          <div :class="['name', { namelang: $i18n.locale === 'en' }]">
            {{ $t(`home.about.${item.boxId}.name`) }}
          </div>
          <div class="tips">{{ $t(`home.about.${item.boxId}.tips`) }}</div>
        </div>
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="core-advantages">
      <img class="core" src="@/assets/home/core.png" />
      <div class="title">{{ $t("home.core.title1") }}</div>
      <div class="langen" v-if="$i18n.locale === 'zn'">CORE ADVANTAGES</div>
      <div class="lunbo">
        <div class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, inedx) in swiperList"
              :key="inedx + 1000"
            >
              <div class="slide-content">
                <img class="left" :src="item.url1" />
                <div class="right">
                  <div class="right-top">
                    <div class="title">
                      {{ $t(`home.core.${item.titie}.title`) }}
                    </div>
                    <img class="right-img" :src="item.url2" />
                  </div>
                  <div class="right-top2">
                    <img class="right-img" :src="item.url2" />
                    <div class="title">
                      {{ $t(`home.core.${item.titie}.title`) }}
                    </div>
                  </div>
                  <div class="right-bottom">
                    <p :class="[{ 'p-zn': $i18n.locale === 'zn' }]">
                      {{ $t(`home.core.${item.titie}.p1`) }}
                    </p>
                    <p>{{ $t(`home.core.${item.titie}.p2`) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img class="lunbo-right" src="@/assets/home/lunbo-right.png" />
          <img class="lunbo-left" src="@/assets/home/lunbo-left.png" />
        </div>
      </div>
      <div class="lunbo2">
        <div class="swiper mySwiper2">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, inedx) in swiperList"
              :key="inedx"
            >
              <div class="slide-content">
                <img class="left" :src="item.url1" />
                <div class="right">
                  <div class="right-top">
                    <div class="title">
                      {{ $t(`home.core.${item.titie}.title`) }}
                    </div>
                    <img class="right-img" :src="item.url2" />
                  </div>
                  <div class="right-top2">
                    <img class="right-img" :src="item.url2" />
                    <div class="title">
                      {{ $t(`home.core.${item.titie}.title`) }}
                    </div>
                  </div>
                  <div class="right-bottom">
                    <p :class="[{ 'p-zn': $i18n.locale === 'zn' }]">
                      {{ $t(`home.core.${item.titie}.p1`) }}
                    </p>
                    <p>{{ $t(`home.core.${item.titie}.p2`) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div :class="['bg', { 'bg-chrome': chrome }]"></div>
    </div>
    <!-- 样本数量 -->
    <div class="samples-number">
      <div class="title">{{ $t("home.samples.title1") }}</div>
      <div class="langen" v-if="$i18n.locale === 'zn'">
        SAMPLE SIZE COVERING THE WORLD
      </div>
      <div class="map-box">
        <img src="@/assets/home/map.png" />

        <div
          class="positioning"
          v-for="(item, index) in positioningList"
          :key="index"
          :style="`left:${item.left}px;top:${item.top}px;`"
        >
          <Ripple :width1="item.width1" :width2="item.width2"></Ripple>
        </div>
        <div
          class="positioning2"
          v-for="(item, index) in positioningList2"
          :key="index + 11"
          :style="`left:${item.left}px;top:${item.top}px;`"
        >
          <Ripple :width1="item.width1" :width2="item.width2"></Ripple>
        </div>
        <div
          class="positioning3"
          v-for="(item, index) in positioningList3"
          :key="index + 31"
          :style="`left:${item.left}px;top:${item.top}px;`"
        >
          <Ripple :width1="item.width1" :width2="item.width2"></Ripple>
        </div>
      </div>
      <div class="number-box">
        <div class="title-box" v-for="(item, index) in numberList" :key="index">
          <div class="number">{{ item.Number }}</div>
          <div class="title">{{ $t(`home.samples.map.${item.titie}`) }}</div>
        </div>
      </div>
    </div>
    <!-- 样本数据 -->
    <div class="samples-data">
      <div class="title">{{ $t("home.samplesData.title1") }}</div>
      <div class="samples-box">
        <div class="left">
          <div
            class="img-box"
            v-for="item in 8"
            :key="item"
            :class="[{ 'bottom-none': item >= 7 }]"
          >
            <img :src="require(`@/assets/home/brand-${item}.png`)" />
          </div>
        </div>
        <div class="middle">
          <div class="number">1500+</div>
          <div class="title2">{{ $t("home.samplesData.title2") }}</div>
          <div class="title3">{{ $t("home.samplesData.title3") }}</div>
          <button>{{ $t("home.samplesData.title4") }}</button>
        </div>
        <div class="right">
          <div
            class="img-box"
            v-for="item in 8"
            :key="item"
            :class="[{ 'bottom-none': item >= 15 }]"
          >
            <img :src="require(`@/assets/home/brand-${item + 8}.png`)" />
          </div>
        </div>
      </div>
      <div class="samples-box2">
        <div class="left">
          <div
            class="img-box"
            v-for="item in 7"
            :key="item"
            :class="[{ 'bottom-none': item >= 7 }]"
          >
            <img :src="require(`@/assets/home/brand-${item}.png`)" />
          </div>
        </div>
        <div class="middle">
          <div class="middle-box">
            <div class="number">1500+</div>
            <div class="title2">{{ $t("home.samplesData.title2") }}</div>
            <div class="title3">{{ $t("home.samplesData.title3") }}</div>
            <button>{{ $t("home.samplesData.title4") }}</button>
          </div>
          <div
            class="img-box"
            v-for="item in 2"
            :key="item"
            :class="[{ 'bottom-none': item >= 2 }]"
          >
            <img :src="require(`@/assets/home/brand-${item + 14}.png`)" />
          </div>
        </div>
        <div class="right">
          <div
            class="img-box"
            v-for="item in 7"
            :key="item"
            :class="[{ 'bottom-none': item >= 14 }]"
          >
            <img :src="require(`@/assets/home/brand-${item + 7}.png`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Ripple from "@/components/Ripple";
export default {
  name: "home",
  components: { Ripple },
  data() {
    return {
      cardList: [
        {
          icon: "sample",
          boxId: "box1",
        },
        {
          icon: "service",
          boxId: "box2",
        },
        {
          icon: "positioning",
          boxId: "box3",
        },
        {
          icon: "programme",
          boxId: "box4",
        },
      ],
      swiperList: [
        {
          url1: require("@/assets/home/lunbo1.png"),
          url2: require("@/assets/home/index1.png"),
          titie: "box1",
        },
        {
          url1: require("@/assets/home/lunbo2.png"),
          url2: require("@/assets/home/index2.png"),
          titie: "box2",
        },
        {
          url1: require("@/assets/home/lunbo3.png"),
          url2: require("@/assets/home/index3.png"),
          titie: "box3",
        },
        {
          url1: require("@/assets/home/lunbo4.png"),
          url2: require("@/assets/home/index4.png"),
          titie: "box4",
        },
      ],
      positioningList: [
        {
          left: 224,
          top: 157,
          width1: 32,
          width2: 16,
        },
        {
          left: 209,
          top: 184,
          width1: 14,
          width2: 6,
        },
        {
          left: 330,
          top: 133,
          width1: 14,
          width2: 6,
        },
        {
          left: 287,
          top: 278,
          width1: 32,
          width2: 16,
        },
        {
          left: 748,
          top: 140,
          width1: 32,
          width2: 16,
        },
        {
          left: 507,
          top: 226,
          width1: 32,
          width2: 16,
        },
        {
          left: 648,
          top: 95,
          width1: 24,
          width2: 12,
        },
        {
          left: 752,
          top: 85,
          width1: 32,
          width2: 16,
        },
        {
          left: 839,
          top: 329,
          width1: 14,
          width2: 6,
        },
      ],
      positioningList2: [
        {
          left: 152,
          top: 106,
          width1: 21,
          width2: 11,
        },
        {
          left: 142,
          top: 125,
          width1: 9,
          width2: 4,
        },
        {
          left: 224,
          top: 90,
          width1: 9,
          width2: 4,
        },
        {
          left: 195,
          top: 189,
          width1: 21,
          width2: 11,
        },
        {
          left: 580,
          top: 95,
          width1: 21,
          width2: 11,
        },
        {
          left: 344,
          top: 153,
          width1: 21,
          width2: 11,
        },
        {
          left: 440,
          top: 64,
          width1: 16,
          width2: 8,
        },
        {
          left: 511,
          top: 57,
          width1: 21,
          width2: 11,
        },
        {
          left: 570,
          top: 223,
          width1: 9,
          width2: 4,
        },
      ],
      positioningList3: [
        {
          left: 76,
          top: 53,
          width1: 11,
          width2: 6,
        },
        {
          left: 71,
          top: 63,
          width1: 5,
          width2: 3,
        },
        {
          left: 112,
          top: 45,
          width1: 5,
          width2: 3,
        },
        {
          left: 96,
          top: 95,
          width1: 11,
          width2: 6,
        },
        {
          left: 290,
          top: 48,
          width1: 11,
          width2: 6,
        },
        {
          left: 172,
          top: 77,
          width1: 11,
          width2: 6,
        },
        {
          left: 220,
          top: 32,
          width1: 8,
          width2: 4,
        },
        {
          left: 255,
          top: 29,
          width1: 11,
          width2: 6,
        },
        {
          left: 285,
          top: 112,
          width1: 5,
          width2: 3,
        },
      ],
      numberList: [
        {
          Number: "400w+",
          titie: "title1",
        },
        { Number: "4000w+", titie: "title2" },
        { Number: "25+", titie: "title3" },
      ],
      chrome: false,
    };
  },
  methods: {
    scrollToTop() {
      var userAgent = navigator.userAgent; // 获取User Agent字符串
      if (userAgent.indexOf("Chrome") !== -1) {
        this.chrome = true;
        return true; // 如果User Agent字符串中包含"Google Chrome"则返回true表示是谷歌浏览器
      } else {
        return false; // 否则返回false表示不是谷歌浏览器
      }
    },
    jump(path){
      this.$router.push(path)
    },
  },
  mounted() {
    console.log(
      "1111",
      document.getElementsByClassName("mySwiper"),
      document.getElementsByClassName("mySwiper2")
    );
    if (document.getElementsByClassName("mySwiper")) {
      new Swiper(".mySwiper", {
        spaceBetween: 30,
        centeredSlides: true,
        loop: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: ".lunbo-right",
          prevEl: ".lunbo-left",
        },
      });
    }
    if (document.getElementsByClassName("mySwiper2")) {
      new Swiper(".mySwiper2", {
        spaceBetween: 30,
        centeredSlides: true,
        loop: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
    this.scrollToTop();
  },
};
</script>

<style lang="scss" scoped>
.main {
  .home-top {
    width: 100%;
    height: 100vh;
    min-height: 500px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/home/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    // .bg1 {
    //   width: 100%;
    //   height: 100%;
    // }
    .home-box {
      position: absolute;
      top: 0;
      width: calc(100% - 60px);
      padding: 0 30px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-blod {
        font-size: 60px;
        color: #fff;
      }
      .title-tips {
        font-size: 18px;
        color: #fff;
        margin-top: 20px;
      }
      .buttons {
        font-size: 16px;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        button {
          padding: 0;
          margin: 0;
          width: 136px;
          height: 40px;
          border-radius: 23px 23px 23px 23px;
          border: 1px solid #ffffff;
          background-color: rgba(0, 0, 0, 0);
          color: #fff;
          cursor: pointer;
        }
        .btn-right {
          margin-left: 178px;
          background-color: #0b7cff;
          border-color: #0b7cff;
        }
      }
    }
  }
  .about-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 73px;
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
    }
    .langen {
      font-size: 22px;
      color: #ebebeb;
      margin-top: 11px;
    }
    .card-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 55px;
      .card-item {
        width: 237px;
        height: 360px;
        border-radius: 8px 8px 8px 8px;
        margin-right: 16px;
        color: #232323;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px;
        transition: all 0.3s;
        .svg {
          width: 54px;
          height: 54px;
          margin-top: 45px;
          text-align: center;
        }
        .name {
          font-size: 28px;
          font-weight: bolder;
          margin-top: 20px;
          text-align: center;
          width: 200px;
        }
        .namelang {
          font-size: 20px;
        }
        .tips {
          font-size: 13px;
          color: #666666;
          margin-top: 40px;
        }
      }
      .card-item:last-child {
        margin-right: 0;
      }
      .card-item:hover {
        background: #0b7cff;
        color: #fff;
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
        .tips {
          color: #fff;
        }
      }
    }
  }

  .core-advantages {
    margin-top: 99px;
    width: calc(100% - 240px);
    margin-left: 120px;
    position: relative;

    .core {
      width: 36px;
      height: auto;
    }
    .title {
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      text-align: center;
    }
    .langen {
      font-size: 22px;
      color: #ebebeb;
      margin-top: 11px;
      text-align: center;
    }
    .lunbo {
      position: relative;
      width: 100%;
      height: 430px;
      margin-top: 36px;
      margin-bottom: 50px;
      overflow: hidden;
    }
    .lunbo2 {
      display: none;
      position: relative;
      width: 100%;
      height: 430px;
      margin-top: 36px;
      margin-bottom: 50px;
      overflow: hidden;
      padding-bottom: 50px;
    }
    .bg {
      position: absolute;
      left: -120px;
      top: 27px;
      width: 100vw;
      height: 415px;
      background-color: #f7fbff;
      z-index: -1;
    }
    .bg-chrome {
      width: calc(100vw - 18px) !important;
    }
  }
  .samples-number {
    margin-top: 99px;
    .title {
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      text-align: center;
    }
    .langen {
      font-size: 22px;
      color: #999999;
      margin-top: 11px;
      text-align: center;
    }
    .map-box {
      width: 1026px;
      height: 434px;
      margin-top: 60px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
      }
      .positioning {
        position: absolute;
        z-index: 99;
      }
      .positioning2 {
        position: absolute;
        z-index: 99;
        display: none;
      }
      .positioning3 {
        position: absolute;
        z-index: 99;
        display: none;
      }
    }
    .number-box {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      .title-box {
        margin-right: 250px;
        .number {
          color: #f83838;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 2px;
          text-align: center;
        }
        .title {
          font-size: 18px;
          color: #232323;
          text-align: center;
          margin-top: 24px;
        }
      }
      .title-box:last-child {
        margin-right: 0;
      }
    }
  }
  .samples-data {
    margin-top: 99px;
    background: #f7fbff;
    padding-top: 90px;
    padding-bottom: 90px;
    .title {
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      text-align: center;
    }
    .samples-box {
      display: flex;
      width: calc(100% - 240px);
      justify-content: space-between;
      margin-left: 120px;
      margin-top: 20px;
      .left {
        width: calc((100% - 300px) / 2);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          width: 202px;
          height: 70px;
          margin-bottom: 36px;
        }
        .bottom-none {
          margin-bottom: 0;
        }
      }
      .middle {
        width: 300px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .number {
          color: #0b7cff;
          font-size: 57px;
          font-weight: bold;
        }
        .title2 {
          font-size: 16px;
          color: #232323;
          margin-top: 26px;
        }
        .title3 {
          font-size: 16px;
          color: #232323;
          margin-top: 16px;
          text-align: center;
        }
        button {
          padding: 0;
          margin: 0;
          border: none;
          width: 138px;
          height: 36px;
          background: #0b7cff;
          border-radius: 2px 2px 2px 2px;
          color: #fff;
          font-size: 14px;
          margin-top: 38px;
          cursor: pointer;
        }
      }
      .right {
        width: calc((100% - 300px) / 2);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .img-box {
          width: 202px;
          height: 80px;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 36px;
          img {
            width: 202px;
            height: 70px;
          }
        }
        .bottom-none {
          margin-bottom: 0;
        }
      }
    }
    .samples-box2 {
      display: none;
      width: calc(100% - 240px);
      justify-content: space-between;
      margin-left: 120px;
      margin-top: 20px;
      .left {
        width: calc((100% - 300px) / 2);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          width: 202px;
          height: 70px;
          margin-bottom: 36px;
        }
        .bottom-none {
          margin-bottom: 0;
        }
      }
      .middle {
        width: 300px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .number {
          color: #0b7cff;
          font-size: 57px;
          font-weight: bold;
        }
        .title2 {
          font-size: 16px;
          color: #232323;
          margin-top: 26px;
        }
        .title3 {
          font-size: 16px;
          color: #232323;
          margin-top: 16px;
          text-align: center;
        }
        button {
          padding: 0;
          margin: 0;
          border: none;
          width: 138px;
          height: 36px;
          background: #0b7cff;
          border-radius: 2px 2px 2px 2px;
          color: #fff;
          font-size: 14px;
          margin-top: 38px;
          cursor: pointer;
        }
      }
      .right {
        width: calc((100% - 300px) / 2);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .img-box {
          width: 202px;
          height: 80px;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 36px;
          img {
            width: 202px;
            height: 70px;
          }
        }
        .bottom-none {
          margin-bottom: 0;
        }
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  .slide-content {
    width: calc(100% - 200px);
    background: #fff;
    height: calc(100% - 50px);
    margin-top: 25px;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    border-radius: 4px;
    .left {
      width: 438px;
      height: 283px;
      margin-left: 66px;
      flex-shrink: 0;
      border-radius: 4px;
    }
    .right {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-left: 57px;
      margin-right: 60px;
      position: relative;
      width: 100%;
      .right-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 30px;
        .title {
          font-size: 18px;
          color: #232323;
          font-weight: bolder;
        }
        .right-img {
          width: 94px;
          height: 94px;
        }
      }
      .right-top2 {
        display: none;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 30px;
        .title {
          font-size: 18px;
          color: #232323;
          font-weight: bolder;
        }
        .right-img {
          width: 94px;
          height: 94px;
        }
      }
      .right-bottom {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 0 50px 0;
        p {
          text-align: left;
          font-size: 14px;
          color: #333333;
        }
        .p-zn {
          margin-top: 30px;
        }
      }
    }
  }
}

.swiper-slide img {
  display: block;
  width: 500px;
  height: 200px;
  object-fit: cover;
}
.lunbo-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 99;
  width: 50px;
  height: 50px;
}
.lunbo-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 99;
  width: 50px;
  height: 50px;
}
.swiper-pagination {
  position: absolute;
  bottom: -30px;
}
</style>

<style scoped>
/* 鼠标涟漪效果 */
.circle {
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 8px;
  border: 1px solid red;
  height: 16px;
  width: 16px;
  background: orange;
}
.ripples {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  animation: ripplesone 1s 2s infinite;
}
@keyframes ripplesone {
  0% {
    transform-origin: 8px 8px;
    transform: scale(1);
    opacity: 0;
    border: 2px solid #ff3434;
  }

  5% {
    transform-origin: 8px 8px;
    transform: scale(1.1);
    opacity: 1;
    border: 2px solid #ff3434;
  }

  100% {
    transform-origin: 8px 8px;
    transform: scale(1.5);
    opacity: 0;
    border: 2px solid #ff3434;
  }
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 1350px) {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-content {
      width: calc(100% - 200px);
      background: #fff;
      height: calc(100% - 50px);
      margin-top: 25px;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      border-radius: 4px;
      .left {
        width: 438px;
        height: 283px;
        margin-left: 20px;
        flex-shrink: 0;
        border-radius: 4px;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 20px;
        margin-right: 20px;
        position: relative;
        width: 100%;
        .right-top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 30px;
          .title {
            font-size: 18px;
            color: #232323;
            font-weight: bolder;
          }
          .right-img {
            width: 94px;
            height: 94px;
          }
        }
        .right-bottom {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0 50px 0;
          p {
            text-align: left;
            font-size: 14px;
            color: #333333;
          }
          .p-zn {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .main {
    .core-advantages {
      .lunbo {
        height: auto;
      }
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-content {
      width: calc(100% - 200px);
      background: #fff;
      // height: calc(100% - 50px);
      height: auto;
      margin-top: 50px;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      .left {
        width: calc(100% - 100px);
        height: auto;
        margin-left: 50px;
        margin-right: 50px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-top: 25px;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 50px;
        position: relative;
        flex-shrink: 0;
        width: calc(100% - 100px);
        .right-top {
          display: none;
        }
        .right-top2 {
          display: flex;
          justify-content: flex-start;
          .title {
            font-size: 18px;
            color: #232323;
            font-weight: bolder;
            margin-left: 10px;
          }
          .right-img {
            width: 94px;
            height: 94px;
          }
        }
        .right-bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0 50px 0;
          p {
            text-align: left;
            font-size: 14px;
            color: #333333;
          }
          .p-zn {
            margin-top: 0;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1026px) {
  .main {
    .samples-number {
      .map-box {
        width: 700px;
        height: 296px;
        .positioning {
          display: none;
        }
        .positioning2 {
          display: block;
        }
      }
      .number-box {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .title-box {
          margin-right: 100px;
          .number {
            color: #f83838;
            font-size: 40px;
            font-weight: bold;
            letter-spacing: 2px;
            text-align: center;
          }
          .title {
            font-size: 18px;
            color: #232323;
            text-align: center;
            margin-top: 24px;
          }
        }
        .title-box:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .main {
    .home-top {
      .home-box {
        .title-blod {
          font-size: 30px;
          color: #fff;
        }
        .title-tips {
          font-size: 15px;
        }
        .buttons {
          button {
            width: 110px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .main {
    .core-advantages {
      width: calc(100% - 80px);
      margin-left: 40px;
      .lunbo {
        display: none;
      }
      .lunbo2 {
        display: block;
        height: auto;
      }
    }
    .samples-data {
      padding-bottom: 45px;
      margin-top: 50px;
      .samples-box {
        display: none;
      }
      .samples-box2 {
        display: flex;
        width: calc(100% - 80px);
        margin-left: 40px;
        .left {
          width: 33%;
          flex-direction: column;
          align-items: center;
          .img-box {
            width: 202px;
            height: 80px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 36px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .middle {
          width: 33%;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          .img-box {
            width: 202px;
            height: 80px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 36px;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .middle-box {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .number {
              color: #0b7cff;
              font-size: 57px;
              font-weight: bold;
            }
            .title2 {
              font-size: 16px;
              color: #232323;
              margin-top: 26px;
            }
            .title3 {
              font-size: 16px;
              color: #232323;
              margin-top: 16px;
              text-align: center;
            }
          }
        }
        .right {
          width: 33%;
          flex-direction: column;
          align-items: center;
          .img-box {
            width: 202px;
            height: 80px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 36px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-content {
      width: 100%;
      background: #fff;
      // height: calc(100% - 50px);
      height: auto;
      margin-top: 50px;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      .left {
        width: calc(100% - 100px);
        height: auto;
        margin-left: 50px;
        margin-right: 50px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-top: 25px;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 50px;
        position: relative;
        flex-shrink: 0;
        width: calc(100% - 100px);
        .right-top {
          display: none;
        }
        .right-top2 {
          display: flex;
          justify-content: flex-start;
          .title {
            font-size: 18px;
            color: #232323;
            font-weight: bolder;
            margin-left: 10px;
          }
          .right-img {
            width: 94px;
            height: 94px;
          }
        }
        .right-bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0 50px 0;
          p {
            text-align: left;
            font-size: 14px;
            color: #333333;
          }
          .p-zn {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .main {
    .home-top {
      height: 350px;
      min-height: 350px;
      .home-box {
        .title-blod {
          font-size: 22px;
          color: #fff;
        }
        .title-tips {
          font-size: 13px;
        }
        .buttons {
          button {
            width: 90px;
            height: 25px;
            font-size: 12px;
          }
        }
      }
    }
    .about-service {
      .title {
        font-size: 20px;
        margin-top: 40px;
      }
      .card-box {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 55px;
        .card-item {
          width: calc((100% - 140px) / 2);
          height: 360px;
          border-radius: 8px;
          margin-right: 20px;
          color: #232323;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 25px;
          transition: all 0.3s;
          .svg {
            width: 54px;
            height: 54px;
            margin-top: 45px;
            text-align: center;
          }
          .name {
            font-size: 28px;
            font-weight: bolder;
            margin-top: 20px;
            text-align: center;
            width: 200px;
          }
          .namelang {
            font-size: 20px;
          }
          .tips {
            font-size: 13px;
            color: #666666;
            margin-top: 40px;
          }
        }
        .card-item:nth-child(2n) {
          margin-right: 0;
        }
        .card-item:hover {
          background: #0b7cff;
          color: #fff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
          .tips {
            color: #fff;
          }
        }
      }
    }
    .core-advantages {
      .title {
        font-size: 20px;
      }
      .langen {
        font-size: 16px;
      }
    }
    .samples-number {
      .title {
        font-size: 20px;
      }
      .langen {
        font-size: 16px;
      }
      .map-box {
        width: 355px;
        height: 140px;
        .positioning {
          display: none;
        }
        .positioning2 {
          display: none;
        }
        .positioning3 {
          display: block;
        }
      }
      .number-box {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .title-box {
          margin-right: 40px;
          .number {
            font-size: 16px;
          }
          .title {
            font-size: 14px;
            margin-top: 22px;
          }
        }
        .title-box:last-child {
          margin-right: 0;
        }
      }
    }
    .samples-data {
      padding-bottom: 45px;
      margin-top: 50px;
      .title {
        font-size: 20px;
      }
      .samples-box {
        display: none;
      }
      .samples-box2 {
        display: flex;
        width: calc(100% - 40px);
        margin-left: 20px;
        .left {
          width: 33%;
          flex-direction: column;
          align-items: center;
          .img-box {
            width: 103px;
            height: 34px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 36px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .middle {
          width: 33%;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          .img-box {
            width: 103px;
            height: 34px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 36px;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .middle-box {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .number {
              color: #0b7cff;
              font-size: 28px;
              font-weight: bold;
            }
            .title2 {
              font-size: 12px;
              color: #232323;
              margin-top: 26px;
            }
            .title3 {
              font-size: 12px;
              color: #232323;
              margin-top: 16px;
              text-align: center;
            }
          }
        }
        .right {
          width: 33%;
          flex-direction: column;
          align-items: center;
          .img-box {
            width: 103px;
            height: 34px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 36px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-content {
      width: 100%;
      background: #fff;
      // height: calc(100% - 50px);
      height: auto;
      margin-top: 30px;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      .left {
        width: calc(100% - 40px);
        height: auto;
        margin-left: 20px;
        margin-right: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-top: 25px;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 20px;
        position: relative;
        flex-shrink: 0;
        width: calc(100% - 40px);
        .right-top {
          display: none;
        }
        .right-top2 {
          display: flex;
          justify-content: flex-start;
          .title {
            font-size: 18px;
            color: #232323;
            font-weight: bolder;
            margin-left: 10px;
          }
          .right-img {
            width: 80px;
            height: 80px;
          }
        }
        .right-bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0 20px 0;
          p {
            text-align: left;
            font-size: 14px;
            color: #333333;
          }
          .p-zn {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-content {
      width: 100%;
      background: #fff;
      // height: calc(100% - 50px);
      height: auto;
      margin-top: 30px;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      .left {
        width: calc(100% - 40px);
        height: auto;
        margin-left: 20px;
        margin-right: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-top: 25px;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 20px;
        position: relative;
        flex-shrink: 0;
        width: calc(100% - 40px);
        .right-top {
          display: none;
        }
        .right-top2 {
          display: flex;
          justify-content: flex-start;
          .title {
            font-size: 18px;
            color: #232323;
            font-weight: bolder;
            margin-left: 10px;
          }
          .right-img {
            width: 35px;
            height: 35px;
          }
        }
        .right-bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0 20px 0;
          p {
            text-align: left;
            font-size: 14px;
            color: #333333;
          }
          .p-zn {
            margin-top: 0;
          }
        }
      }
    }
  }

  .main {
    .about-service {
      .card-box {
        margin-top: 55px;
        .card-item {
          height: auto;
          margin-bottom: 20px;
          padding-bottom: 20px;
          .svg {
            width: 30px;
            height: 30px;
            margin-top: 35px;
            text-align: center;
          }
          .name {
            font-size: 16px;
            font-weight: bolder;
            margin-top: 20px;
            text-align: center;
            width: 200px;
          }
          .namelang {
            font-size: 16px;
          }
          .tips {
            font-size: 13px;
            color: #666666;
            margin-top: 40px;
          }
        }
        .card-item:nth-child(2n) {
          margin-right: 0;
        }
        .card-item:hover {
          background: #0b7cff;
          color: #fff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
          .tips {
            color: #fff;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-content {
      width: 100%;
      background: #fff;
      // height: calc(100% - 50px);
      height: auto;
      margin-top: 30px;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      .left {
        width: calc(100% - 40px);
        height: auto;
        margin-left: 20px;
        margin-right: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-top: 25px;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 20px;
        position: relative;
        flex-shrink: 0;
        width: calc(100% - 40px);
        .right-top {
          display: none;
        }
        .right-top2 {
          display: flex;
          justify-content: flex-start;
          .title {
            font-size: 14px;
            color: #232323;
            font-weight: bolder;
            margin-left: 10px;
          }
          .right-img {
            width: 20px;
            height: 20px;
          }
        }
        .right-bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0 20px 0;
          p {
            text-align: left;
            font-size: 12px;
            color: #333333;
          }
          .p-zn {
            margin-top: 0;
          }
        }
      }
    }
  }
  .main {
    .about-service {
      .card-box {
        margin-top: 55px;
        .card-item {
          height: auto;
          margin-bottom: 20px;
          padding-bottom: 20px;
          .svg {
            width: 26px;
            height: 26px;
            text-align: center;
          }
          .name {
            font-size: 14px;
          }
          .namelang {
            font-size: 12px;
          }
          .tips {
            font-size: 12px;
            color: #666666;
            margin-top: 40px;
          }
        }
        .card-item:nth-child(2n) {
          margin-right: 0;
        }
        .card-item:hover {
          background: #0b7cff;
          color: #fff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
          .tips {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>