import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";
import sample from "../views/sample.vue";
import programme from "../views/programme.vue";
import news from "../views/news.vue";
import aboutUs from "../views/aboutUs.vue";
import contactUs from "../views/contactUs.vue";
import joinUs from "../views/joinUs.vue";
import privacyPolicy from "../views/privacyPolicy.vue";
import newsDetails from "../views/newsDetails.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/home",
    meta: { title: "Maven Survey", eTitle: "Maven Survey" },
  },
  {
    path: "/home",
    name: "home",
    component: home,
    meta: { title: "首页", eTitle: "Home" },
  },
  {
    path: "/sample",
    name: "sample",
    component: sample,
    meta: { title: "样本服务", eTitle: "Sample" },
  },
  {
    path: "/consumer",
    name: "consumer",
    component: () => import("@/views/consumer.vue"),
    meta: {
      title: "产品中心-消费者调研社区",
      eTitle: "Product Center - Consumer research community",
    },
  },
  {
    path: "/symposium",
    name: "symposium",
    component: () => import("@/views/symposium.vue"),
    meta: {
      title: "产品中心-在线座谈会系统",
      eTitle: "Product Center - Online Symposium System",
    },
  },
  {
    path: "/program",
    name: "program",
    component: () => import("@/views/program.vue"),
    meta: {
      title: "产品中心-问卷编程系统",
      eTitle: "Product Center - Questionnaire programming system",
    },
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("@/views/survey.vue"),
    meta: {
      title: "产品中心-定制化调研社区",
      eTitle: "Product Center - Customized research community",
    },
  },
  {
    path: "/programme",
    name: "programme",
    component: programme,
    meta: { title: " 解决方案", eTitle: "Solution" },
  },
  {
    path: "/news",
    name: "news",
    component: news,
    meta: { title: " 新闻中心", eTitle: "News Center" },
  },
  {
    path: "/newsDetails",
    name: "newsDetails",
    component: newsDetails,
    meta: { title: " 新闻内容", eTitle: "News Content" },
  },

  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
    meta: { title: " 关于我们", eTitle: "About Us" },
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: contactUs,
    meta: { title: " 联系我们", eTitle: "Contact Us" },
  },
  {
    path: "/joinUs",
    name: "joinUs",
    component: joinUs,
    meta: { title: " 加入我们", eTitle: "Join us" },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy,
    meta: { title: " 隐私政策", eTitle: "Privacy Policy" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
