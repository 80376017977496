<template>
  <div
    class="label"
    v-html="value"
    :style="`font-size:${size}px;top:${top}px;left:${left}px`"
  ></div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 36,
    },
    top: {
      type: Number,
      default: 16,
    },
    left: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.label {
  position: absolute;
  color: #666666;
}
</style>
<style scoped>
.label >>> span {
  font-weight: bold;
  color: #232323;
}
</style>
