<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="about-top">
      <div class="title">ABOUT US</div>
      <div class="tips">{{ $t("aboutUs.page1.tips1") }}</div>
    </div>
    <!-- 我们的宗旨 -->
    <div class="purpose">
      <div class="title">{{ $t("aboutUs.page2.title1") }}</div>
      <div class="line"></div>
      <div class="tips">{{ $t("aboutUs.page2.tips1") }}</div>
      <div class="tips">{{ $t("aboutUs.page2.tips2") }}</div>
      <div class="tips">{{ $t("aboutUs.page2.tips3") }}</div>
    </div>
    <!-- 获奖证书 -->
    <div class="certificate">
      <div class="title">{{ $t("aboutUs.page3.title1") }}</div>
      <div class="line"></div>
      <div class="tips">{{ $t("aboutUs.page3.tips1") }}</div>
      <div class="boxs">
        <div class="box-item" v-for="item in 4" :key="item">
          <img
            :class="['img1', { img2: item === 3 }, { img3: item === 4 }]"
            :src="require(`@/assets/aboutUs/certificate${item}.png`)"
          />
          <div class="title-box">{{ $t(`aboutUs.page3.box.name${item}`) }}</div>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="develop">
      <div class="title">{{ $t("aboutUs.page4.title1") }}</div>
      <div class="line"></div>
      <img class="en" src="@/assets/aboutUs/en1.png" />

      <div class="move-box">
        <div class="move-content" ref="move">
          <div class="move-line"></div>
          <el-tooltip
            popper-class="popper-tps"
            :placement="item.placement"
            :effect="item.effect"
            v-for="(item, index) in circleList"
            :key="index"
            v-if="index >= leftActive && index <= rightActive"
          >
            <div
              slot="content"
              v-html="$i18n.locale === 'zn' ? item.content : item.econtent"
            ></div>
            <div
              class="circle-fa"
              :style="`left:${margins + unit + unit * index * 2}px`"
            >
              <div class="number">
                {{ $i18n.locale === "zn" ? item.number : item.enumber }}
              </div>

              <div :class="['circle']">
                <div class="circle-center"></div>
              </div>
            </div>
          </el-tooltip>
        </div>
        <div class="left-btn" :style="`left:${margins}px`" @click="left">
          <svg-icon class="svg" icon-class="left-btn"></svg-icon>
        </div>
        <div class="right-btn" :style="`right:${margins}px`" @click="right">
          <svg-icon class="svg" icon-class="right-btn"></svg-icon>
        </div>
      </div>
      <div class="move-box2" v-if="this.width <= 600">
        <div class="move-line"></div>
        <el-tooltip
          popper-class="popper-tps"
          placement="right"
          :effect="item.effect"
          :manual="true"
          v-for="(item, index) in circleList"
          :key="index"
          v-model="isTrue"
        >
          <div
            slot="content"
            v-html="$i18n.locale === 'zn' ? item.content : item.econtent"
          ></div>
          <div class="circle-fa" :style="`top:${150 * index + 215}px`">
            <div class="number">
              {{ $i18n.locale === "zn" ? item.number : item.enumber }}
            </div>

            <div :class="['circle']">
              <div class="circle-center"></div>
            </div>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    "$i18n.locale"() {
      const circleList = JSON.parse(JSON.stringify(this.circleList));
      this.circleList = [];
      setTimeout(() => {
        this.circleList = circleList;
      }, 100);
    },
  },
  data() {
    return {
      visible: true,
      active: 0,
      circleList: [
        {
          visible: true,
          effect: "dark",
          number: "2016",
          enumber: "2016",
          placement: "top",
          content: "<p>样本网站上线</p><p>用户突破50万 定性调研系统上线</p>",
          econtent:
            "<p>Sample website launched</p><p>User breakthrough of 500000 qualitative research system launched</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "2017",
          enumber: "2017",
          placement: "bottom",
          content:
            "<p>微信服务号上线</p><p>与阿里众包达成流量合作协议,中国市场研究协会会员单位</p>",
          econtent:
            "<p>WeChat service account goes online</p><p>Reached a traffic cooperation agreement with Alibaba crowdsourcing, a member unit of the China Association for Market Research</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "2018",
          enumber: "2018",
          placement: "top",
          content: "<p>成为Kantar核心供应商，同年 用户突破150万人次</p>",
          econtent:
            "<p>Becoming a core supplier of Kantar, with over 1.5 million users in the same year</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "2019",
          enumber: "2019",
          placement: "bottom",
          content:
            "<p>与今日头条达成流量合作战略合作协议 ，同年用户突破280万人次</p>",
          econtent:
            "<p>Reached a strategic cooperation agreement on traffic cooperation with Today's Headlines, with over 2.8 million users in the same year</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "2020",
          enumber: "2020",
          placement: "top",
          content: "<p>南京分公司成立</p>",
          econtent: "<p>Establishment of Nanjing Branch</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "2021",
          enumber: "2021",
          placement: "bottom",
          content: "<p>全球样本库建立</p><p>11月用户突破380w</p>",
          econtent:
            "<p>Global sample library establishment</p><p>In November, the number of users exceeded 380000</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "2022",
          enumber: "2022",
          placement: "top",
          content: "<p>专业医生样本 “医享谈”上线 </p>",
          econtent:
            "<p>Professional Doctor Sample 'Medical Experience Talk' Launched</p>",
        },
        {
          visible: false,
          effect: "dark",
          number: "未来",
          enumber: "future",
          placement: "bottom",
          content: "<p>期待与您合作</p>",
          econtent: "Looking forward to working with you",
        },
      ],

      margins: 120, //边距
      number: 5, //中间显示格数
      unit: null, //单位距离
      leftActive: 0,
      rightActive: 4,
      options1: [
        {
          value: 1,
          label: "互联网",
          elabel: "internet",
        },
        {
          value: 2,
          label: "医疗",
          elabel: "medical treatment",
        },
      ],
      options2: [
        {
          value: 1,
          label: "亚洲",
          elabel: "Asia",
        },
        {
          value: 2,
          label: "欧洲",
          elabel: "Europe",
        },
      ],
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      value1: "",
      value2: "",
      textarea: "",
      isTrue: true,
      width: null,
    };
  },
  methods: {
    scrollToTop() {
      const width =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.width = width;
      if (width > 1000) {
        this.margins = 120;
        this.rightActive = 4;
        this.number = 5;
      }
      if (750 <= width < 1000) {
        this.margins = 40;
        this.rightActive = 4;
        this.number = 5;
      }
      if (width < 750) {
        this.number = 4;
        this.rightActive = 3;
      }
      this.unit = (width - this.margins * 2 - 67) / (this.number * 2);
    },
    changeActive(value) {
      this.circleList.forEach((_, index, array) => {
        if (value === index) {
          array[index].visible = true;
        } else {
          array[index].visible = false;
        }
        this.active = value;
      });
    },
    left() {
      if (this.leftActive === 0) {
        return;
      }
      this.leftActive--;
      this.rightActive--;
      const circleList = JSON.parse(JSON.stringify(this.circleList));
      this.circleList = [];
      setTimeout(() => {
        this.circleList = circleList;
      }, 100);
      this.$refs.move.style.transform = `translateX(${
        -this.unit * this.leftActive * 2
      }px)`;
    },
    right() {
      if (this.rightActive === 7) {
        return;
      }
      this.leftActive++;
      this.rightActive++;
      const circleList = JSON.parse(JSON.stringify(this.circleList));
      this.circleList = [];
      setTimeout(() => {
        this.circleList = circleList;
      }, 100);
      this.$refs.move.style.transform = `translateX(${
        -this.unit * this.leftActive * 2
      }px)`;
    },
  },
  mounted() {
    window.addEventListener("resize", this.scrollToTop, true);
    if (!this.unit) {
      this.scrollToTop();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scrollToTop, true);
  },
};
</script>

<style lang="scss" scoped>
.main {
  // 第一屏
  .about-top {
    width: 100%;
    height: 414px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/aboutUs/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      // padding-top: 119px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .tips {
      text-align: center;
      color: #fff;
      margin-top: 18px;
      font-size: 16px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  // 我们的宗旨
  .purpose {
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
      margin-top: 85px;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 55px;
    }
    .tips {
      text-align: center;
      width: 824px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      line-height: 30px;
      font-size: 16px;
      color: #232323;
    }
  }
  // 获奖证书
  .certificate {
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
      margin-top: 90px;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 16px;
    }
    .tips {
      text-align: center;
      width: 824px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: #232323;
      font-weight: bolder;
    }
    .boxs {
      width: calc(100% - 240px);
      margin-left: 120px;
      display: flex;
      justify-content: space-between;
      margin-top: 81px;
      .box-item {
        width: 287px;
        height: 184px;
        background: #ffffff;
        opacity: 1;
        border: 1px solid #ededed;
        display: flex;
        align-items: center;
        .img1 {
          width: 140px;
          margin-left: 10px;
          flex-shrink: 0;
        }
        .img2 {
          width: 161px;
        }
        .img3 {
          width: 113px;
        }
        .title-box {
          font-size: 16px;
          padding: 0 12px;
          text-align: center;
          color: #333333;
        }
      }
    }
  }
  //发展历程
  .develop {
    width: 100%;
    height: 640px;
    background-image: url("../assets/aboutUs/bg2.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: 114px;
    position: relative;
    margin-bottom: 100px;
    .title {
      position: relative;
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
      padding-top: 85px;
      z-index: 1;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 55px;
      z-index: 1;
    }
    .en {
      position: absolute;
      width: 272px;
      height: 50px;
      left: 50%;
      transform: translateX(-50%);
      top: 110px;
      z-index: 0;
    }
    .move-box {
      width: 100%;
      overflow-x: hidden;
      height: 450px;
      position: relative;
      .move-content {
        position: absolute;
        height: 450px;
        transition: transform 0.5s;
        .move-line {
          width: 2200px;
          height: 2px;
          background-color: #e5e5e5;
          margin-top: 243px;
        }
        .circle-fa {
          position: absolute;
          top: 190px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50px;
          .number {
            color: #666666;
            font-size: 20px;
            margin-bottom: 14px;
          }
          .circle {
            width: 28px;
            height: 28px;
            background: rgba(201, 201, 201, 0.5);
            border-radius: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            .circle-center {
              width: 10px;
              height: 10px;
              background: #9a9a9a;
              border-radius: 5px;
              opacity: 1;
            }
          }
          .circle:hover {
            background-color: rgba(11, 124, 255, 0.5);
            .circle-center {
              background-color: #0b7cff;
            }
          }
          .active {
            background-color: rgba(11, 124, 255, 0.5);
            .circle-center {
              background-color: #0b7cff;
            }
          }
        }
      }
      .left-btn {
        width: 38px;
        height: 38px;
        background-color: #dddddd;
        border-radius: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #aaa6a6;
        cursor: pointer;
        position: absolute;
        top: 224px;
        // left: 120px;
      }
      .left-btn:hover {
        color: #fff;
        background-color: #0b7cff;
      }
      .right-btn {
        width: 38px;
        height: 38px;
        background-color: #dddddd;
        border-radius: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #aaa6a6;
        cursor: pointer;
        position: absolute;
        top: 224px;
        // right: 120px;
      }
      .right-btn:hover {
        color: #fff;
        background-color: #0b7cff;
      }
    }
    .move-box2 {
      display: none;
      padding-bottom: 40px;
      .move-line {
        height: 1050px;
        width: 2px;
        background-color: #e5e5e5;
        margin-top: 150px;
        margin-left: 100px;
      }
      .circle-fa {
        position: absolute;
        top: 190px;
        left: 10px;
        display: flex;
        align-items: center;
        .number {
          color: #666666;
          font-size: 20px;
          width: 77px;
          text-align: center;
        }
        .circle {
          width: 28px;
          height: 28px;
          background: rgba(201, 201, 201, 0.5);
          border-radius: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          .circle-center {
            width: 10px;
            height: 10px;
            background: #9a9a9a;
            border-radius: 5px;
            opacity: 1;
          }
        }
        .circle:hover {
          background-color: rgba(11, 124, 255, 0.5);
          .circle-center {
            background-color: #0b7cff;
          }
        }
        .active {
          background-color: rgba(11, 124, 255, 0.5);
          .circle-center {
            background-color: #0b7cff;
          }
        }
      }
    }
  }
  // 联系我们
  .contact {
    width: 100%;
    height: 859px;
    position: relative;
    .title {
      position: relative;
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
      padding-top: 106px;
      z-index: 1;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 55px;
      z-index: 1;
    }
    .en {
      position: absolute;
      width: 162px;
      height: 36px;
      left: 50%;
      transform: translateX(-50%);
      top: 140px;
      z-index: 0;
    }
    .address-box {
      display: flex;
      width: 909px;
      position: relative;
      justify-content: space-between;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 90px;
      margin-bottom: 60px;
      .left {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 500px;
        .address {
          width: 38px;
          height: 38px;
        }
        .title-box {
          display: flex;
          flex-direction: column;
          margin-left: 31px;
          .visit {
            font-size: 16px;
            color: #232323;
            font-weight: bolder;
          }
          .address-title {
            font-size: 16px;
            color: #232323;
            margin-top: 5px;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .emali {
          width: 38px;
          height: 38px;
        }
        .title-box {
          display: flex;
          flex-direction: column;
          margin-left: 31px;
          .visit {
            font-size: 16px;
            color: #232323;
            font-weight: bolder;
          }
          .emali-title {
            font-size: 16px;
            color: #232323;
            margin-top: 5px;
          }
        }
      }
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      width: 909px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 30px;
      .el-input {
        width: 287px;
      }
      .el-select {
        width: 287px;
      }
      .textarea {
        width: 100%;
      }
    }
    button {
      padding: 0;
      margin: 0;
      border: none;
      width: 186px;
      height: 50px;
      background: #0b7cff;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 16px;
      margin-top: 40px;
    }
  }
}
</style>
<style scoped>
.textarea >>> .el-textarea__inner {
  min-height: 178px !important;
  /* box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05); */
  border-color: #e5e5e5;
}
.textarea >>> .el-textarea__inner:hover {
  border-color: #0b7cff;
}
.textarea >>> .el-textarea__inner:focus {
  border-color: #0b7cff;
}
.main >>> .el-input__inner {
  border-color: #e5e5e5;
  /* box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05); */
}
.main >>> .el-input__inner:hover {
  border-color: #0b7cff;
}
.main >>> .el-input__inner:focus {
  border-color: #0b7cff;
}
</style>
<style>
.popper-tps.el-tooltip__popper {
  background: #ffffff;
}
/* // 深色系可以添加一个类 is-dark */
.popper-tps.el-tooltip__popper.is-dark {
  background: #0b7cff;
  color: #fff;
  font-size: 14px;
  max-width: 220px !important;
}

/* // 系可以添加一个类 is-light */

.popper-tps.el-tooltip__popper[x-placement^="top"] .popper__arrow:after,
.popper-tps.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #0b7cff;
}
.popper-tps.el-tooltip__popper[x-placement^="bottom"] .popper__arrow:after,
.popper-tps.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #0b7cff !important;
}
.popper-tps.el-tooltip__popper[x-placement^="right"] .popper__arrow:after,
.popper-tps.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-right-color: #0b7cff !important;
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 1400px) {
  .main {
    // 获奖证书
    .certificate {
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 90px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 16px;
      }
      .tips {
        text-align: center;
        width: 824px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        color: #232323;
        font-weight: bolder;
      }
      .boxs {
        width: calc(100% - 80px);
        margin-left: 40px;
        display: flex;
        justify-content: space-between;
        margin-top: 81px;
        .box-item {
          width: 287px;
          height: 184px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #ededed;
          display: flex;
          align-items: center;
          .img1 {
            width: 140px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .img2 {
            width: 161px;
          }
          .img3 {
            width: 113px;
          }
          .title-box {
            font-size: 16px;
            padding: 0 12px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .main {
    // 获奖证书
    .certificate {
      .boxs {
        width: calc(100% - 240px);
        margin-left: 120px;
        display: flex;
        justify-content: space-between;
        margin-top: 81px;
        flex-wrap: wrap;
        .box-item {
          width: 287px;
          height: 184px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #ededed;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img1 {
            width: 140px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .img2 {
            width: 161px;
          }
          .img3 {
            width: 113px;
          }
          .title-box {
            font-size: 16px;
            padding: 0 12px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .main {
    // 我们的宗旨
    .purpose {
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 85px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 55px;
      }
      .tips {
        text-align: center;
        width: 600px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        line-height: 30px;
        font-size: 16px;
        color: #232323;
      }
    }
    // 获奖证书
    .certificate {
      .title {
        font-size: 20px;
      }
      .tips {
        width: 500px;
      }
      .boxs {
        width: calc(100% - 80px);
        margin-left: 40px;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        flex-wrap: wrap;
        .box-item {
          width: 287px;
          height: 184px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #ededed;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img1 {
            width: 140px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .img2 {
            width: 161px;
          }
          .img3 {
            width: 113px;
          }
          .title-box {
            font-size: 16px;
            padding: 0 12px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }

    // 联系我们
    .contact {
      width: 100%;
      height: auto;
      position: relative;
      .title {
        position: relative;
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        padding-top: 106px;
        z-index: 1;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 55px;
        z-index: 1;
      }
      .en {
        position: absolute;
        width: 162px;
        height: 36px;
        left: 50%;
        transform: translateX(-50%);
        top: 140px;
        z-index: 0;
      }
      .address-box {
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 60px;
        margin-bottom: 30px;
        .left {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 500px;
          .address {
            width: 38px;
            height: 38px;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            margin-left: 31px;
            .visit {
              font-size: 16px;
              color: #232323;
              font-weight: bolder;
            }
            .address-title {
              font-size: 16px;
              color: #232323;
              margin-top: 5px;
            }
          }
        }
        .right {
          width: 500px;
          display: flex;
          margin-top: 20px;
          align-items: center;
          flex-shrink: 0;
          .emali {
            width: 38px;
            height: 38px;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            margin-left: 31px;
            .visit {
              font-size: 16px;
              color: #232323;
              font-weight: bolder;
            }
            .emali-title {
              font-size: 16px;
              color: #232323;
              margin-top: 5px;
            }
          }
        }
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 500px;
          margin-bottom: 20px;
        }
        .el-select {
          width: 500px;
          margin-bottom: 20px;
        }
        .textarea {
          width: 500px;
          margin-bottom: 20px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 186px;
        height: 50px;
        background: #0b7cff;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .main {
    // 第一屏
    .about-top {
      width: 100%;
      height: 414px;
      background-color: #000;
      position: relative;
      background-image: url("../assets/aboutUs/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        // padding-top: 119px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .tips {
        text-align: center;
        color: #fff;
        margin-top: 18px;
        font-size: 14px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    // 我们的宗旨
    .purpose {
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 50px;
      }
      .tips {
        text-align: center;
        width: calc(100% - 40px);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        line-height: 30px;
        font-size: 14px;
        color: #232323;
      }
    }
    // 获奖证书
    .certificate {
      .tips {
        width: calc(100% - 40px);
      }
      .boxs {
        width: calc(100% - 240px);
        margin-left: 120px;
        display: flex;
        justify-content: center;
        margin-top: 81px;
        flex-wrap: wrap;
        .box-item {
          width: 287px;
          height: 184px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #ededed;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img1 {
            width: 140px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .img2 {
            width: 161px;
          }
          .img3 {
            width: 113px;
          }
          .title-box {
            font-size: 16px;
            padding: 0 12px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }

    //发展历程
    .develop {
      width: 100%;
      height: 640px;
      background-image: url("../assets/aboutUs/bg2.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 40px;
      position: relative;
      .title {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        padding-top: 40px;
        z-index: 1;
      }
    }

    // 联系我们
    .contact {
      width: 100%;
      height: auto;
      position: relative;
      .title {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        padding-top: 106px;
        z-index: 1;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 55px;
        z-index: 1;
      }
      .en {
        position: absolute;
        width: 162px;
        height: 36px;
        left: 50%;
        transform: translateX(-50%);
        top: 140px;
        z-index: 0;
      }
      .address-box {
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 60px;
        margin-bottom: 30px;
        .left {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 500px;
          .address {
            width: 30px;
            height: 30px;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            margin-left: 31px;
            .visit {
              font-size: 14px;
              color: #232323;
              font-weight: bolder;
            }
            .address-title {
              font-size: 14px;
              color: #232323;
              margin-top: 5px;
            }
          }
        }
        .right {
          width: 500px;
          display: flex;
          margin-top: 20px;
          align-items: center;
          flex-shrink: 0;
          .emali {
            width: 30px;
            height: 30px;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            margin-left: 31px;
            .visit {
              font-size: 14px;
              color: #232323;
              font-weight: bolder;
            }
            .emali-title {
              font-size: 14px;
              color: #232323;
              margin-top: 5px;
            }
          }
        }
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 500px;
          margin-bottom: 20px;
        }
        .el-select {
          width: 500px;
          margin-bottom: 20px;
        }
        .textarea {
          width: 500px;
          margin-bottom: 20px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 150px;
        height: 40px;
        background: #0b7cff;
        border-radius: 20px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .main {
    // 获奖证书
    .certificate {
      .boxs {
        width: calc(100% - 80px);
        margin-left: 40px;
        display: flex;
        justify-content: center;
        margin-top: 81px;
        flex-wrap: wrap;
        .box-item {
          width: 287px;
          height: 184px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #ededed;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img1 {
            width: 140px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .img2 {
            width: 161px;
          }
          .img3 {
            width: 113px;
          }
          .title-box {
            font-size: 16px;
            padding: 0 12px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }
    //发展历程
    .develop {
      height: auto;
      .move-box {
        display: none;
      }
      .move-box2 {
        display: block;
      }
    }

    // 联系我们
    .contact {
      width: 100%;
      height: auto;
      position: relative;
      .title {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        padding-top: 106px;
        z-index: 1;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 55px;
        z-index: 1;
      }
      .en {
        position: absolute;
        width: 162px;
        height: 36px;
        left: 50%;
        transform: translateX(-50%);
        top: 140px;
        z-index: 0;
      }
      .address-box {
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 60px;
        margin-bottom: 30px;
        .left {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 300px;
          .address {
            width: 30px;
            height: 30px;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            margin-left: 31px;
            .visit {
              font-size: 14px;
              color: #232323;
              font-weight: bolder;
            }
            .address-title {
              font-size: 14px;
              color: #232323;
              margin-top: 5px;
            }
          }
        }
        .right {
          width: 300px;
          display: flex;
          margin-top: 20px;
          align-items: center;
          flex-shrink: 0;
          .emali {
            width: 30px;
            height: 30px;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            margin-left: 31px;
            .visit {
              font-size: 14px;
              color: #232323;
              font-weight: bolder;
            }
            .emali-title {
              font-size: 14px;
              color: #232323;
              margin-top: 5px;
            }
          }
        }
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 300px;
          margin-bottom: 20px;
        }
        .el-select {
          width: 300px;
          margin-bottom: 20px;
        }
        .textarea {
          width: 300px;
          margin-bottom: 20px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 150px;
        height: 40px;
        background: #0b7cff;
        border-radius: 20px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }
  }
}
@media only screen and (max-width: 520px) {
  .main {
    // 获奖证书
    .certificate {
      .boxs {
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: center;
        margin-top: 81px;
        flex-wrap: wrap;
        .box-item {
          width: 287px;
          height: 184px;
          background: #ffffff;
          opacity: 1;
          border: 1px solid #ededed;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img1 {
            width: 140px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .img2 {
            width: 161px;
          }
          .img3 {
            width: 113px;
          }
          .title-box {
            font-size: 16px;
            padding: 0 12px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }

    //发展历程
    .develop {
      width: 100%;
      height: auto;
      background-image: url("../assets/aboutUs/bg2.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 40px;
      position: relative;
      .title {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        padding-top: 40px;
        z-index: 1;
      }
    }
  }
}
</style>