import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Pagination,
  Select,
  Option,
  Input,
  Tooltip,
  Empty,
} from "element-ui";

// 语言切换
import i18n from "./lang";

import "@/icons";

// 路由处理
import "./permission";

Vue.config.productionTip = false;

Vue.use(Table)
  .use(TableColumn)
  .use(Tabs)
  .use(TabPane)
  .use(Pagination)
  .use(Select)
  .use(Option)
  .use(Input)
  .use(Tooltip)
  .use(Empty);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
