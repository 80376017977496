export default {
  lang: "CN",
  move: {
    name: "Navigation",
  },
  navigation: {
    home: "Home",
    sample: "Sample",
    product: "Product",
    programme: "Solution",
    news: "News",
    about: "About",
  },
  home: {
    page1: {
      title1: "40 million+ global online sample library",
      title2:
        "Large and diverse membership, with a wide and authentic representation, essentially representing the characteristics of Chinese internet users.",
      btn1: "Learn more",
      btn2: "Contact Us",
    },
    about: {
      title1: "About our services",
      box1: {
        name: "Sample Collection Service",
        tips: "Online sample collection, survey programming, cross-tabulation, online and offline recruitment, and data collection services.",
      },
      box2: {
        name: "Research Analysis Services",
        tips: "Quantitative/Qualitative Services, Survey Design, Data Research Reports, Industry Research, Brand Research.",
      },
      box3: {
        name: "Customized Community Services",
        tips: "Tailor-Made - Starting from your actual needs, providing a comprehensive set of services, including community operations services.",
      },
      box4: {
        name: "Customized IT Solutions",
        tips: "Leveraging industry expertise and strong technical background to provide enterprises with customized IT solutions.",
      },
    },
    core: {
      title1: "Core Competencies",
      box1: {
        title: "Full Dataset",
        p1: "A composite sample structure with a focus on ordinary consumer samples, while also incorporating various types of professional sample libraries.",
        p2: "Including sample libraries from 60+ countries and regions globally, encompassing diverse populations such as doctors, vehicle owners, mothers, and infants, ensuring authenticity and reliability.",
      },
      box2: {
        title: "Technology-driven",
        p1: "Robust technological support significantly enhances product innovation capabilities.",
        p2: "With a background in a technical team and two decades of industry expertise, we provide professional IT technology solutions to the industry.",
      },
      box3: {
        title: "One-stop service",
        p1: "One-stop service, from questionnaire design to report output.",
        p2: "We offer one-stop market research services, from questionnaire design, survey programming, data processing to research reports, enabling clients to quickly grasp market trends.",
      },
      box4: {
        title: "Customized R&D",
        p1: "Starting from the real needs of the enterprise, we create a customized research platform tailored to each business.",
        p2: "With our experienced IT technical team, we can customize a research platform and provide tailored system development services for businesses.",
      },
    },
    samples: {
      title1: "The sample quantity covers the entire globe.",
      map: {
        title1: "Complete sample size",
        title2: "Global sample size",
        title3: "Country Region",
      },
    },
    samplesData: {
      title1: "Helping numerous customers obtain sample data",
      title2: "Customer Cases",
      title3: "Serving customers in various industries",
      title4: "All customers",
    },
  },
  footer: {
    box1: {
      title1: "About Us",
      title2: "Company Profile",
      title3: "Contact Us",
      title4: "Join us",
      title5: "Privacy Policy",
    },
    box2: {
      title1: "Product Center",
      title2: "Consumer research community",
      title3: "Questionnaire system",
      title4: "Online Symposium System",
      title5: "Customized research community",
    },
    box3: {
      title1: "Solution",
      title2: "Sample collection service",
      title3: "Research and analysis services",
      title4: "Customized community services",
      title5: "IT customized solutions",
    },
    box4: {
      title1: "contact details",
      title2: "WeChat",
    },
  },
  sample: {
    page1: {
      title1: "High quality sample service",
      title2: "Accurate research data",
      title3:
        "All members undergo mobile identity verification, including information on car owners' brand and model, targeting the mother-infant demographic, ensuring the authenticity and reliability of the samples.",
    },
    page2: {
      title1: "Mobile Samples",
      title2: "Rapid recycling",
      title3:
        "One hour can easily complete a research project with 2000 samples",
      title4:
        "With our proprietary membership platform, we offer businesses rapid sample support through instant invitations.",
    },
    page3: {
      rotate: {
        title1: "W",
        title2: "Own samples",
      },
      left: {
        title1: "100 million e-commerce samples",
        title2: "We cooperate with top domestic e-commerce companies",
        title3:
          "Precise sampling can be conducted based on user shopping behavior at Jinding",
        labels: {
          title1: "<span>Searched</span> dehumidifier ",
          title2: "<span>Searched</span> Shower Gel",
          title3: "<span>Browsed</span> face cream",
          title4: "<span>Purchased</span> electric toothbrush",
          title5:
            "Apply lipstick<span> Add to shopping cart，</span> But no<span> purchase</span>",
          title6: "<span>Added purchase</span> Facial cleanser",
          title7: "<span>Purchased</span> Smartphone",
          title8: "<span>Collected</span> microwave oven",
          title9: "<span>Collected</span> microwave oven",
          title10: "<span>Collected</span> microwave oven",
        },
      },
      right: {
        title1: "DSP streaming",
        title2: "We cooperate with top domestic traffic platforms",
        title3:
          "Precise sampling can be conducted based on user behavior at Jinding",
        labels: {
          title1: "<span>WeChat</span>",
          title2: "<span>Today's Headlines</span>",
          title3: "<span>Tencent news</span>",
          title4: "<span>Tiktok</span>",
          title5: "<span>Baidu</span>",
          title6: "<span>JD.com</span>",
          title7: "MicroBlog",
          title8: "<span>Youku Video</span>",
          title9: "<span>bilibili</span>",
          title10: "<span>Kwai</span>",
          title11: "tencent qq",
        },
      },
    },
    page4: {
      title1:
        "Sample of over 2 million car owners (including over 500000 new energy car owners)",
      title2:
        "Data Authority, Comprehensive Coverage, User Activity, Stable Growth, Direct Sample Access, and Technological Improvement",
      name1: "Automobile Country",
      name2: "auto brand",
      name3: "Car models",
      name4: "Vehicle segmentation",
      name5: "Car price range",
      name6: "buy car and use car",
      tips1: {
        p1: "domestic",
        p2: "German",
        p3: "solar system",
        p4: "American style",
        p5: "Korean",
      },
      tips2: {
        p1: "VW",
        p2: "Toyota",
        p3: "Honda",
        p4: "Benz",
        p5: "BMW",
      },
      tips3: {
        p1: "Sedan",
        p2: "SUV",
        p3: "MPV",
        p4: "sports car",
      },
      tips4: {
        p1: "Compact",
        p2: "Mid-Size",
        p3: "Full-size",
      },
      tips5: {
        p1: "5～10w",
        p2: "10～20w",
        p3: "20～30w",
        p4: "50～100w",
        p5: "Over 1 million",
      },
      tips6: {
        p1: "Purchase time",
        p2: "Car buy channels",
        p3: "Life",
        p4: "Vehicle frequency",
        p5: "4S store information",
      },
    },
    page5: {
      title1: "Sample data from some countries",
      data: [
        {
          flag: "flag1",
          country: "China",
          samplesNumber: "3,874,547",
          male: "2,014,764",
          woman: "1,859,783",
          age1: "658,673",
          age2: "1,007,382",
          age3: "1,511,073",
          age4: "697,418",
        },
        {
          flag: "flag2",
          country: "Taiwan",
          samplesNumber: "655,328",
          male: "327,664",
          woman: "327,664",
          age1: "131,066",
          age2: "242,471",
          age3: "203,152",
          age4: "78,639",
        },
        {
          flag: "flag3",
          country: "Hong Kong",
          samplesNumber: "32,196",
          male: "16,420",
          woman: "15,776",
          age1: "5,795",
          age2: "10,303",
          age3: "11,269",
          age4: "4,829",
        },
        {
          flag: "flag4",
          country: "Japan",
          samplesNumber: "3,138,745",
          male: "1,412,435",
          woman: "1,726,310",
          age1: "345,262",
          age2: "847,461",
          age3: "1,286,885",
          age4: "659,136",
        },
        {
          flag: "flag5",
          country: "South Korea",
          samplesNumber: "378,532",
          male: "185,481",
          woman: "193,051",
          age1: "87,062",
          age2: "128,701",
          age3: "128,701",
          age4: "34,068",
        },
        {
          flag: "flag6",
          country: "Singapore",
          samplesNumber: "131,461",
          male: "67,045",
          woman: "64,416",
          age1: "27,607",
          age2: "48,641",
          age3: "40,753",
          age4: "14,461",
        },
        {
          flag: "flag7",
          country: "Indonesia",
          samplesNumber: "2,013,389",
          male: "1,288,569",
          woman: "724,820",
          age1: "563,749",
          age2: "986,561",
          age3: "402,678",
          age4: "60,402",
        },
        {
          flag: "flag8",
          country: "Thailand",
          samplesNumber: "1,632,094",
          male: "701,800",
          woman: "930,294",
          age1: "505,949",
          age2: "669,159",
          age3: "326,419",
          age4: "130,568",
        },
        {
          flag: "flag9",
          country: "Vietnam",
          samplesNumber: "913,725",
          male: "529,961",
          woman: "383,765",
          age1: "274,118",
          age2: "383,765",
          age3: "191,882",
          age4: "63,961",
        },
        {
          flag: "flag10",
          country: "Malaysia",
          samplesNumber: "359,617",
          male: "169,020",
          woman: "190,597",
          age1: "122,270",
          age2: "143,847",
          age3: "71,923",
          age4: "21,577",
        },
        {
          flag: "flag11",
          country: "The Philippines",
          samplesNumber: "583,202",
          male: "256,609",
          woman: "326,593",
          age1: "204,121",
          age2: "262,441",
          age3: "81,648",
          age4: "34,992",
        },
        {
          flag: "flag12",
          country: "India",
          samplesNumber: "1,524,157",
          male: "929,736",
          woman: "594,421",
          age1: "624,904",
          age2: "563,938",
          age3: "259,107",
          age4: "76,208",
        },
      ],
    },
    page6:{
      title1:'Multi channel traffic deployment',
      tips1:'Multi channel platform cooperation, attracting too many samples to inject fresh blood into the samples',
      tips2:'Cooperation on Weibo, WeChat official account, Little Red Book, Today Toutiao and other platforms',
      tips3:'Collaborate with mainstream e-commerce platforms to provide more authentic shopping and consumption habits data.',
    }
  },
  consumer: {
    page1: {
      title1: "40 million+global members covering 25+countries and regions",
      title2:
        "Recruitment and management model integrating online and offline, capable of executing various projects.",
      title3: "More",
    },
    page2: {
      name1: "Diverse recruitment channels.",
      name2: "Latest attribute segmentation.",
      name3: "Incorporating user interests.",
      name4: "Data quality assurance.",
      tips1: "Search engine, friend recommendations, and channel collaborations.",
      tips11: "WeChat promotion.",
      tips2: "More accurate estimation of occurrence rate.",
      tips22: "Meet various basic research needs.",
      tips3: "Patients, mother-infant, vehicle owners, B2B.",
      tips33: "Finance, insurance, and 20+ dimensions.",
      tips4: "High-point/cash rewards + credit system.",
      tips44: "Enhancing data quality.",
    },
    page3: {
      left: {
        name: "Sample online inquiry",
        title1: "Authentic",
        tips1: "User resources",
        title2: "abundant",
        tips2: "Segmented audience tags",
        title3: "wide",
        tips3: "Delivery channels",
      },
      right: {
        title1: "3.87 million+massive sample libraries",
        tips1: "Covering all parts of the country, industries, and age groups",
        tips2: "Attribute tags+interest tags+industry tags",
        labels1: {
          name: "Population:",
          label1: "country",
          label2: "gender",
          label3: "education",
          label4: "income",
          label5: "age",
        },
        labels2: {
          name: "Interest:",
          label1: "car",
          label2: "tourism",
          label3: "numerical",
          label4: "financial",
        },
        labels3: {
          name: "Behavior:",
          label1: "Online duration",
          label2: "Mobile game types",
          label3: "Payment method",
        },
      },
    },
    page4: {
      title1: "Data quality assurance.",
      tips1: "Credit scoring system for answering questions, comprehensively tracking the quality of members' answers.",
      tips2:
        "Through mobile and real-name authentication, ensuring the authenticity and uniqueness of samples.",
      tips3: "Identifying through techniques such as IP, cookies, and network environment to exclude duplicate responses.",
      tips4: "Setting the shortest answer time limit, implementing multiple link tracking to guide respondents to answer seriously.",
    },
    page5: {
      title1: "Sample segmentation",
      tips1:
        "Authoritative data coverage, comprehensive and stable growth, direct sample access, and improved technology",
      tips2:
        "2 million+car owner samples 1 million+maternal and child samples 1.5 million+medical samples",
      name1: "Automotive sample",
      name2: "MOM and BB sample",
      name3: "Tourism sample",
      name4: "Pc digital sample",
      tipBox1: {
        tips1: "Automobile Country",
        tips2: "auto brand",
        tips3: "Car models",
        tips4: "Vehicle segmentation",
        tips5: "Car price range",
      },
      tipBox2: {
        tips1: "Pregnancy status",
        tips2: "Infant age",
        tips3: "Infant gender",
        tips4: "Usage of baby products",
        tips5: "Household consumption amount",
      },
      tipBox3: {
        tips1: "Total number of trips",
        tips2: "Overseas tourist destinations",
        tips3: "All tourism consumption amounts",
        tips4: "content classification",
        tips5: "content classification",
      },
      tipBox4: {
        tips1: "Purchase budget",
        tips2: "The purpose of using a computer",
        tips3: "The type of computer owned",
        tips4: "Understanding and Favorite Brands",
        tips5: "Factors to consider when choosing a computer",
      },
    },
  },
  symposium: {
    page1: {
      title1: "Online Symposium System",
      tips1:
        "Sample nationwide coverage, mobile online, no need for business trips, role-based discussion groups",
      tips2:
        "Host, interviewee, observer, imitating the usage scenarios of traditional symposiums, with different permissions for each role according to business needs",
    },
    page2: {
      title1: "Dynamic user attribute labels",
      title2: "Mobile and email reminders",
      title3: "Flexible data export",
      title4: "Symposium/Holding Homework",
    },
    page3: {
      title1: "One-on-one language interviews",
      tips1:
        "Role based online discussion groups enable efficient interaction between brands and consumers, enabling rapid acquisition of user real perceptions, feedback, and deep insights online ",
      title2: "Online video interviews",
      tips2:
        "Role based online discussion groups enable efficient interaction between brands and consumers, enabling rapid acquisition of user real perceptions, feedback, and deep insights online ",
    },
    page4: {
      title1: "Partial business cases",
      title2: "A certain household appliance brand",
      title3:
        "Regarding the appearance test of a certain refrigerator.",
    },
  },
  program: {
    page1: {
      title1: "Questionnaire programming system",
      tips1: "Adaptive quality control for both computer and mobile devices",
    },
    page2: {
      name1: "70+ questionnaire languages",
      name2: "20+ Type of question",
      name3: "Cloud storage server",
      name4: "Supports complex logic",
      name5: "Repetitive answering restriction",
      name6: "IP check",
      name7: "Cookie check",
      name8: "Questionnaire timing function",
      tips1:
        "Surveys support up to 70+ languages.",
      tips2:
        "20+ basic question types, as well as advanced question types such as MaxDiff, image drag-and-drop, image selection, heatmaps, NPS (Net Promoter Score), recording, etc.",
      tips3:
        "Using cloud storage servers for efficient, secure, and highly scalable solutions.",
      tips4:
        "Easily set questionnaire skip logic, conveniently implement question looping, and enable randomization of options.",
      tips5:
        "Through computer unique identification technology and mobile phone unique machine code recognition, the project management system can screen out duplicate participating samples",
      tips6: "Verify the uniqueness of IP addresses and cookies",
      tips7: "Verify the uniqueness of cookies",
      tips8:
        "Shortest time limit for answering questions, multiple link tracking, guiding respondents to answer seriously",
    },
    page3: {
      title1: "Support complex questionnaire design programming",
      name1: "Research type",
      name2: "Program way",
      tips1: "Concept Testing",
      tips2: "Advertising testing",
      tips3: "ABtest",
      tips4: "satisfaction",
      tips5: "Activity effect test",
      tips6: "Joint analysis",
      tips7: "Maximizing the degree of difference MaxDiff",
    },
    page4: {
      title1: "Customized data processing",
      title2: "Supports exporting multiple data formats such as Excel and SPSS",
      title3:
        "Translate open questions according to customer needs and code them according to customer needs",
      title4: "Provide data cross tabulation based on customer needs",
    },
  },
  survey: {
    page1: {
      title1: "Customized research community",
      title2:
        "Provide customized online research systems, research communities, online qualitative systems, assist clients in recruiting customized samples, and assist clients in managing sample library operations",
    },
    page2: {
      left: {
        title1: "Own system",
        tips1: "Independently developed community research tool",
        title2: "Customized questionnaire template",
        tips2: "According to the needs of the enterprise",
        tips3: "Create an exclusive questionnaire template",
      },
      centre: {
        title1: "Customized research community",
      },
      right: {
        title1: "Permission control management",
        tips1: "Use of data and management permissions",
        tips2: "Strictly control internal personnel of the system",
        title2: "Data encryption protection",
        tips3: "Internal data of the enterprise",
        tips4: "Secure and tightly protected",
      },
    },
    page3: {
      title1: "Customized service process for enterprises",
      tips1:
        "Research community tool independently developed by Maiwen Technology;",
      tips2:
        "Committed to creating an environment for instant interaction with consumers through diverse research methods;",
      tips3:
        " Output sustainable and implementable results for enterprise innovation",
      box1: "Demand",
      box2: "Proposing Solutions",
      box3: "questionnaire design",
      box4: "data collection ",
      box5: "Data processing and analysis",
      box6: "Final report output",
    },
    page4: {
      title1: "your needs",
    },
  },
  programme: {
    page1: {
      title1:
        "Maiwen Technology's big data intelligent consumer insight solution, dedicated to unleashing the commercial value of data",
      tips1:
        "Enable seamless integration of multiple scenarios and channels between enterprises and consumers, improving marketing and management efficiency of enterprises;",
      tips2:
        "Maiwen Technology is willing to share with you our experiences in questionnaire programming and consumer research community construction, providing services for online research community operation and research system development;",
      btn: "Learn more",
    },
    page2: {
      title1: "Segmentation",
      title2: "Product Development",
      title3: "Content Optimization",
      title4: "Advertising effectiveness",
      title5: "Brand monitoring",
      title6: "User relationships",
      title7: "Research community development",
      title8: "product placement test",
      tips1:
        "Targeted Approach - Understand the Market, Understand Your Consumers",
      tips2:
        "Optimized Combinations - Selecting the best options at various stages of product design to enhance the success rate after launch.",
      tips3:
        "Content is King - Optimize communication content and channels to increase user attention to the brand.",
      tips4:
        "Data Speaks - Assessing the impact of digital marketing on traffic, preferences, and conversions.",
      tips5:
        "Know Yourself, Know Your Enemy - Assessing the brand's exposure, attractiveness, and advocacy in the market.",
      tips6:
        "Open source and reduce costs - help brands attract more users, improve retention rates, and increase user stickiness",
      tips7:
        "Tailor-Made - Starting from your actual needs, we provide a seamless set of services that integrates with your existing enterprise system.",
      tips8:
        "Rigorous and Meticulous - Serving 10+ retention projects annually, we have developed a rigorous product retention strategy.",
    },
  },
  news: {
    page1: {
      title1: "Company dynamics",
      synopsis: "I am the content introduction. I am the content introduction",
      read: "/more",
      content: "I am content, I am content, I am content, I am content",
      title2:'Release time',
      title3:'more',
    },
  },
  newsDetails: {
    page1: {
      title1: "News Content",
      title2: "news",
      title3: "See more",
      title4: "Return to List",
    },
  },
  aboutUs: {
    page1: {
      tips1: "Professional one-stop market research services",
    },
    page2: {
      title1: "Our Aim",
      tips1:
        "Insight into the pulse of the market, allowing you to better understand consumers. Your trusted online data service expert!",
      tips2:
        "By combining big and small data, the commercial value of data can be unleashed, thereby helping to improve the decision-making power of enterprises and making data a valuable asset for enterprises in the new era! Maiwen Technology has a mature background in big data technology and rich marketing applications; Through technologies such as AI intelligent learning, behavior labeling construction, and result visualization.",
      tips3:
        "We provide higher quality market insights in a more real-time manner！",
    },
    page3: {
      title1: "Certificate",
      tips1: "Recognition of Shanghai Maiwen by relevant national institutions",
      box: {
        name1: "Unit of the Data Intelligence Professional Committee",
        name2: "Unit of China Market Information Research Association",
        name3: "Foreign investigation permit",
        name4:
          "Member of the Market Research Branch (CMRA) of the China Information Association",
      },
    },
    page4: {
      title1: "development history",
    },
    page5: {
      title1: "Contact Us",
      tips1:
        "3rd Floor, Yu'an Building, No. 738 Dongfang Road, Pudong New Area, Shanghai",
    },
  },
  contactUs: {
    page1: {
      title1: "Contact Us",
      tips1:
        "3rd Floor, Yu'an Building, No. 738 Dongfang Road, Pudong New Area, Shanghai",
    },
    page2: {
      title1: "audit supervision",
      tips1:
        "Hello! In the process of participating in business cooperation, if you encounter violations such as staff taking cards, requesting kickbacks, manipulating inquiries or bidding, please contact the audit center in a timely manner and provide corresponding information for quick processing",
      title2: "Reporting contact information",
      box: {
        name1: "Reporting phone number: ",
        name2: "Report email: ",
        name3: "mailing address: ",
        name4: "zip code: ",
        content1:
          "3rd Floor, Yu'an Building, No. 738 Dongfang Road, Pudong New Area, Shanghai",
      },
    },
    page3: {
      title1: "our address",
      box1: {
        name: "Shanghai",
        email: "mail",
        address:
          "address: 3rd Floor, Yu'an Building, No. 738 Dongfang Road, Pudong New Area, Shanghai",
      },
      box2: {
        name: "Nanjing",
        email: "mail",
        address:
          "address: 7th Floor, Zhixin Technology Building, No. 15 Xinghuo Road, Pukou District, Nanjing City",
      },
    },
  },
  joinUs: {
    page1: {
      title1: "Join us",
      tips1: "Join hands to create a better tomorrow",
    },
    page2: {
      title1: "welfare treatment",
      name1: "system training",
      name2: "paid annual leave",
      name3: "Team culture",
      name4: "Five insurances and one fund",
    },
    page3: {
      title1: "Job",
      name1: "Senior Product Manager ",
      name2: "Senior front-end development engineer",
      name3: "IOS development engineer",
      boxs: [
        {
          name: "Job Description",
          pList: [
            "Proficient in using various market analysis tools and product prototype tools, able to write and output standardized product white papers;",
            "Has product planning and management thinking, able to write market research, market analysis, feasibility analysis reports, etc;",
            "Having good communication and coordination skills, systematic thinking, and innovative consciousness;",
            "Has product requirements, induction ability, and market sensitivity and insight ability;",
            "More than 5 years of experience as a product manager in TOB enterprises;",
            "Priority will be given to those with front-end related technical background and product architect skills.",
          ],
          email: "Please send your resume to:",
        },
        {
          name: "Job Description",
          pList: [
            "Proficient in using various market analysis tools and product prototype tools, able to write and output standardized product white papers;",
            "Has product planning and management thinking, able to write market research, market analysis, feasibility analysis reports, etc;",
            "Having good communication and coordination skills, systematic thinking, and innovative consciousness;",
            "Has product requirements, induction ability, and market sensitivity and insight ability;",
            "More than 5 years of experience as a product manager in TOB enterprises;",
            "Priority will be given to those with front-end related technical background and product architect skills",
          ],
          email: "Please send your resume to:",
        },
        {
          name: "Job Description",
          name2: "Priority given to those with the following experience",

          pList: [
            "Bachelor's degree or above, 3-5 years of development experience;",
            "Proficient in JS native code development, with a deep understanding of prototype programming;",
            "Proficient in using at least one front-end framework(for example:Angularjs/reactjs/backbonejs/emberjs/knockoutjs);",
            "Has product requirements, induction ability, and market sensitivity and insight ability;",
            "Familiar with modular development ideas and have actually used at least one framework(for example:requirejs/seajs); ",
          ],
          pList2: [
            "Bachelor's degree or above in computer related majors;",
            "Having practical development experience in Reactjs;",
            "Having experience in Nodejs backend development;",
            "Has good UI design skills;",
            "There is a personal work that can be demonstrated;",
          ],
          email: "Please send your resume to:",
        },
      ],
    },
  },
  privacyPolicy: {
    name: "Maiwen Technology Privacy Statement",
    title1:
      "Maiwen Technology website promises to respect and protect the privacy of users. This statement discloses the collection, use, and protection policies adopted by this website for user personal information. Please read carefully. The information we collect through is limited to internal use within the company for the following purposes;",
    useTo1: "1.Provide personalized online services",
    useTo2: "2.To provide you with information about our products and services",
    useTo3: "3.Understand market conditions",
    title2: "information gathering",

    boxs: [
      {
        tipsName: "1.Using non personalized information",
        tipsContent:
          "<p>We will use your IP address to provide personalized information on your phone, such as the nature of your browser, the type of operating system, and the domain name of the ISP that provides access to the server, in order to optimize the page displayed on your computer screen. By collecting the above information, we also conduct traffic statistics to improve website management and services.</p>",
      },
      {
        tipsName: "2.Personalized information",
        tipsContent:
          "<p>When you register on this website, with your consent and confirmation, this website will record your personal information through a registration form, which includes:</p> <p>Personal identification information: such as name, gender, age, date of birth, phone number, mailing address, email address, etc.</p> <p>Personal background: occupation, position, etc.</p>",
      },

      {
        tipsName: "3.Information security and quality",
        tipsContent:
          "<p>This website will strictly manage and protect the information you provide, and will use corresponding technologies to prevent the loss, theft, or tampering of your personal information.</p>",
      },
    ],

    prompt:
      "*We may modify our privacy policy from time to time. These modifications will be reflected in this statement. Any modifications will prioritize your satisfaction. We encourage you to refer to our privacy policy every time you visit the webpage of this website.",
  },
};
