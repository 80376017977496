<template>
  <div class="main">
    <div class="main-box">
      <div class="logo-box">
        <img src="@/assets/logo.png" @click="goUrl('/home')" />
      </div>

      <div class="info-box">
        <div class="text-box">
          <div class="title">{{ $t("footer.box1.title1") }}</div>
          <div class="tpis">{{ $t("footer.box1.title2") }}</div>
          <div class="tpis">{{ $t("footer.box1.title3") }}</div>
          <div class="tpis">{{ $t("footer.box1.title4") }}</div>
          <div class="tpis tpis-curr" @click="goUrl('privacyPolicy')">
            {{ $t("footer.box1.title5") }}
          </div>
        </div>
        <div class="text-box">
          <div class="title">{{ $t("footer.box2.title1") }}</div>
          <div class="tpis">{{ $t("footer.box2.title2") }}</div>
          <div class="tpis">{{ $t("footer.box2.title3") }}</div>
          <div class="tpis">{{ $t("footer.box2.title4") }}</div>
          <div class="tpis">{{ $t("footer.box2.title5") }}</div>
        </div>
        <div class="text-box">
          <div class="title">{{ $t("footer.box3.title1") }}</div>
          <div class="tpis">{{ $t("footer.box3.title2") }}</div>
          <div class="tpis">{{ $t("footer.box3.title3") }}</div>
          <div class="tpis">{{ $t("footer.box3.title4") }}</div>
          <div class="tpis">{{ $t("footer.box3.title5") }}</div>
        </div>
        <div class="text-box">
          <div class="title">{{ $t("footer.box4.title1") }}</div>
          <div class="emali-box">
            <img src="@/assets/emali.png" />
            <span>Team@mavensurvey.com</span>
          </div>
          <div class="qr-code-box" v-if="false">
            <img src="@/assets/qr-code.png" />
            <span>{{ $t("footer.box4.title2") }}</span>
          </div>
        </div>
      </div>

      <div class="company">
        <div class="left">Copyright 2011-2023 上海脉问信息科技有限公司</div>
        <div class="right"><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备18042951号-3</a></div>
      </div>
    </div>
    <div class="move-box">
      <div class="logo-box">
        <img src="@/assets/logo.png" @click="goUrl('/home')" />
      </div>
      <div class="info-box">
        <div class="text-box">
          <div
            :class="['title-box', { 'title-box-open': active1 }]"
            @click="active1 = !active1"
          >
            <div class="title">{{ $t("footer.box1.title1") }}</div>
            <img
              :class="[{ 'foot-arrow-rotate': active1 }]"
              src="@/assets/foot-arrow.png"
            />
          </div>
          <div :class="['tips-box', { 'tips-box-active': active1 }]">
            <div class="tpis">{{ $t("footer.box1.title2") }}</div>
            <div class="tpis">{{ $t("footer.box1.title3") }}</div>
            <div class="tpis">{{ $t("footer.box1.title4") }}</div>
            <div class="tpis" @click="goUrl('privacyPolicy')">
              {{ $t("footer.box1.title5") }}
            </div>
          </div>
        </div>
        <div class="text-box">
          <div
            :class="['title-box', { 'title-box-open': active2 }]"
            @click="active2 = !active2"
          >
            <div class="title">{{ $t("footer.box2.title1") }}</div>
            <img
              :class="[{ 'foot-arrow-rotate': active2 }]"
              src="@/assets/foot-arrow.png"
            />
          </div>
          <div :class="['tips-box', { 'tips-box-active': active2 }]">
            <div class="tpis">{{ $t("footer.box2.title2") }}</div>
            <div class="tpis">{{ $t("footer.box2.title3") }}</div>
            <div class="tpis">{{ $t("footer.box2.title4") }}</div>
            <div class="tpis">{{ $t("footer.box2.title5") }}</div>
          </div>
        </div>
        <div class="text-box">
          <div
            :class="['title-box', { 'title-box-open': active3 }]"
            @click="active3 = !active3"
          >
            <div class="title">{{ $t("footer.box3.title1") }}</div>
            <img
              :class="[{ 'foot-arrow-rotate': active3 }]"
              src="@/assets/foot-arrow.png"
            />
          </div>
          <div :class="['tips-box', { 'tips-box-active': active3 }]">
            <div class="tpis">{{ $t("footer.box3.title2") }}</div>
            <div class="tpis">{{ $t("footer.box3.title3") }}</div>
            <div class="tpis">{{ $t("footer.box3.title4") }}</div>
            <div class="tpis">{{ $t("footer.box3.title5") }}</div>
          </div>
        </div>
        <div class="text-box">
          <div
            :class="['title-box', { 'title-box-open': active4 }]"
            @click="active4 = !active4"
          >
            <div class="title">{{ $t("footer.box4.title1") }}</div>
            <img
              :class="[{ 'foot-arrow-rotate': active4 }]"
              src="@/assets/foot-arrow.png"
            />
          </div>
          <div :class="['emali-box', { 'emali-box-active': active4 }]">
            <img src="@/assets/emali.png" />
            <span>Team@mavensurvey.com</span>
          </div>
        </div>
      </div>
      <div class="company">
        <div class="left">Copyright 2011-2023 上海脉问信息科技有限公司</div>
        <div class="right">沪ICP备18042951号-3</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active1: false,
      active2: false,
      active3: false,
      active4: false,
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 454px;
  background-color: #222427;
  .main-box {
    width: calc(100% - 240px);
    margin-left: 120px;
    .logo-box {
      height: 96px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #3c3c3c;
      img {
        width: 110px;
        height: 45px;
        cursor: pointer;
      }
    }
    .info-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
      .text-box {
        display: flex;
        flex-direction: column;
        .title {
          color: #fff;
          font-size: 16px;
          font-weight: bolder;
          margin-bottom: 10px;
        }
        .tpis {
          color: #b4b4b4;
          font-size: 14px;
          margin-top: 25px;
        }
         .tpis-curr{
          cursor: pointer;
        }
        .emali-box {
          display: flex;
          align-items: center;
          margin-top: 25px;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            font-size: 14px;
            color: #fff;
            margin-left: 15px;
          }
        }
        .qr-code-box {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          img {
            width: 82px;
            height: 82px;
          }
          span {
            color: #999999;
            margin-top: 10px;
          }
        }
      }
    }
    .company {
      display: flex;
      justify-content: center;
      margin-top: 71px;
      .left {
        color: #ffffff;
        font-size: 14px;
      }
      .right {
        font-size: 14px;
        color: #107fff;
        margin-left: 30px;
        a {
          color: #ffffff;
          text-decoration: none;
        }
        a:hover {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }
  .move-box {
    display: none;
    padding-top: 26px;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 19px;
    .logo-box {
      width: 117px;
      height: 48px;
      margin-bottom: 25px;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .info-box {
      width: 100%;
      color: #fff;
      .title-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 16px;
        img {
          width: 18px;
          height: 18px;
          cursor: pointer;
          transition: transform 0.75s;
        }
        .foot-arrow-rotate {
          transform: rotate(180deg);
        }
      }
    
      .tips-box {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.75s;
        .tpis {
          color: #b4b4b4;
          font-size: 14px;
          margin-bottom: 10px;
        }
      
      }
      .tips-box-active {
        max-height: 200px;
      }
      .emali-box {
        max-height: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        span {
          font-size: 14px;
          color: #b4b4b4;
          margin-left: 15px;
        }
      }
      .emali-box-active {
        max-height: 40px;
      }
    }
    .company {
      display: flex;
      flex-direction: column;
      color: #fff;
      margin-top: 20px;
      font-size: 14px;
      .left {
        word-wrap: break-word;
        text-align: center;
        margin-bottom: 20px;
      }
      .right {
        word-wrap: break-word;
        text-align: center;
        color: #107fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
// 媒体查询
@media only screen and (max-width: 1000px) {
  .main {
    .main-box {
      width: calc(100% - 80px);
      margin-left: 40px;
    }
  }
}
@media only screen and (max-width: 850px) {
  .main {
    min-height: 400px;
    .main-box {
      display: none;
    }
    .move-box {
      display: block;
    }
  }
}
</style>