//app相关
import request from "@/utils/request";

//获取新闻列表
export function getBlogs(params) {
  return request({
    url: "/api/blog",
    method: "get",
    params,
  });
}

//置顶新闻
export function getSuper(params) {
  return request({
    url: "/api/blog/super",
    method: "get",
    params,
  });
}

//获取新闻详情
export function getBlogId(params) {
  return request({
    url: `/api/blog/${params.id}`,
    method: "get",
    params,
  });
}
//提交您的需求
export function setGuestbookAdd(data) {
  return request({
    url: "/api/guestbook/add",
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}
