<template>
  <div>
    <div :class="['herder', { 'herder-white': scrollTop }]">
      <img
        class="logo"
        @click="goUrl('/home', 1)"
        src="@/assets/logo2.png"
        v-if="!scrollTop"
      />
      <img
        class="logo"
        @click="goUrl('/home', 1)"
        src="@/assets/logo.png"
        v-else
      />

      <div class="navigation">
        <div
          :class="[
            'navigation-item',
            { 'navigation-img': item.type === 1 },
            {
              'navigation-language': item.type === 2,
            },
          ]"
          v-for="(item, index) in navigation"
          :key="index"
          @click="changeNavigation(item)"
        >
          <img
            v-if="item.type === 2 && !scrollTop"
            src="@/assets/language.png"
          />
          <img
            v-if="item.type === 2 && scrollTop"
            src="@/assets/language2.png"
          />
          <span v-if="item.type !== 2"> {{ $t(`navigation.${item.id}`) }}</span>
          <span v-if="item.type === 2"> {{ $t("lang") }}</span>
          <img v-if="item.type === 1 && !scrollTop" src="@/assets/arrow.png" />
          <img v-if="item.type === 1 && scrollTop" src="@/assets/arrow2.png" />
          <div
            :class="['product-box', { 'product-box2': $i18n.locale === 'en' }]"
            v-if="item.name === '产品中心'"
          >
            <div
              :class="[
                'product-item',
                { 'product-active': history === Citem.path },
              ]"
              v-for="(Citem, CIndex) in product"
              :key="CIndex"
              @click.stop="goUrl(Citem.path, 3)"
            >
              <svg-icon class="svg" :icon-class="Citem.url"></svg-icon>
              <span>{{
                $i18n.locale === "zn" ? Citem.name : Citem.ename
              }}</span>
            </div>
          </div>

          <div
            :class="['about-box', { 'about-box2': $i18n.locale === 'en' }]"
            v-if="item.name === '关于脉问'"
          >
            <div
              v-for="(Citem, Cindex) in about"
              :class="[
                'about-item',
                { 'about-active': history === Citem.path },
              ]"
              :key="Cindex"
              @click.stop="goUrl(Citem.path, 6)"
            >
              <svg-icon class="svg" :icon-class="Citem.url"></svg-icon>
              <span>{{
                $i18n.locale === "zn" ? Citem.name : Citem.ename
              }}</span>
            </div>
          </div>
        </div>
        <div :class="['line', `line-active${active}`]"></div>
      </div>
    </div>

    <div :class="['mobile-terminal', { 'mobile-terminal-white': scrollTop }]">
      <img
        class="logo"
        @click="goUrl('/home', 1)"
        src="@/assets/logo2.png"
        v-if="!scrollTop"
      />
      <img
        class="logo"
        @click="goUrl('/home', 1)"
        src="@/assets/logo.png"
        v-else
      />

      <div @click.stop="mobileShow = true">
        <svg-icon class="storage" icon-class="storage"></svg-icon>
      </div>
      <div class="sidebar" v-if="mobileShow">
        <div class="sidebar-top">
          <div class="name">{{ $t("move.name") }}</div>
          <img
            class="close"
            src="@/assets/close.png"
            @click.stop="mobileShow = false"
          />
        </div>
        <div class="line"></div>
        <div class="sidebar-box">
          <div
            v-for="(item, index) in navigation"
            :key="index"
            v-if="item.type !== 2"
            class="sidebar-item"
            @click="changeNavigation(item)"
          >
            <div class="name-box" @click="changeRotate(index)">
              <div :class="['name', { 'name-active': active === item.active }]">
                {{ $t(`navigation.${item.id}`) }}
              </div>
              <img
                :class="['name-arrow', { 'name-arrow-rotate': item.rotate }]"
                v-if="item.type === 1"
                src="@/assets/move-arrow.png"
              />
            </div>
            <div
              v-if="item.name === '产品中心'"
              :class="[
                'sidebar-product',
                { 'sidebar-product-zhan': item.rotate },
              ]"
            >
              <div
                :class="[
                  'product-item',
                  { 'product-active': history === cItem.path },
                ]"
                v-for="(cItem, cIndex) in product"
                @click.stop="goUrl(cItem.path, 3)"
                :key="cIndex"
              >
                <span class="product-name">{{
                  $i18n.locale === "zn" ? cItem.name : cItem.ename
                }}</span>
              </div>
            </div>
            <div
              v-if="item.name === '关于脉问'"
              :class="[
                'sidebar-product',
                { 'sidebar-product-zhan': item.rotate },
              ]"
            >
              <div
                :class="[
                  'product-item',
                  { 'product-active': history === cItem.path },
                ]"
                v-for="(cItem, cIndex) in about"
                :key="cIndex"
                @click.stop="goUrl(cItem.path, 6)"
              >
                <span class="product-name">{{
                  $i18n.locale === "zn" ? cItem.name : cItem.ename
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <el-select v-model="value" @change="changeLang" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div
        class="zhezhao"
        @click.stop="mobileShow = false"
        v-if="mobileShow"
      ></div>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
export default {
  name: "herder",
  watch: {
    "$i18n.locale"() {
      this.value = this.$i18n.locale;
    },
    $route(to, from) {
      // 路由发生变化时的处理逻辑
      if (to.path === "/home") {
        this.active=1
      }
    },
  },
  data() {
    return {
      navigation: [
        {
          name: "首页",
          path: "/home",
          active: 1,
          type: 3,
          id: "home",
        },
        {
          name: "样本服务",
          path: "/sample",
          active: 2,
          type: 3,
          id: "sample",
        },
        {
          name: "产品中心",
          path: "/consumer",
          active: 3,
          type: 1,
          id: "product",
          rotate: false,
        },
        {
          name: "解决方案",
          path: "/programme",
          active: 4,
          type: 3,
          id: "programme",
        },
        {
          name: "新闻中心",
          path: "/news",
          active: 5,
          type: 3,
          id: "news",
        },
        {
          name: "关于脉问",
          path: "/aboutUs",
          active: 6,
          type: 1,
          id: "about",
          rotate: false,
        },
        {
          type: 2,
        },
      ],
      product: [
        {
          url: "iocn1",
          name: "消费者调研社区",
          ename: "Consumer research community",
          path: "/consumer",
        },
        {
          url: "iocn2",
          name: "在线座谈会系统",
          ename: "Online Symposium System",
          path: "/symposium",
        },
        {
          url: "iocn3",
          name: "问卷编程系统",
          ename: "Questionnaire programming system",
          path: "/program",
        },
        {
          url: "iocn4",
          name: "定制化调研社区",
          ename: "Customized research community",
          path: "/survey",
        },
      ],
      about: [
        {
          url: "iocn5",
          name: "关于我们",
          ename: "About Us",
          path: "/aboutUs",
        },
        {
          url: "iocn6",
          name: "联系我们",
          ename: "Contact Us",
          path: "/contactUs",
        },
        {
          url: "iocn7",
          name: "加入我们",
          ename: "Join us",
          path: "/joinUs",
        },
      ],
      active: 1,
      history: "",
      scrollTop: 0,
      mobileShow: false,
      value: this.$i18n.locale,
      options: [
        {
          value: "zn",
          label: "中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.getActive();
  },

  methods: {
    //切换页面
    changeNavigation(data) {
      if (this.active === data.active) {
        return;
      }
      switch (data.type) {
        case 1:
          this.active = data.active;
          this.$router.push(data.path);
          this.history = data.path;
          break;
        case 2:
          if (this.$i18n.locale === "zn") {
            // 判断当前语言
            this.$i18n.locale = "en"; // 设置当前语言
          } else {
            this.$i18n.locale = "zn";
          }

          break;
        case 3:
          this.active = data.active;
          this.$router.push(data.path);
          this.history = data.path;
          break;
      }
    },
    //监听页面滚动
    scrollToTop() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    //判断当前active
    getActive() {
      const { proxy } = getCurrentInstance();
      console.log("proxy", proxy.$router.history._startLocation);
      this.history = proxy.$router.history._startLocation;
      let proxyRouter = proxy.$router.history._startLocation;
      if (proxyRouter.indexOf("newsDetails") !== -1) {
        proxyRouter = "/newsDetails";
      }
      switch (proxyRouter) {
        case "/home":
          this.active = 1;
          break;
        case "/sample":
          this.active = 2;
          break;
        case "/consumer":
        case "/symposium":
        case "/survey":
        case "/program":
          this.active = 3;
          break;
        case "/programme":
          this.active = 4;
          break;
        case "/news":
        case "/newsDetails":
          this.active = 5;
          break;
        case "/aboutUs":
        case "/contactUs":
        case "/joinUs":
          this.active = 6;
          break;
      }
    },
    //跳转链接
    goUrl(path, index) {
      this.$router.push(path);
      this.history = path;
      this.active = index;
    },
    changeRotate(index) {
      console.log("1111111");
      switch (this.navigation[index].name) {
        case "产品中心":
          this.navigation[index].rotate = !this.navigation[index].rotate;
          break;
        case "关于脉问":
          this.navigation[index].rotate = !this.navigation[index].rotate;
          break;
      }
    },
    changeLang() {
      this.$i18n.locale = this.value;
    },
  },
};
</script>


<style scoped lang="scss">
.herder {
  display: flex;
  height: 60px;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  color: #fff;
  transition: all 0.3s;
  font-weight: bolder;
  z-index: 999;
  .logo {
    width: 110px;
    height: 45px;
    margin-left: 120px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .navigation {
    margin-left: 80px;
    display: flex;
    height: 100%;
    line-height: 60px;
    position: relative;
    .navigation-item {
      width: 100px;
      margin-right: 50px;
      text-align: center;
      position: relative;
      cursor: pointer;
      font-size: 15px;
    }
    .navigation-img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      span {
        margin-left: 10px;
      }
      img {
        width: 19px;
        height: 19px;
        // margin-right: 10px;
      }
      .product-box {
        display: none;
        position: absolute;
        width: 332px;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        top: 60px;
        left: 10px;
        transition: all 0.3s;
        flex-wrap: wrap;
        padding: 14px 0;
        z-index: 1000;
        .product-item {
          width: 50%;
          color: #232323;
          font-weight: normal;
          font-size: 14px;
          // height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          .svg {
            width: 20px;
            height: 20px;
            margin-left: 20px;
            margin-right: 10px;
          }
          span {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            text-align: left;
          }
        }
        .product-item:hover {
          color: #0b7cff;
        }
        .product-active {
          color: #0b7cff;
        }
      }
      .product-box2 {
        display: none;
        flex-direction: column;
        .product-item {
          width: 100%;
          text-align: left;
        }
      }
      .about-box {
        position: absolute;
        width: 258px;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        display: none;
        top: 60px;
        left: 10px;
        transition: all 0.3s;
        flex-wrap: wrap;
        padding: 14px 0;
        .about-item {
          display: flex;
          width: 50%;
          color: #232323;
          font-weight: normal;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          .svg {
            width: 20px;
            height: 20px;
            margin-left: 20px;
            margin-right: 10px;
          }
          span {
            margin-left: 0;
            margin-right: 0;
          }
        }
        .about-item:hover {
          color: #0b7cff;
        }
        .about-active {
          color: #0b7cff;
        }
      }
      .about-box2 {
        width: 140px;
        .about-item {
          width: 100%;
        }
      }
    }
    .navigation-img:hover {
      .product-box {
        display: flex;
      }
      .about-box {
        display: flex;
      }
    }
    .navigation-language {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }
    }
    .line {
      position: absolute;
      width: 30px;
      height: 2px;
      background-color: #0b7cff;
      bottom: 0;
      transition: transform 0.3s;
    }
  }
}
.herder-white {
  background-color: #fff;
  color: #232323;
}
.line-active1 {
  transform: translateX(34px);
}
.line-active2 {
  transform: translateX(182px);
}
.line-active3 {
  transform: translateX(325px);
}
.line-active4 {
  transform: translateX(485px);
}
.line-active5 {
  transform: translateX(635px);
}
.line-active6 {
  transform: translateX(780px);
}
.mobile-terminal {
  display: none;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  color: #fff;
  transition: all 0.3s;
  font-weight: bolder;
  z-index: 9999;
  .logo {
    width: 90px;
    height: 36px;
    margin-left: 10px;
    cursor: pointer;
  }
  .storage {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }
  .sidebar {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 999999;
    width: 255px;
    background-color: #fff;
    overflow: auto;
    .sidebar-top {
      color: #999999;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        font-size: 14px;
        margin-left: 15px;
      }
      .close {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .line {
      background: #f8f8f8;
      height: 8px;
      margin-bottom: 8px;
    }
    .sidebar-box {
      color: #232323;
      font-size: 14px;
      font-weight: normal;
      .sidebar-item {
        margin-bottom: 20px;
        .name-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          .name {
            margin-left: 15px;
          }
          .name-active {
            color: #0b7cff;
          }
          .name-arrow {
            width: 18px;
            height: 18px;
            margin-right: 14px;
            transition: all 0.75s;
          }
          .name-arrow-rotate {
            transform: rotate(180deg);
          }
        }
        .sidebar-product {
          max-height: 0px;
          overflow: hidden;
          transition: all 0.75s;
          .product-item {
            height: auto;
            line-height: 28px;
            min-height: 38px;
            background-color: #fafafa;
            border-bottom: 1px solid #f0f0f0;
            cursor: pointer;
            padding: 0 15px;
            display: flex;
            align-items: center;
            .product-name {
              color: #666666;
            }
          }
          .product-item:first-child {
            margin-top: 10px;
          }
          .product-item:last-child {
            border: none;
          }
          .product-active {
            .product-name {
              color: #0b7cff;
            }
          }
        }
        .sidebar-product-zhan {
          max-height: 400px;
        }
      }
    }
  }
  .zhezhao {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background: rgba(35, 35, 35, 0.3);
    z-index: 99999;
  }
}
.mobile-terminal-white {
  background-color: #fff;
  color: #232323;
}
.el-select {
  width: 107px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>


<style lang="scss" scoped>
/* 媒体查询 */
@media only screen and (max-width: 1360px) {
  .herder {
    .navigation {
      margin-left: 40px;
      .navigation-item {
        margin-right: 30px;
      }
    }
  }
  .line-active1 {
    transform: translateX(34px);
  }
  .line-active2 {
    transform: translateX(165px);
  }
  .line-active3 {
    transform: translateX(290px);
  }
  .line-active4 {
    transform: translateX(425px);
  }
  .line-active5 {
    transform: translateX(555px);
  }
  .line-active6 {
    transform: translateX(680px);
  }
}
@media only screen and (max-width: 1160px) {
  .herder {
    .navigation {
      margin-left: 20px;
      .navigation-item {
        margin-right: 10px;
      }
    }
  }
  .line-active1 {
    transform: translateX(34px);
  }
  .line-active2 {
    transform: translateX(145px);
  }
  .line-active3 {
    transform: translateX(245px);
  }
  .line-active4 {
    transform: translateX(365px);
  }
  .line-active5 {
    transform: translateX(475px);
  }
  .line-active6 {
    transform: translateX(580px);
  }
}
@media only screen and (max-width: 1000px) {
  .herder {
    .logo {
      margin-left: 40px;
    }
  }
}
@media only screen and (max-width: 950px) {
  .herder {
    display: none;
  }
  .mobile-terminal {
    display: flex;
  }
}
</style>

<style>
.el-select-dropdown {
  z-index: 999999999 !important;
}
</style>