<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="join-top">
      <div class="triangle-box">
        <img src="@/assets/joinUs/triangle.png" />
        <div class="title">{{ $t("joinUs.page1.title1") }}</div>
        <div class="tips">{{ $t("joinUs.page1.tips1") }}</div>
      </div>
    </div>
    <!-- 福利待遇 -->
    <div class="welfare">
      <div class="title">{{ $t("joinUs.page2.title1") }}</div>
      <div class="line"></div>
      <div class="welfare-box">
        <div
          :class="['welfare-item', { 'welfare-iteme': $i18n.locale === 'en' }]"
          v-for="item in 4"
          :key="item"
        >
          <img :src="require(`@/assets/joinUs/welfare${item}.png`)" />
          <div class="name">{{ $t(`joinUs.page2.name${item}`) }}</div>
        </div>
      </div>
    </div>
    <!-- 招聘职位 -->
    <div class="recruit">
      <div class="title">{{ $t("joinUs.page3.title1") }}</div>
      <div class="line"></div>

      <div class="boxs">
        <div
          :class="['box-item', { 'box-active': actives[index] }]"
          v-for="(item, index) in this.list"
          :key="index"
          @click="changeActive(index)"
        >
          <div class="box-position">
            <div class="name">{{ $t(`joinUs.page3.name${index + 1}`) }}</div>
            <svg-icon class="svg" icon-class="arrow"></svg-icon>
          </div>
          <div class="box-content">
            <div class="content-title">{{ item.name }}:</div>
            <p v-for="(pItem, pIndex) in item.pList" :key="pIndex">
              {{ pItem }}
            </p>
            <div class="content-title content-title2" v-if="item.name2">
              {{ item.name2 }}:
            </div>
            <div v-if="item.pList2">
              <p v-for="(pItem, pIndex) in item.pList2" :key="pIndex">
                {{ pItem }}
              </p>
            </div>
            <div class="emali-box">
              <div class="name">{{ item.email }}</div>
              <div class="emali">Team@mavensurvey.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    "$i18n.locale"() {
      this.list = this.$t("joinUs.page3.boxs"); 
    },
  },
  data() {
    return {
      list: [],
      actives: [false, false, false],
    };
  },
  methods: {
    changeActive(index) {
      this.actives[index] = !this.actives[index];
      this.$forceUpdate();
      console.log("触发", this.actives);
    },
  },
  created() {
    this.list = this.$t("joinUs.page3.boxs");
  },
};
</script>

<style lang="scss" scoped>
.main {
  // 第一屏
  .join-top {
    width: 100%;
    height: 565px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/joinUs/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .triangle-box {
      width: 100%;
      height: 125px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 142px;
        height: 125px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .title {
        font-size: 28px;
        color: #fff;
        position: absolute;
        top: 21px;
        left: 50%;
        transform: translateX(-50%);
      }
      .tips {
        font-size: 16px;
        color: #fff;
        position: absolute;
        top: 86px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  // 福利待遇
  .welfare {
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
      margin-top: 69px;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 72px;
    }
    .welfare-box {
      display: flex;
      justify-content: space-around;
      .welfare-item {
        display: flex;
        align-items: center;
        img {
          width: 72px;
          height: 72px;
          margin-right: 30px;
        }
        .name {
          color: #232323;
          font-size: 22px;
        }
      }
    }
  }
  // 招聘职位
  .recruit {
    margin-bottom: 100px;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
      margin-top: 105px;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 49px;
    }
    .boxs {
      width: 1196px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      .box-item {
        width: 100%;
        .box-position {
          height: 80px;
          background: #ffffff;
          box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #232323;
          cursor: pointer;
          .name {
            margin-left: 43px;
            font-size: 16px;
          }
          .svg {
            margin-right: 35px;
            color: #0b7cff;
            width: 16px;
            height: 16px;
            transform: rotate(-90deg);
            transition: transform 0.3s;
          }
        }
        .box-position:hover {
          color: #fff;
          background: #0b7cff;
          transition: all 0.3s;
          .svg {
            color: #fff;
          }
        }
        .box-content {
          width: 1196px;
          padding: 0 45px;
          background: #ffffff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
          border-radius: 4px 4px 4px 4px;
          font-size: 16px;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s;
          .content-title {
            color: #232323;
            font-weight: bolder;
            margin-bottom: 16px;
            margin-top: 25px;
          }
          .content-title2 {
            margin-top: 20px;
          }
          p {
            color: #232323;
            margin-bottom: 12px;
          }
          .emali-box {
            display: flex;
            margin-top: 20px;
            margin-bottom: 47px;
            .name {
              color: #232323;
              font-weight: bolder;
              margin-bottom: 16px;
            }
            .emali {
              margin-left: 20px;
              color: #0b7cff;
              font-weight: bolder;
            }
          }
        }
      }
      .box-active {
        .box-content {
          width: 1196px;
          margin-bottom: 16px;
          max-height: 1000px;
        }
        .box-position {
          color: #fff;
          background: #0b7cff;
          transition: all 0.3s;
          .svg {
            transform: rotate(0);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1250px) {
  .main {
    // 招聘职位
    .recruit {
      margin-bottom: 100px;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 105px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 49px;
      }
      .boxs {
        width: 950px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .box-item {
          width: 100%;
          .box-position {
            height: 80px;
            background: #ffffff;
            box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #232323;
            cursor: pointer;
            .name {
              margin-left: 43px;
              font-size: 16px;
            }
            .svg {
              margin-right: 35px;
              color: #0b7cff;
              width: 16px;
              height: 16px;
              transform: rotate(-90deg);
              transition: transform 0.3s;
            }
          }
          .box-position:hover {
            color: #fff;
            background: #0b7cff;
            transition: all 0.3s;
            .svg {
              color: #fff;
            }
          }
          .box-content {
            width: 860px;
            padding: 0 45px;
            background: #ffffff;
            box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 4px 4px;
            font-size: 16px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s;
            .content-title {
              color: #232323;
              font-weight: bolder;
              margin-bottom: 16px;
              margin-top: 25px;
            }
            .content-title2 {
              margin-top: 20px;
            }
            p {
              color: #232323;
              margin-bottom: 12px;
            }
            .emali-box {
              display: flex;
              margin-top: 20px;
              margin-bottom: 47px;
              .name {
                color: #232323;
                font-weight: bolder;
                margin-bottom: 16px;
              }
              .emali {
                margin-left: 20px;
                color: #0b7cff;
                font-weight: bolder;
              }
            }
          }
        }
        .box-active {
          .box-content {
            width: 860px;
            margin-bottom: 16px;
            max-height: 1000px;
          }
          .box-position {
            color: #fff;
            background: #0b7cff;
            transition: all 0.3s;
            .svg {
              transform: rotate(0);
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .main {
    // 福利待遇
    .welfare {
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 69px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 72px;
      }
      .welfare-box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .welfare-item {
          display: flex;
          width: 40%;
          flex-shrink: 0;
          align-items: center;
          margin-bottom: 20px;
          justify-content: center;
          img {
            width: 72px;
            height: 72px;
            margin-right: 30px;
          }
          .name {
            color: #232323;
            font-size: 22px;
          }
        }
        .welfare-iteme {
          justify-content: flex-start;
        }
      }
    }

    // 招聘职位
    .recruit {
      margin-bottom: 100px;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 105px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 49px;
      }
      .boxs {
        width: 700px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .box-item {
          width: 100%;
          .box-position {
            height: 80px;
            background: #ffffff;
            box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #232323;
            cursor: pointer;
            .name {
              margin-left: 43px;
              font-size: 16px;
            }
            .svg {
              margin-right: 35px;
              color: #0b7cff;
              width: 16px;
              height: 16px;
              transform: rotate(-90deg);
              transition: transform 0.3s;
            }
          }
          .box-position:hover {
            color: #fff;
            background: #0b7cff;
            transition: all 0.3s;
            .svg {
              color: #fff;
            }
          }
          .box-content {
            width: 640px;
            padding: 0 30px;
            background: #ffffff;
            box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 4px 4px;
            font-size: 16px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s;
            .content-title {
              color: #232323;
              font-weight: bolder;
              margin-bottom: 16px;
              margin-top: 25px;
            }
            .content-title2 {
              margin-top: 20px;
            }
            p {
              color: #232323;
              margin-bottom: 12px;
            }
            .emali-box {
              display: flex;
              margin-top: 20px;
              margin-bottom: 47px;
              .name {
                color: #232323;
                font-weight: bolder;
                margin-bottom: 16px;
              }
              .emali {
                margin-left: 20px;
                color: #0b7cff;
                font-weight: bolder;
              }
            }
          }
        }
        .box-active {
          .box-content {
            width: 640px;
            margin-bottom: 16px;
            max-height: 1000px;
          }
          .box-position {
            color: #fff;
            background: #0b7cff;
            transition: all 0.3s;
            .svg {
              transform: rotate(0);
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .main {
    // 第一屏
    .join-top {
      width: 100%;
      height: 400px;
      background-color: #000;
      position: relative;
      background-image: url("../assets/joinUs/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .triangle-box {
        width: 100%;
        height: 125px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 142px;
          height: 125px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .title {
          font-size: 25px;
          color: #fff;
          position: absolute;
          top: 21px;
          left: 50%;
          transform: translateX(-50%);
        }
        .tips {
          width: 100%;
          text-align: center;
          font-size: 14px;
          color: #fff;
          position: absolute;
          top: 86px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    // 福利待遇
    .welfare {
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 50px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 40px;
      }
      .welfare-box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .welfare-item {
          display: flex;
          width: calc(50% - 40px);
          flex-shrink: 0;
          align-items: center;
          margin-bottom: 20px;
          justify-content: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 30px;
          }
          .name {
            color: #232323;
            font-size: 16px;
          }
        }
        .welfare-iteme {
          justify-content: flex-start;
        }
      }
    }

    // 招聘职位
    .recruit {
      margin-bottom: 100px;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 60px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 30px;
      }
      .boxs {
        width: calc(100% - 50px);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .box-item {
          width: 100%;
          .box-position {
            height: 60px;
            background: #ffffff;
            box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #232323;
            cursor: pointer;
            .name {
              margin-left: 43px;
              font-size: 16px;
            }
            .svg {
              margin-right: 35px;
              color: #0b7cff;
              width: 16px;
              height: 16px;
              transform: rotate(-90deg);
              transition: transform 0.3s;
            }
          }
          .box-position:hover {
            color: #fff;
            background: #0b7cff;
            transition: all 0.3s;
            .svg {
              color: #fff;
            }
          }
          .box-content {
            width: calc(100% - 60px);
            padding: 0 30px;
            background: #ffffff;
            box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 4px 4px;
            font-size: 16px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s;
            .content-title {
              color: #232323;
              font-weight: bolder;
              margin-bottom: 16px;
              margin-top: 25px;
            }
            .content-title2 {
              margin-top: 20px;
            }
            p {
              color: #232323;
              margin-bottom: 12px;
            }
            .emali-box {
              display: flex;
              margin-top: 20px;
              margin-bottom: 20px;
              .name {
                color: #232323;
                font-weight: bolder;
                margin-bottom: 16px;
              }
              .emali {
                margin-left: 20px;
                color: #0b7cff;
                font-weight: bolder;
              }
            }
          }
        }
        .box-active {
          .box-content {
           width: calc(100% - 60px);
            margin-bottom: 16px;
            max-height: 1000px;
          }
          .box-position {
            color: #fff;
            background: #0b7cff;
            transition: all 0.3s;
            .svg {
              transform: rotate(0);
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 485px) {
  .main {
    // 福利待遇
    .welfare {
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 50px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 40px;
      }
      .welfare-box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .welfare-item {
          display: flex;
          width: calc(100% - 40px);
          flex-shrink: 0;
          align-items: center;
          margin-bottom: 20px;
          justify-content: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 30px;
          }
          .name {
            color: #232323;
            font-size: 16px;
          }
        }
        .welfare-iteme {
          justify-content: flex-start;
          img {
            margin-left: 60px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .main {
    // 福利待遇
    .welfare {
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
        margin-top: 50px;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 40px;
      }
      .welfare-box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .welfare-item {
          display: flex;
          width: calc(100% - 40px);
          flex-shrink: 0;
          align-items: center;
          margin-bottom: 20px;
          justify-content: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 30px;
          }
          .name {
            color: #232323;
            font-size: 16px;
          }
        }
        .welfare-iteme {
          justify-content: flex-start;
          img {
            margin-left: 40px;
          }
        }
      }
    }
  }
}
</style>