<template>
  <div class="main">
    <div class="name">{{ $t("privacyPolicy.name") }}</div>
    <div class="title1">{{ $t("privacyPolicy.title1") }}</div>

    <div class="useTo">{{ $t("privacyPolicy.useTo1") }}</div>
    <div class="useTo">{{ $t("privacyPolicy.useTo2") }}</div>
    <div class="useTo">{{ $t("privacyPolicy.useTo3") }}</div>

    <div class="title2">{{ $t("privacyPolicy.title2") }}</div>

    <div v-for="(item, index) in list" :key="index" class="box-item">
      <div>{{ item.tipsName }}</div>
      <div v-html="item.tipsContent"></div>
    </div>

    <div class="prompt">{{ $t("privacyPolicy.prompt") }}</div>
  </div>
</template>
<script>
export default {
  watch: {
    "$i18n.locale"() {
      this.list = this.$t("privacyPolicy.boxs");
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.list = this.$t("privacyPolicy.boxs");
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: calc(100% - 240px);
  padding: 0 120px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .name {
    color: #232323;
    font-size: 20px;
    text-align: center;
    font-weight: bolder;
    padding-top: 54px;
  }
  .title1 {
    margin-top: 40px;
    color: #232323;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .useTo {
    line-height: 30px;
    color: #232323;
    font-size: 16px;
  }
  .title2 {
    margin-top: 40px;
    color: #232323;
    font-size: 16px;
    margin-bottom: 25px;
  }
  .box-item {
    margin-top: 30px;
    line-height: 30px;
  }
  .prompt {
    color: #232323;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 128px;
  }
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1000px) {
  .main {
    width: calc(100% - 80px);
    padding: 0 40px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 750px) {
  .main {
    .prompt {
      color: #232323;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }
}
</style>