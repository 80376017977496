export default {
  lang: "EN",
  move: {
    name: "导航",
  },
  navigation: {
    home: "首页",
    sample: "样本服务",
    product: "产品中心",
    programme: "解决方案",
    news: "新闻中心",
    about: "关于脉问",
  },
  home: {
    page1: {
      title1: "4000万+全球在线样本库",
      title2: "会员人数众多、来源广泛而真实，基本代表中国网民特征",
      btn1: "了解更多",
      btn2: "联系我们",
    },
    about: {
      title1: "关于我们的服务",
      box1: {
        name: "样本收集服务",
        tips: "在线样本收集、问卷编程、交叉表格、线上线下招募、数据收集相关",
      },
      box2: {
        name: "研究分析服务",
        tips: "定量/定性服务、问卷设计、数据研 究报告、行业研究相关、品牌研究;",
      },
      box3: {
        name: "定制社区服务",
        tips: "量身打造-从您的真实需求出发， 提供与一整套服务，社区代运营 服务;",
      },
      box4: {
        name: "IT定制解决方案",
        tips: "依托行业背景，运用强大的技术背 景，为企业解决定制化IT解决方案;",
      },
    },
    core: {
      title1: "核心优势",
      box1: {
        title: "全量数据",
        p1: "以普通消费者样本为主体、兼顾各类型专业样本库的组合式样本结构",
        p2: "包含全球60+国家和地区样本库，包含医生，车主，母婴等人群样本，真实可靠",
      },
      box2: {
        title: "技术驱动",
        p1: "强大的技术支撑，大大提升了产品创新能力",
        p2: "以技术团队为背景，深耕行业20年，为行业提供专业的IT技术解决方案。",
      },
      box3: {
        title: "一站式服务",
        p1: "一站式服务，从问卷设计到报告输出",
        p2: "我们提供一站式市场研究服务，从问卷设计，问卷编程，数据处理，研究报告，让客户快速得到市场动向。",
      },
      box4: {
        title: "定制研发",
        p1: "从企业是真实需求出发，为企业打造属于自己的调研平台",
        p2: "自身经验丰富的IT技术团队，可以为企业定制属于自己的调研平台，以及定制系统开发服务。",
      },
    },
    samples: {
      title1: "样本数量覆盖全球",
      map: {
        title1: "完成样本量",
        title2: "全球样本量",
        title3: "国家地区",
      },
    },
    samplesData: {
      title1: "帮助众多客户获得样本数据",
      title2: "客户案例",
      title3: "服务于各行业的客户",
      title4: "全部客户",
    },
  },
  footer: {
    box1: {
      title1: "关于我们",
      title2: "公司介绍",
      title3: "联系我们",
      title4: "加入我们",
      title5: "隐私政策",
    },
    box2: {
      title1: "产品中心",
      title2: "消费者调研社区",
      title3: "问卷系统",
      title4: "在线座谈会系统",
      title5: "定制化调研社区",
    },
    box3: {
      title1: "解决方案",
      title2: "样本收集服务",
      title3: "研究分析服务",
      title4: "定制社区服务",
      title5: "IT定制解决方案",
    },
    box4: {
      title1: "联系详情",
      title2: "官方客服微信",
    },
  },
  sample: {
    page1: {
      title1: "优质样本服务",
      title2: "精准调研数据",
      title3: "全会员手机身份验证，车主品牌型号，母婴人群，样本真实可靠",
    },
    page2: {
      title1: "移动样本",
      title2: "极速回收",
      title3: "1小时可以轻松完成2000样本的调研项目",
      title4: "自有会员平台，通过即时邀请，为企业快速样本服务支持。",
    },
    page3: {
      rotate: {
        title1: "万",
        title2: "自有样本",
      },
      left: {
        title1: "1亿电商样本",
        title2: "我们与国内头部电商合作",
        title3: "可以根据用户的购物行为金顶精准抽样",
        labels: {
          title1: "<span>搜索了</span>除湿器",
          title2: "<span>搜索了</span>沐浴露",
          title3: "<span>浏览了</span>面霜",
          title4: "<span>购买了</span>电动牙刷",
          title5: "将唇膏<span>加入到购物车，</span>但没有<span>购买</span>",
          title6: "<span>加购了</span>洗面奶",
          title7: "<span>购买了</span>智能手机",
          title8: "<span>收藏了</span>空调",
          title9: "<span>收藏了</span>面膜",
          title10: "<span>收藏了</span>儿童玩具",
        },
      },
      right: {
        title1: "DSP流媒体",
        title2: "我们与国内头部流量平台合作",
        title3: "可以根据用户的行为金顶精准抽样",
        labels: {
          title1: "<span>微信</span>",
          title2: "<span>今日头条</span>",
          title3: "<span>腾讯新闻</span>",
          title4: "<span>抖音</span>",
          title5: "<span>百度</span>",
          title6: "<span>京东</span>",
          title7: "微博",
          title8: "<span>优酷视频</span>",
          title9: "<span>哔哩哔哩</span>",
          title10: "<span>快手</span>",
          title11: "腾讯QQ",
        },
      },
    },
    page4: {
      title1: "200万+车主（含50万+新能源车主）样本",
      title2: "数据权威·覆盖全面·用户活跃·稳定增长·样本直达·技术完善",
      name1: "汽车国别",
      name2: "汽车品牌",
      name3: "汽车车型",
      name4: "车型细分",
      name5: "汽车价位",
      name6: "购车用车",
      tips1: {
        p1: "国产",
        p2: "德系",
        p3: "日系",
        p4: "美系",
        p5: "韩系",
      },
      tips2: {
        p1: "大众",
        p2: "丰田",
        p3: "本田",
        p4: "奔驰",
        p5: "宝马",
      },
      tips3: {
        p1: "轿车",
        p2: "SUV",
        p3: "MPV",
        p4: "跑车",
      },
      tips4: {
        p1: "紧凑型车",
        p2: "中型车",
        p3: "大型车",
      },
      tips5: {
        p1: "5～10万",
        p2: "10～20万",
        p3: "20～30万",
        p4: "50～100万",
        p5: "100万以上",
      },
      tips6: {
        p1: "购车时间",
        p2: "购车渠道",
        p3: "使用年限",
        p4: "用车频次",
        p5: "4s店信息",
      },
    },
    page5: {
      title1: "部分国家样本数据",
      data: [
        {
          flag: "flag1",
          country: "中国",
          samplesNumber: "3,874,547",
          male: "2,014,764",
          woman: "1,859,783",
          age1: "658,673",
          age2: "1,007,382",
          age3: "1,511,073",
          age4: "697,418",
        },
        {
          flag: "flag2",
          country: "中国台湾",
          samplesNumber: "655,328",
          male: "327,664",
          woman: "327,664",
          age1: "131,066",
          age2: "242,471",
          age3: "203,152",
          age4: "78,639",
        },
        {
          flag: "flag3",
          country: "中国香港",
          samplesNumber: "32,196",
          male: "16,420",
          woman: "15,776",
          age1: "5,795",
          age2: "10,303",
          age3: "11,269",
          age4: "4,829",
        },
        {
          flag: "flag4",
          country: "日本",
          samplesNumber: "3,138,745",
          male: "1,412,435",
          woman: "1,726,310",
          age1: "345,262",
          age2: "847,461",
          age3: "1,286,885",
          age4: "659,136",
        },
        {
          flag: "flag5",
          country: "韩国",
          samplesNumber: "378,532",
          male: "185,481",
          woman: "193,051",
          age1: "87,062",
          age2: "128,701",
          age3: "128,701",
          age4: "34,068",
        },
        {
          flag: "flag6",
          country: "新加坡",
          samplesNumber: "131,461",
          male: "67,045",
          woman: "64,416",
          age1: "27,607",
          age2: "48,641",
          age3: "40,753",
          age4: "14,461",
        },
        {
          flag: "flag7",
          country: "印度尼西亚",
          samplesNumber: "2,013,389",
          male: "1,288,569",
          woman: "724,820",
          age1: "563,749",
          age2: "986,561",
          age3: "402,678",
          age4: "60,402",
        },
        {
          flag: "flag8",
          country: "泰国",
          samplesNumber: "1,632,094",
          male: "701,800",
          woman: "930,294",
          age1: "505,949",
          age2: "669,159",
          age3: "326,419",
          age4: "130,568",
        },
        {
          flag: "flag9",
          country: "越南",
          samplesNumber: "913,725",
          male: "529,961",
          woman: "383,765",
          age1: "274,118",
          age2: "383,765",
          age3: "191,882",
          age4: "63,961",
        },
        {
          flag: "flag10",
          country: "马来西亚",
          samplesNumber: "359,617",
          male: "169,020",
          woman: "190,597",
          age1: "122,270",
          age2: "143,847",
          age3: "71,923",
          age4: "21,577",
        },
        {
          flag: "flag11",
          country: "菲律宾",
          samplesNumber: "583,202",
          male: "256,609",
          woman: "326,593",
          age1: "204,121",
          age2: "262,441",
          age3: "81,648",
          age4: "34,992",
        },
        {
          flag: "flag12",
          country: "印度",
          samplesNumber: "1,524,157",
          male: "929,736",
          woman: "594,421",
          age1: "624,904",
          age2: "563,938",
          age3: "259,107",
          age4: "76,208",
        },
      ],
    },
    page6: {
      title1: "多渠道流量投放",
      tips1: "多渠道平台合作，引流过多样本，为样本可以注入新鲜血液",
      tips2: "微博，微信公众号，小红书，今日头条等平台合作",
      tips3: "主流电商平台合作，更真实的购物消费习惯数据。",
    },
  },
  consumer: {
    page1: {
      title1: "4000万+全球会员 覆盖25+国家和地区",
      title2: "线上与线下结合的招募及管理模式，可执行各类项目",
      title3: "更多",
    },
    page2: {
      name1: "丰富的招募渠道",
      name2: "最新属性划分",
      name3: "结合用户兴趣",
      name4: "数据质量保证",
      tips1: "搜索引擎、朋友推荐、渠道合作",
      tips11: "微信推广",
      tips2: "更精准预估出现率",
      tips22: "满足各种调研基础需求",
      tips3: "患者、母婴、车主、B2B",
      tips33: "金融保险等20+维度",
      tips4: "高额积分/现金奖励+信用体系",
      tips44: "提升数据质量",
    },
    page3: {
      left: {
        name: "样本在线询价",
        title1: "真实的",
        tips1: "用户资源",
        title2: "丰富的",
        tips2: "细分人群标签",
        title3: "广泛的",
        tips3: "投放渠道",
      },
      right: {
        title1: "387万+海量样本库",
        tips1: "覆盖全国各地、各行业、各年龄段",
        tips2: "属性标签+兴趣标签+行业标签",
        labels1: {
          name: "人口标签:",
          label1: "国家",
          label2: "性别",
          label3: "学历",
          label4: "收入",
          label5: "年龄",
        },
        labels2: {
          name: "兴趣标签:",
          label1: "汽车",
          label2: "旅游",
          label3: "数码",
          label4: "理财",
        },
        labels3: {
          name: "行为标签:",
          label1: "上网时长",
          label2: "手游类型",
          label3: "支付方式",
        },
      },
    },
    page4: {
      title1: "数据质量保证",
      tips1: "答题信用值体系认证，全面跟踪会员答题质量。",
      tips2: "通过手机认证、实名认证，保证样本真实且唯一",
      tips3: "通过IP、Cookie、网络环境等技术识别，排除重复作答",
      tips4: "最短答题时间限制、多链接追踪，引导受访者认真作答",
    },
    page5: {
      title1: "样本细分",
      tips1: "数据权威 覆盖全面 稳定增长 样本直达 技术完善",
      tips2: "200万+车主样本 100万+母婴样本 150万+医疗样本",
      name1: "汽车样本组",
      name2: "母婴样本组",
      name3: "旅游样本组",
      name4: "电脑数码样本组",
      tipBox1: {
        tips1: "汽车国别",
        tips2: "汽车品牌",
        tips3: "汽车车型",
        tips4: "车型细分",
        tips5: "汽车价位",
      },
      tipBox2: {
        tips1: "孕期状态",
        tips2: "婴儿年龄",
        tips3: "婴儿性别",
        tips4: "婴儿产品使用情况",
        tips5: "家庭消费金额",
      },
      tipBox3: {
        tips1: "境内、境外旅游次数",
        tips2: "境外旅游目的地",
        tips3: "境内境外旅游消费金额",
        tips4: "使用的国内国际航班品牌",
        tips5: "使用的旅行社",
      },
      tipBox4: {
        tips1: "购买预算",
        tips2: "使用电脑的用途",
        tips3: "拥有电脑的类型",
        tips4: "了解和喜爱的品牌",
        tips5: "选择电脑考虑的因素",
      },
    },
  },
  symposium: {
    page1: {
      title1: "在线座谈会系统",
      tips1: "样本全国覆盖、移动在线、无需出差，基于角色的讨论小组",
      tips2:
        "主持人、受访者、观察者，模仿传统座谈会的使用场景，根据业务的需求每个角色的权限都不同",
    },
    page2: {
      title1: "动态用户属性标签",
      title2: "手机及邮件提醒",
      title3: "数据灵活导出",
      title4: "座谈会/留置作业",
    },
    page3: {
      title1: "一对一语言访谈",
      tips1:
        "基于角色的线上讨论小组，实现品牌与消费者的高效互动，实现在线快速获取用户真实感知，获取用户反馈，实现深度洞察 ",
      title2: "在线视频访谈",
      tips2:
        "基于角色的线上讨论小组，实现品牌与消费者的高效互动，实现在线快速获取用户真实感知，获取用户反馈，实现深度洞察 ",
    },
    page4: {
      title1: "部分商业案例",
      title2: "某家电品牌",
      title3: "关于某电冰箱外观测试",
    },
  },
  program: {
    page1: {
      title1: "问卷编程系统",
      tips1: "质量把控 自适应电脑端和移动端",
    },
    page2: {
      name1: "70+问卷语言",
      name2: "20+种类题型",
      name3: "云储存服务器",
      name4: "支持复杂逻辑",
      name5: "重复作答限制",
      name6: "IP检查",
      name7: "Cookie检查",
      name8: "问卷计时功能",
      tips1: "问卷支持多达70+问卷语言",
      tips2:
        "20+基础题型，以及MaxDiff题、图片拖拽题、图片选择题、热力图、NPS题、录音题等高级题型",
      tips3: "采用云存储服务器，高效、安全、扩容性强",
      tips4: "轻松设置问卷跳转逻辑，便捷实现题目循环和选项随机",
      tips5:
        "通过电脑唯一识别技术和手机唯一机器码识别，项目管理系统可以筛查掉重复参与样本",
      tips6: "校验IP地址的唯一性",
      tips7: "cookie的唯一性",
      tips8: "最短答题时间限制、多链接追踪，引导受访者认真作答",
    },
    page3: {
      title1: "支持复杂的问卷设计编程",
      name1: "调研类型",
      name2: "编程方法",
      tips1: "概念测试",
      tips2: "广告测试",
      tips3: "ABtest",
      tips4: "员工满意度",
      tips5: "活动效果前后测试",
      tips6: "联合分析Conjoint",
      tips7: "最大化差异度量MaxDiff",
    },
    page4: {
      title1: "定制化数据处理",
      title2: "支持Excel、SPSS等多种数据格式导出",
      title3: "根据客户需求对开放题翻译，根据客户需求对开放题编码",
      title4: "根据客户需求提供数据交叉表",
    },
  },
  survey: {
    page1: {
      title1: "定制化调研社区",
      title2:
        "提供自有定制化在线调研系统、调研社区、在线定性系统、帮助客户定制化招募样本，并帮助客户进行样本库运营管理",
    },
    page2: {
      left: {
        title1: "自有系统",
        tips1: "自主研发社区调研工具",
        title2: "定制问卷模版",
        tips2: "根据企业需求",
        tips3: "打造专属问卷模版",
      },
      centre: {
        title1: "定制化调研社区",
      },
      right: {
        title1: "权限控制管理",
        tips1: "对数据的使用以及管理权限",
        tips2: "严格控制系统内部人员",
        title2: "数据加密防护",
        tips3: "企业内部数据",
        tips4: "安全严密防护",
      },
    },
    page3: {
      title1: "企业定制服务流程",
      tips1: "“脉问科技”自主研发的调研社区工具;",
      tips2: "致力于创造一个与消费者即时互动的环境，通过多元化的调研方法;",
      tips3: " 输出对企业创新的、可持续的、可落地的结果",
      box1: "提出需求",
      box2: "提出解决方案",
      box3: "问卷设计",
      box4: "数据收集",
      box5: "数据处理和分析",
      box6: "最终报告输出",
    },
    page4: {
      title1: "您的需求",
    },
  },
  programme: {
    page1: {
      title1:
        "脉问科技倾力打造的大数据智能消费者洞察解决方案，致力于释放数据的商业价值",
      tips1:
        "让企业与消费者之间进行多场景、多渠道的无缝衔接，提升企业营销和管理效率;",
      tips2:
        "“脉问科技”乐意与您分享自己在问卷编程和消费者调研社区建设上的心得，提供在线调研社区代运营和调研系统开发的服务;",
      btn: "了解更多",
    },
    page2: {
      title1: "市场细分",
      title2: "产品研发",
      title3: "内容优化",
      title4: "广告效果",
      title5: "品牌监测",
      title6: "用户关系",
      title7: "调研社区开发",
      title8: "产品留置测试",
      tips1: "有的放矢 - 了解市场，了解你的消费者",
      tips2: "优化组合 - 在产品设计的各个阶段筛选最优项，提升上市后的成功率",
      tips3: "内容为王 - 优化传播内容和载体，提升用户对品牌的关注度",
      tips4: "数据说话 - 评估数字营销对流量、偏好和转化的影响",
      tips5: "知己知彼-衡量品牌在市场的 曝光力、吸引力和拥护力",
      tips6: "开源节流-帮助品牌吸纳更多 用户、提升留存率、用户黏性",
      tips7:
        "量身打造 - 从您的真实需求出发，提供与企业已有体系无缝对接的一整套服务",
      tips8: "严谨缜密 - 年服务10+留置项目，总结了自己一套严谨的产品留置方案",
    },
  },
  news: {
    page1: {
      title1: "公司动态",
      synopsis: "我是内容简介我是内容简介我是内容简介",
      read: "/阅读全文",
      content: "我是内容我是内容",
      title2: "发布时间",
      title3: "阅读全文",
    },
  },
  newsDetails: {
    page1: {
      title1: "新闻内容",
      title2: "相关新闻",
      title3: "查看更多",
      title4: "返回列表",
    },
  },
  aboutUs: {
    page1: {
      tips1: "专业的一站式市场研究服务",
    },
    page2: {
      title1: "我们的宗旨",
      tips1: "洞察市场脉搏，让您更懂消费者。您可信赖的在线数据服务专家！",
      tips2:
        "通过大小数据结合，让数据的商业价值得有以释放，从而帮助提高企业的数据决策力，让数据成为新时代企业的宝贵资产！“脉问科技”有成熟的大数据技术背景和丰富的营销应用;通过AI智能化学习、行为标签化构建、结果可视化等技术。",
      tips3: "我们以更实时的方式提供更优质的市场洞察！",
    },
    page3: {
      title1: "获奖证书",
      tips1: "国家相关机构对上海脉问的认可",
      box: {
        name1: "数据智能专业委员会单位",
        name2: "中国市场信息调查协会单位",
        name3: "涉外调查许可证",
        name4: "中国信息协会市场研究业分会(CMRA)会员",
      },
    },
    page4: {
      title1: "发展历程",
    },
    page5: {
      title1: "联系我们",
      tips1: "上海市浦东新区东方路738号裕安大厦3楼",
    },
  },
  contactUs: {
    page1: {
      title1: "联系我们",
      tips1: "上海市浦东新区东方路738号裕安大厦3楼",
    },
    page2: {
      title1: "审计监督",
      tips1:
        "您好！在参加业务合作过程中，如遇到工作人员吃拿卡要、索要回扣、操纵询价或 招标等违规行为时，请及时与审计中心联系，并请提供相应资料便于快速处理",
      title2: "举报联系方式",
      box: {
        name1: "举报电话: ",
        name2: "举报邮箱: ",
        name3: "邮寄地址: ",
        name4: "邮编: ",
        content1: "上海市浦东新区东方路738号裕安大厦3楼",
      },
    },
    page3: {
      title1: "我们的地址",
      box1: {
        name: "上海",
        email: "邮件",
        address: "地址: 上海市浦东新区东方路738号裕安大厦3楼",
      },
      box2: {
        name: "南京",
        email: "邮件",
        address: "地址: 南京市浦口区星火路15号智芯科技楼7楼",
      },
    },
  },
  joinUs: {
    page1: {
      title1: "加入我们",
      tips1: "携手创造更好的明天",
    },
    page2: {
      title1: "福利待遇",
      name1: "系统培训",
      name2: "带薪年假",
      name3: "团队文化",
      name4: "五险一金",
    },
    page3: {
      title1: "招聘职位",
      name1: "高级产品经理",
      name2: "高级前端开发工程师",
      name3: "iOS开发工程师",
      boxs: [
        {
          name: "职位描述",
          pList: [
            "熟练应用各种市场分析工具及产品原型工具，能够撰写和输出规范的产品白皮书;",
            "有产品策划和产品管理思维，能够撰写市场调研、市场分析、可行性分析报告等;",
            "具备良好的沟通协调能力、系统性思维和创新意识;",
            "具有产品需求、归纳能力、市场敏觉洞察能力;",
            "有TOB企业5年以上产品经理工作经验;",
            "有前端相关技术背景，具有产品架构师能力者优先考虑。",
          ],
          email: "请将您的简历发送至:",
        },
        {
          name: "职位描述",
          pList: [
            "熟练应用各种市场分析工具及产品原型工具，能够撰写和输出规范的产品白皮书;",
            "有产品策划和产品管理思维，能够撰写市场调研、市场分析、可行性分析报告等;",
            "具备良好的沟通协调能力、系统性思维和创新意识;",
            "具有产品需求、归纳能力、市场敏觉洞察能力;",
            "有TOB企业5年以上产品经理工作经验;",
            "有前端相关技术背景，具有产品架构师能力者优先考虑。",
          ],
          email: "请将您的简历发送至:",
        },
        {
          name: "职位描述",
          name2: "有下列经验者优先",

          pList: [
            "本科及以上,3-5年开发经验;",
            "熟练掌握JS原生代码开发,对原型编程有深入的理解;",
            "熟练使用过至少一种前端框架（如:Angularjs/reactjs/backbonejs/emberjs/knockoutjs);",
            "具有产品需求、归纳能力、市场敏觉洞察能力;",
            "熟悉模块化开发思路,实际使用过至少一种框架（如:requirejs/seajs); ",
          ],
          pList2: [
            "计算机相关专业统招本科以上学历;",
            "具有Reactjs实际开发经验;",
            "具有Nodejs后端开发经验;",
            "具有较好的UI设计能力;",
            "有个人作品可以演示;",
          ],
          email: "请将您的简历发送至:",
        },
      ],
    },
  },
  privacyPolicy: {
    name: "脉问科技隐私声明",
    title1:
      "脉问科技网站承诺、尊重并保护用户的隐私。本生声明披露了本网站对用户个人信息所采取的收集、使用和保护政策,请仔细阅读,我们通过采集到的信息仅限于公司内部用做以下用途;",
    useTo1: "1.提供个性化的网上服务",
    useTo2: "2.为您提供有关我们产品和服务的信息",
    useTo3: "3.了解市场状况",
    title2: "信息收集",
    boxs: [
      {
        tipsName: "1.使用非个人化信息",
        tipsContent:
          "<p>我们将通过您的IP地址来手机非个人化信息,如您的浏览器性质、操作系统种类、为您提供接入服务器的ISP的域名等,以优化在您计算机屏幕上显示的页面。通过收集上述信息，我们还进行客流量的统计从而改进网站的管理和服务。</p>",
      },
      {
        tipsName: "2.个人化信息",
        tipsContent:
          "<p>当您在本网站进行注册登记，在您的同意及确认下，本网站将通过登记表记录您的个人资料，这些个人资料包括：</p> <p>个人识别资料：如姓名、性别、年龄、出生日期、电话、通讯地址、电子邮件地址等情况。</p> <p>个人背景：职业、职务等。</p>",
      },

      {
        tipsName: "3.信息安全和质量",
        tipsContent:
          "<p>本网站将对您所提供的资料进行严格的管理及保护，本网站将使用相应的技术，防止您的个人资料丢失、被盗用或遭篡改。</p>",
      },
    ],
    prompt:
      "*我们可能会不时修改我们的隐私政策。这些修改会反映在本声明中。任何修改都会将您的满意度置于前位。我们鼓励您在每次访问本网站的网页时都查阅我们的隐私政策。",
  },
};
