<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="contact-top">
      <div class="title">{{ $t("contactUs.page1.title1") }}</div>
      <div class="contact-absolute">
        <div class="card" v-for="item in 2" :key="item">
          <img src="@/assets/contactUs/emali.png" />
          <div class="title-box">
            <div class="blod" v-if="item === 1">Drop a Line</div>
            <div class="tips" v-if="item === 1">team@baishulai.com</div>
            <div class="blod" v-if="item === 2">Visit us</div>
            <div class="tips" v-if="item === 2">
              {{ $t("contactUs.page1.tips1") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审计监督 -->
    <div class="supervise">
      <div class="title">{{ $t("contactUs.page2.title1") }}</div>
      <div class="line"></div>
      <div class="tips">{{ $t("contactUs.page2.tips1") }}</div>
      <div class="title2">{{ $t("contactUs.page2.title2") }}</div>
      <div class="box">
        <div class="box-item">
          {{ $t("contactUs.page2.box.name2") }} david.sun@mavensurvey.com
        </div>
        <div class="box-item">
          {{ $t("contactUs.page2.box.name3") }}
          {{ $t("contactUs.page2.box.content1") }}
        </div>
        <div class="box-item">{{ $t("contactUs.page2.box.name4") }} 211899</div>
      </div>
    </div>
    <!-- 我们的地址 -->
    <div class="address">
      <div class="title">{{ $t("contactUs.page3.title1") }}</div>
      <div class="line"></div>
      <div class="address-box">
        <div class="address-item" v-for="item in 2" :key="item">
          <div class="name">{{ $t(`contactUs.page3.box${item}.name`) }}</div>
          <div class="address-title">
            {{ $t(`contactUs.page3.box${item}.address`) }}
          </div>
          <div class="email">
            {{ $t(`contactUs.page3.box${item}.email`) }}: team@mavensurvey.com
          </div>

          <img
            class="positioning"
            @click="goMap"
            src="@/assets/contactUs/positioning.png"
          />
        </div>
      </div>
    </div>
    <!-- 悬浮图标 -->
    <div class="suspension">
      <img
        class="img"
        v-for="item in 3"
        @click="changeSuspension(item)"
        :key="item"
        :src="require(`@/assets/contactUs/suspension${item}.png`)"
      />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goMap() {
      window.open(
        "https://uri.amap.com/marker?position=121.524345,31.228567&name=上海市浦东新区东方路738号裕安大厦3楼"
      );
    },
    changeSuspension(index){
      if(index===3){
         window.scrollTo(0, 0);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  // 第一屏
  .contact-top {
    width: 100%;
    height: 394px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/contactUs/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .title {
      text-align: center;
      font-size: 24px;
      color: #fff;
      font-weight: bolder;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .contact-absolute {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      bottom: -148px;
      .card {
        width: 505px;
        height: 202px;
        background: #ffffff;
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        display: flex;
        align-items: center;
        img {
          width: 72px;
          height: 72px;
          margin-left: 60px;
          flex-shrink: 0;
        }
        .title-box {
          display: flex;
          flex-direction: column;
          padding: 0 30px 0 30px;
          .blod {
            font-size: 24px;
            font-weight: bolder;
            color: #232323;
          }
          .tips {
            font-size: 20px;
            color: #232323;
            margin-top: 15px;
          }
        }
      }
      .card:first-child {
        margin-right: 16px;
      }
    }
  }
  // 审计监督
  .supervise {
    margin-top: 238px;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 26px;
    }
    .tips {
      text-align: center;
      width: 600px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      line-height: 30px;
      font-size: 16px;
      color: #232323;
    }
    .title2 {
      text-align: center;
      font-size: 20px;
      color: #232323;
      margin-top: 50px;
    }
    .box {
      width: 612px;
      padding: 38px 90px;
      opacity: 1;
      border: 1px solid #d3d3d3;
      margin-top: 72px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      .box-item {
        color: #666666;
        font-size: 16px;
        margin-bottom: 24px;
      }
      .box-item:last-child {
        margin-bottom: 0;
      }
    }
  }
  // 我们的地址
  .address {
    margin-top: 110px;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #232323;
      text-align: center;
    }
    .line {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 3px;
      background-color: #0b7cff;
      margin-top: 12px;
      margin-bottom: 72px;
    }
    .address-box {
      display: flex;
      justify-content: center;
      .address-item {
        width: 256px;
        .name {
          text-align: center;
          font-size: 20px;
          color: #707070;
        }
        .address-title {
          color: #707070;
          font-size: 14px;
          margin-top: 18px;
          text-align: center;
        }
        .email {
          color: #707070;
          font-size: 14px;
          margin-top: 18px;
          text-align: center;
        }
        .positioning {
          width: 256px;
          height: 154px;
          margin-top: 51px;
          margin-bottom: 190px;
          cursor: pointer;
        }
      }
      .address-item:first-child {
        margin-right: 280px;
      }
    }
  }
  //悬浮图标
  .suspension {
    position: fixed;
    right: 145px;
    bottom: 81px;
    display: flex;
    flex-direction: column;
    z-index: 999;
    .img {
      width: 40px;
      height: 40px;
      margin-bottom: 28px;
      border-radius: 20px;
      box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.08);
    }
    .img:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1400px) {
  .main {
    .suspension {
      right: 100px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .main {
    // 第一屏
    .contact-top {
      width: 100%;
      height: 394px;
      background-color: #000;
      position: relative;
      background-image: url("../assets/contactUs/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .title {
        text-align: center;
        font-size: 24px;
        color: #fff;
        font-weight: bolder;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .contact-absolute {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        bottom: -300px;
        .card {
          width: 505px;
          height: 202px;
          background: #ffffff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          align-items: center;
          img {
            width: 72px;
            height: 72px;
            margin-left: 60px;
            flex-shrink: 0;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            padding: 0 30px 0 30px;
            .blod {
              font-size: 24px;
              font-weight: bolder;
              color: #232323;
            }
            .tips {
              font-size: 20px;
              color: #232323;
              margin-top: 15px;
            }
          }
        }
        .card:first-child {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    // 审计监督
    .supervise {
      margin-top: 400px;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 26px;
      }
      .tips {
        text-align: center;
        width: 600px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        line-height: 30px;
        font-size: 16px;
        color: #232323;
      }
      .title2 {
        text-align: center;
        font-size: 20px;
        color: #232323;
        margin-top: 50px;
      }
      .box {
        width: 612px;
        padding: 38px 20px;
        opacity: 1;
        border: 1px solid #d3d3d3;
        margin-top: 72px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .box-item {
          color: #666666;
          font-size: 16px;
          margin-bottom: 24px;
        }
        .box-item:last-child {
          margin-bottom: 0;
        }
      }
    }

    // 我们的地址
    .address {
      margin-top: 110px;
      width: calc(100% - 80px);
      margin-left: 40px;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #232323;
        text-align: center;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 72px;
      }
      .address-box {
        display: flex;
        justify-content: space-between;
        .address-item {
          width: 256px;
          .name {
            text-align: center;
            font-size: 20px;
            color: #707070;
          }
          .address-title {
            color: #707070;
            font-size: 14px;
            margin-top: 18px;
            text-align: center;
          }
          .email {
            color: #707070;
            font-size: 14px;
            margin-top: 18px;
            text-align: center;
          }
          .positioning {
            width: 256px;
            height: 154px;
            margin-top: 51px;
            margin-bottom: 190px;
            cursor: pointer;
          }
        }
        .address-item:first-child {
          margin-right: 0;
        }
      }
    }

    .suspension {
      right: 60px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .main {
    // 第一屏
    .contact-top {
      width: 100%;
      height: 300px;
      background-color: #000;
      position: relative;
      background-image: url("../assets/contactUs/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .title {
        text-align: center;
        font-size: 20px;
        color: #fff;
        font-weight: bolder;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .contact-absolute {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        bottom: -300px;
        .card {
          width: 400px;
          height: 180px;
          background: #ffffff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          align-items: center;
          img {
            width: 72px;
            height: 72px;
            margin-left: 60px;
            flex-shrink: 0;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            padding: 0 30px 0 30px;
            .blod {
              font-size: 16px;
              font-weight: bolder;
              color: #232323;
            }
            .tips {
              font-size: 14px;
              color: #232323;
              margin-top: 15px;
            }
          }
        }
        .card:first-child {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
    // 审计监督
    .supervise {
      margin-top: 350px;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 26px;
      }
      .tips {
        text-align: center;
        width: 400px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        line-height: 30px;
        font-size: 14px;
        color: #232323;
      }
      .title2 {
        text-align: center;
        font-size: 18px;
        color: #232323;
        margin-top: 40px;
      }
      .box {
        width: 400px;
        padding: 18px 15px;
        opacity: 1;
        border: 1px solid #d3d3d3;
        margin-top: 30px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .box-item {
          color: #666666;
          font-size: 14px;
          margin-bottom: 24px;
        }
        .box-item:last-child {
          margin-bottom: 0;
        }
      }
    }

    // 我们的地址
    .address {
      margin-top: 60px;
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-bottom: 50px;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 30px;
      }
      .address-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .address-item {
          width: 100%;
          .name {
            text-align: center;
            font-size: 16px;
            color: #707070;
          }
          .address-title {
            color: #707070;
            font-size: 14px;
            margin-top: 18px;
            text-align: center;
          }
          .email {
            color: #707070;
            font-size: 14px;
            margin-top: 18px;
            text-align: center;
          }
          .positioning {
            width: 256px;
            height: 154px;
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
          }
        }
        .address-item:first-child {
          margin-right: 0;
        }
      }
    }

    .suspension {
      right: 40px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .main {
    // 第一屏
    .contact-top {
      width: 100%;
      height: 300px;
      background-color: #000;
      position: relative;
      background-image: url("../assets/contactUs/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .title {
        text-align: center;
        font-size: 20px;
        color: #fff;
        font-weight: bolder;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .contact-absolute {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        bottom: -250px;
        .card {
          width: 300px;
          height: 150px;
          background: #ffffff;
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
            margin-left: 10px;
            flex-shrink: 0;
          }
          .title-box {
            display: flex;
            flex-direction: column;
            padding: 0 10px 0 10px;
            .blod {
              font-size: 16px;
              font-weight: bolder;
              color: #232323;
            }
            .tips {
              font-size: 14px;
              color: #232323;
              margin-top: 15px;
            }
          }
        }
        .card:first-child {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    // 审计监督
    .supervise {
      margin-top: 300px;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #232323;
        text-align: center;
      }
      .line {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 3px;
        background-color: #0b7cff;
        margin-top: 12px;
        margin-bottom: 26px;
      }
      .tips {
        text-align: center;
        width: 300px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        line-height: 30px;
        font-size: 14px;
        color: #232323;
      }
      .title2 {
        text-align: center;
        font-size: 18px;
        color: #232323;
        margin-top: 40px;
      }
      .box {
        width: 300px;
        padding: 18px 15px;
        opacity: 1;
        border: 1px solid #d3d3d3;
        margin-top: 30px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .box-item {
          color: #666666;
          font-size: 14px;
          margin-bottom: 24px;
        }
        .box-item:last-child {
          margin-bottom: 0;
        }
      }
    }

    .suspension {
      right: 30px;
    }
  }
}
</style>