<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="programme-top">
      <div :class="['title', { title2: $i18n.locale === 'en' }]">
        {{ $t("programme.page1.title1") }}
      </div>
      <div class="tips">{{ $t("programme.page1.tips1") }}</div>
      <div class="tips">{{ $t("programme.page1.tips2") }}</div>
      <button>{{ $t("programme.page1.btn") }}</button>
    </div>

    <!-- 第二屏 -->
    <div class="programme-second">
      <div class="card-item" v-for="item in 8">
        <div class="img-box">
          <img :src="require(`@/assets/programme/card${item}.png`)" />
          <div class="title">{{ $t(`programme.page2.title${item}`) }}</div>
        </div>
        <div class="tips-box">{{ $t(`programme.page2.tips${item}`) }}</div>
      </div>
    </div>

    <!-- 你的需求 -->
    <div class="demand">
      <div class="title">{{ $t("survey.page4.title1") }}</div>

      <div class="boxs">
        <el-input
          v-model="input1"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '请输入企业名称'
              : 'Please enter the company name'
          }`"
        ></el-input>
        <el-input
          v-model="input2"
          :placeholder="`${
            $i18n.locale === 'zn' ? '请输入姓名' : 'Please enter your name'
          }`"
        ></el-input>
        <el-select
          v-model="value1"
          :placeholder="`${
            $i18n.locale === 'zn' ? '请选择行业' : 'Please select an industry'
          }`"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="$i18n.locale === 'zn' ? item.label : item.elabel"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="boxs">
        <el-select
          v-model="value2"
          :placeholder="`${
            $i18n.locale === 'zn' ? '请选择地区' : 'Please select a region'
          }`"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="$i18n.locale === 'zn' ? item.label : item.elabel"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="input3"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '请输入手机号'
              : 'Please enter your phone number'
          }`"
        ></el-input>
        <el-input
          v-model="input4"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '请输入邮箱'
              : 'Please enter your email address'
          }`"
        ></el-input>
      </div>

      <div class="boxs">
        <el-input
          class="textarea"
          type="textarea"
          :rows="2"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '留下您的需求，获取解决方案'
              : 'Leave your needs and obtain solutions'
          }`"
          v-model="textarea"
        >
        </el-input>
      </div>

      <button @click="submit" :disabled="disabled">
        {{ $i18n.locale === "zn" ? "立即提交" : "Submit" }}
      </button>
    </div>
  </div>
</template>

<script>
import { setGuestbookAdd } from "@/api/app";
import { Message } from "element-ui";
export default {
  data() {
    return {
      options1: [
        {
          value: 1,
          label: "互联网",
          elabel: "internet",
        },
        {
          value: 2,
          label: "医疗",
          elabel: "medical treatment",
        },
      ],
      options2: [
        {
          value: 1,
          label: "亚洲",
          elabel: "Asia",
        },
        {
          value: 2,
          label: "欧洲",
          elabel: "Europe",
        },
      ],
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      value1: "",
      value2: "",
      textarea: "",
      disabled: false,
    };
  },
  methods: {
    submit() {
      const industry = this.options1.find((item) => {
        return item.value == this.value1;
      });
      const region = this.options2.find((item) => {
        return item.value == this.value2;
      });
      const parameter = {
        company: this.input1, //公司
        name: this.input2, //名字
        industry: industry ? industry.label : "", //行业
        region: region ? region.label : "", //地区
        phone: this.input3, //手机
        email: this.input4, //邮箱
        body: this.textarea, //留言
      };
      setGuestbookAdd(parameter).then((res) => {
        console.log(1111);
        if (res.status === 200) {
          Message({
            showClose: true,
            message: '提交成功',
            duration: 3000,
            type: "success",
          });
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.main {
  // 第一屏
  .programme-top {
    width: 100%;
    height: 413px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/programme/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: #fff;
    .title {
      width: calc(100% - 240px);
      padding-top: 153px;
      margin-left: 120px;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .title2 {
      padding-top: 100px;
    }
    .tips {
      width: calc(100% - 240px);
      margin-left: 120px;
      font-size: 16px;
      margin-bottom: 10px;
    }
    button {
      padding: 0;
      margin: 0;
      border: none;
      margin-left: 120px;
      margin-top: 34px;
      padding: 0 24px;
      height: 32px;
      background: #ffffff;
      border-radius: 16px;
      font-size: 16px;
      color: #0f6dd9;
    }
  }
  // 第二屏
  .programme-second {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 240px);
    margin-left: 120px;
    .card-item {
      width: 210px;
      margin-right: calc((100% - 840px) / 3);
      margin-top: 90px;
      .img-box {
        width: 210px;
        height: 248px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 20px 1px rgba(12, 31, 52, 0.05);
        border-radius: 12px;
        img {
          width: 70px;
          height: 70px;
        }
        .title {
          font-size: 18px;
          color: #232323;
          margin-top: 30px;
          text-align: center;
        }
      }
      .tips-box {
        margin-top: 37px;
        font-size: 16px;
        color: #666;
        text-align: center;
      }
    }
    .card-item:nth-child(4n) {
      margin-right: 0;
    }
  }
  // 你的需求
  .demand {
    width: 100%;
    height: 680px;
    background: #f7fbff;
    margin-top: 90px;
    .title {
      text-align: center;
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      padding-top: 60px;
      margin-bottom: 46px;
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      width: 909px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 30px;
      .el-input {
        width: 287px;
      }
      .el-select {
        width: 287px;
      }
      .textarea {
        width: 100%;
      }
    }
    button {
      padding: 0;
      margin: 0;
      border: none;
      width: 186px;
      height: 50px;
      background: #0b7cff;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 16px;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}
</style>
<style scoped>
.textarea >>> .el-textarea__inner {
  min-height: 178px !important;
  /* box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05); */
  border-color: #e5e5e5;
}
.textarea >>> .el-textarea__inner:hover {
  border-color: #0b7cff;
}
.textarea >>> .el-textarea__inner:focus {
  border-color: #0b7cff;
}
.main >>> .el-input__inner {
  border-color: #e5e5e5;
  /* box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05); */
}
.main >>> .el-input__inner:hover {
  border-color: #0b7cff;
}
.main >>> .el-input__inner:focus {
  border-color: #0b7cff;
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 1250px) {
  .main {
    // 第一屏
    .programme-top {
      .title {
        padding-top: 120px;
      }
      .title2 {
        padding-top: 80px;
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .main {
    // 第一屏
    .programme-top {
      .title {
        padding-top: 120px;
        width: calc(100% - 80px);
        margin-left: 40px;
      }
      .title {
        padding-top: 80px;
      }
      .tips {
        width: calc(100% - 80px);
        margin-left: 40px;
        font-size: 16px;
        margin-bottom: 10px;
      }
      button {
        margin-left: 40px;
      }
    }
    // 第二屏
    .programme-second {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 80px);
      margin-left: 40px;
      .card-item {
        width: 210px;
        margin-right: calc((100% - 840px) / 3);
        margin-top: 90px;
        .img-box {
          width: 210px;
          height: 248px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          align-items: center;
          box-shadow: 0px 3px 20px 1px rgba(12, 31, 52, 0.05);
          border-radius: 12px;
          img {
            width: 70px;
            height: 70px;
          }
          .title {
            font-size: 18px;
            color: #232323;
            margin-top: 30px;
            text-align: center;
          }
        }
        .tips-box {
          margin-top: 37px;
          font-size: 16px;
          color: #666;
          text-align: center;
        }
      }
      .card-item:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .main {
    // 第二屏
    .programme-second {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 80px);
      margin-left: 40px;
      justify-content: space-between;
      .card-item {
        width: 45%;
        margin-right: 0;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;
        .img-box {
          width: 210px;
          height: 248px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          align-items: center;
          box-shadow: 0px 3px 20px 1px rgba(12, 31, 52, 0.05);
          border-radius: 12px;
          img {
            width: 70px;
            height: 70px;
          }
          .title {
            font-size: 18px;
            color: #232323;
            margin-top: 30px;
            text-align: center;
          }
        }
        .tips-box {
          margin-top: 37px;
          font-size: 16px;
          color: #666;
          text-align: center;
        }
      }
      .card-item:nth-child(4n) {
        margin-right: 0;
      }
    }
    // 你的需求
    .demand {
      width: 100%;
      height: auto;
      background: #f7fbff;
      margin-top: 40px;
      .title {
        text-align: center;
        font-size: 26px;
        color: #232323;
        font-weight: bolder;
        padding-top: 40px;
        margin-bottom: 46px;
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 300px;
          margin-bottom: 30px;
        }
        .el-select {
          width: 300px;
          margin-bottom: 30px;
        }
        .textarea {
          width: 300px;
          margin-bottom: 30px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 186px;
        height: 50px;
        background: #0b7cff;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .main {
    // 第一屏
    .programme-top {
      height: 413px;
      .title {
        width: calc(100% - 40px);
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-top: 100px;
        text-align: center;
      }
      .title2 {
        padding-top: 60px;
      }
      .tips {
        width: calc(100% - 40px);
        margin-left: 20px;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: center;
      }
      button {
        margin-left: 0px;
        font-size: 14px;
        margin-top: 20px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    // 第二屏
    .programme-second {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 80px);
      margin-left: 40px;
      justify-content: space-between;
      .card-item {
        width: 100%;
        margin-right: 0;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;
        .img-box {
          width: 210px;
          height: 248px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          align-items: center;
          box-shadow: 0px 3px 20px 1px rgba(12, 31, 52, 0.05);
          border-radius: 12px;
          img {
            width: 70px;
            height: 70px;
          }
          .title {
            font-size: 18px;
            color: #232323;
            margin-top: 30px;
            text-align: center;
          }
        }
        .tips-box {
          margin-top: 37px;
          font-size: 16px;
          color: #666;
          text-align: center;
        }
      }
      .card-item:nth-child(4n) {
        margin-right: 0;
      }
    }
    // 你的需求
    .demand {
      width: 100%;
      height: auto;
      background: #f7fbff;
      margin-top: 40px;
      .title {
        text-align: center;
        font-size: 20px;
        color: #232323;
        font-weight: bolder;
        padding-top: 40px;
        margin-bottom: 46px;
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 300px;
          margin-bottom: 30px;
        }
        .el-select {
          width: 300px;
          margin-bottom: 30px;
        }
        .textarea {
          width: 300px;
          margin-bottom: 30px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 186px;
        height: 50px;
        background: #0b7cff;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .main {
    // 第一屏
    .programme-top {
      height: 413px;
      .title {
        width: calc(100% - 40px);
        margin-left: 20px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-top: 100px;
      }
      .title2 {
        padding-top: 60px;
      }
      .tips {
        width: calc(100% - 40px);
        margin-left: 20px;
        font-size: 12px;
        margin-bottom: 10px;
      }
      button {
        margin-left: 20px;
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}
</style>