<template>
  <div id="app">
    <Herder v-if="newRouter"></Herder>
    <router-view />
    <Footer v-if="newRouter"></Footer>
  </div>
</template>
<script>
import Herder from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import { getCurrentInstance } from "vue";
export default {
  name: "App",
  components: { Herder, Footer },
  data() {
    return {
      newRouter: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/privacyPolicy") {
        this.newRouter = false;
      } else {
        this.newRouter = true;
      }
    },
  },
  created() {
    const { proxy } = getCurrentInstance();
    if (proxy.$router.history._startLocation === "/privacyPolicy") {
      this.newRouter = false;
    } else {
      this.newRouter = true;
    }
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-size: 16px;
  color: #333333;
  font-family: "思源黑体,Arial";
}
#nprogress .bar {
  background: #0b7cff !important; //自定义颜色
}
</style>
