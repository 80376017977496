import axios from "axios";

import { Message } from "element-ui";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 500000, // 请求超时
});
// let cancel = null;
//请求拦截
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//相应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status && res.status !== 200) {
      Message({
        showClose: true,
        message: res.msg,
        duration: 3000,
        type: "error",
      });
    }
    return res;
  },
  (error) => {
    if (error.response) {
      const res = error.response.data;
    }
  }
);

export default service;
