<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="sample-top">
      <div class="sample-box">
        <div class="title-blod">
          <span class="left">{{ $t("sample.page1.title1") }}</span>
          <span class="right">{{ $t("sample.page1.title2") }}</span>
        </div>
        <div class="title-tips">
          {{ $t("sample.page1.title3") }}
        </div>
      </div>
    </div>
    <!-- 移动样本 -->
    <div class="moving-amples">
      <div class="left">
        <div class="title-box">
          <div class="title-left">{{ $t("sample.page2.title1") }}</div>
          <div class="title-right">{{ $t("sample.page2.title2") }}</div>
        </div>
        <div class="tips-box">{{ $t("sample.page2.title3") }}</div>
        <div class="conten-box">
          {{ $t("sample.page2.title4") }}
        </div>
      </div>
      <div class="right">
        <div class="phone-box">
          <img
            class="phone"
            id="phone"
            src="@/assets/sample/mobile-phone.png"
          />
          <img class="felx charge" src="@/assets/sample/charge.png" />
          <img class="felx people" src="@/assets/sample/people.png" />
          <img class="felx slide" src="@/assets/sample/slide.png" />
        </div>
      </div>
    </div>
    <!-- 自有样本 -->
    <div class="own-samples">
      <div class="left">
        <div class="left-title">{{ $t("sample.page3.left.title1") }}</div>
        <div class="left-tips">{{ $t("sample.page3.left.title2") }}</div>
        <div class="left-tips">{{ $t("sample.page3.left.title3") }}</div>
        <div class="box-label">
          <Label
            v-for="(item, index) in leftLabel"
            :key="index"
            :value="`${$t(`sample.page3.left.labels.title${index + 1}`)}`"
            :size="item.size"
            :top="$i18n.locale === 'en' ? item.eTop : item.top"
            :left="$i18n.locale === 'en' ? item.eLeft : item.left"
          ></Label>
        </div>
        <div class="box-label2">
          <Label
            v-for="(item, index) in leftLabel2"
            :key="index"
            :value="`${$t(`sample.page3.right.labels.title${index + 1}`)}`"
            :size="item.size"
            :top="$i18n.locale === 'en' ? item.eTop : item.top"
            :left="$i18n.locale === 'en' ? item.eLeft : item.left"
          ></Label>
        </div>
      </div>
      <div class="right">
        <div class="right-title">{{ $t("sample.page3.right.title1") }}</div>
        <div class="right-tips">{{ $t("sample.page3.right.title2") }}</div>
        <div class="right-tips">{{ $t("sample.page3.right.title3") }}</div>
        <div class="box-label">
          <Label
            v-for="(item, index) in rightLabel"
            :key="index"
            :value="`${$t(`sample.page3.right.labels.title${index + 1}`)}`"
            :size="item.size"
            :top="$i18n.locale === 'en' ? item.eTop : item.top"
            :left="$i18n.locale === 'en' ? item.eLeft : item.left"
          ></Label>
        </div>
        <div class="box-label2">
          <Label
            v-for="(item, index) in rightLabel2"
            :key="index"
            :value="`${$t(`sample.page3.right.labels.title${index + 1}`)}`"
            :size="item.size"
            :top="$i18n.locale === 'en' ? item.eTop : item.top"
            :left="$i18n.locale === 'en' ? item.eLeft : item.left"
          ></Label>
        </div>
      </div>
      <div class="own-box">
        <div class="rotate-box">
          <div class="rotate-img"></div>
          <div class="round">
            <div class="number-box">
              387{{ $t("sample.page3.rotate.title1") }}
            </div>
            <div class="title-box">{{ $t("sample.page3.rotate.title2") }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多渠道流量投放 -->
    <div class="multi-channel">
      <div class="left">
        <img
          class="application"
          id="application"
          src="@/assets/sample/application.png"
        />
        <img class="felx-img baidu" src="@/assets/sample/baidu.png" />
        <img class="felx-img toutiao" src="@/assets/sample/toutiao.png" />
        <img class="felx-img qq" src="@/assets/sample/qq.png" />
        <img class="felx-img jy" src="@/assets/sample/jy.png" />
        <!-- <img class="felx-img jd" src="@/assets/sample/jd.png" /> -->
        <img class="felx-img txxw" src="@/assets/sample/txxw.png" />
      </div>
      <div class="right">
        <div class="title">{{ $t("sample.page6.title1") }}</div>

        <div class="tips-box">
          <div class="tips">{{ $t("sample.page6.tips1") }}</div>
          <div class="tips">
            {{ $t("sample.page6.tips2") }}
          </div>
        </div>

        <div class="tips-box">
          <div class="tips">{{ $t("sample.page6.tips3") }}</div>
          <div class="tips"></div>
        </div>
      </div>
    </div>
    <!-- 200万+车主（含50万+新能源车主）样本 -->
    <div class="car-owners">
      <div class="title">{{ $t("sample.page4.title1") }}</div>
      <div class="tips">{{ $t("sample.page4.title2") }}</div>
      <div class="card-boxs">
        <div class="card-item" v-for="(item, index) in owners" :key="index">
          <div class="name">{{ $t(`sample.page4.${item.name}`) }}</div>
          <p v-for="cItem in item.p" :key="cItem">
            {{ $t(`sample.page4.${cItem}`) }}
          </p>
          <div class="dian">. . .</div>
          <img :src="item.url" />
        </div>
      </div>
      <div class="card-boxs2">
        <div class="card-fa">
          <div class="card-item" v-for="(item, index) in owners1" :key="index">
            <div class="name">{{ $t(`sample.page4.${item.name}`) }}</div>
            <p v-for="cItem in item.p" :key="cItem">
              {{ $t(`sample.page4.${cItem}`) }}
            </p>
            <div class="dian">. . .</div>
            <img :src="item.url" />
          </div>
        </div>
        <div class="card-fa">
          <div class="card-item" v-for="(item, index) in owners2" :key="index">
            <div class="name">{{ $t(`sample.page4.${item.name}`) }}</div>
            <p v-for="cItem in item.p" :key="cItem">
              {{ $t(`sample.page4.${cItem}`) }}
            </p>
            <div class="dian">. . .</div>
            <img :src="item.url" />
          </div>
        </div>
      </div>
    </div>

    <div class="car-owners2">
      <div class="title">{{ $t("sample.page4.title1") }}</div>
      <div class="tips">{{ $t("sample.page4.title2") }}</div>
      <div class="card-boxs">
        <div class="card-item" v-for="(item, index) in owners" :key="index">
          <div class="name">{{ $t(`sample.page4.${item.name}`) }}</div>
          <p v-for="cItem in item.p" :key="cItem">
            {{ $t(`sample.page4.${cItem}`) }}
          </p>
          <div class="dian">. . .</div>
          <img :src="item.url" />
        </div>
      </div>
    </div>
    <!-- 样本数量 -->
    <div class="samples-number">
      <div class="title">{{ $t("home.samples.title1") }}</div>
      <div class="langen" v-if="$i18n.locale === 'zn'">
        SAMPLE SIZE COVERING THE WORLD
      </div>
      <div class="map-box">
        <img src="@/assets/home/map.png" />

        <div
          class="positioning"
          v-for="(item, index) in positioningList"
          :key="index"
          :style="`left:${item.left}px;top:${item.top}px;`"
        >
          <Ripple :width1="item.width1" :width2="item.width2"></Ripple>
        </div>

        <div
          class="positioning2"
          v-for="(item, index) in positioningList2"
          :key="index + 11"
          :style="`left:${item.left}px;top:${item.top}px;`"
        >
          <Ripple :width1="item.width1" :width2="item.width2"></Ripple>
        </div>
        <div
          class="positioning3"
          v-for="(item, index) in positioningList3"
          :key="index + 31"
          :style="`left:${item.left}px;top:${item.top}px;`"
        >
          <Ripple :width1="item.width1" :width2="item.width2"></Ripple>
        </div>
      </div>
      <div class="number-box">
        <div class="title-box" v-for="(item, index) in numberList" :key="index">
          <div class="number">{{ item.Number }}</div>
          <div class="title">{{ $t(`home.samples.map.${item.titie}`) }}</div>
        </div>
      </div>
    </div>

    <!-- 部分国家样本数据 -->
    <div class="some-countries">
      <div class="title">{{ $t("sample.page5.title1") }}</div>
      <div class="langen" v-if="$i18n.locale === 'zn'">
        SAMPLE DATA FROM SOME COUNTRIES
      </div>

      <el-table :data="$t('sample.page5.data')" style="width: 100%">
        <el-table-column prop="flag" fixed width="100">
          <template slot-scope="scope">
            <img :src="require(`@/assets/sample/${scope.row.flag}.png`)" />
          </template>
        </el-table-column>
        <!-- 国家 -->
        <el-table-column
          fixed
          prop="country"
          :label="`${$i18n.locale === 'zn' ? '国家' : 'country'}`"
          min-width="120"
        >
        </el-table-column>
        <!-- 样本总数 -->
        <el-table-column
          prop="samplesNumber"
          :label="`${$i18n.locale === 'zn' ? '样本总数' : 'sample total'}`"
          min-width="150"
        >
        </el-table-column>
        <!-- 男性 -->
        <el-table-column
          prop="male"
          :label="`${$i18n.locale === 'zn' ? '男性' : 'male'}`"
          min-width="120"
        >
        </el-table-column>
        <!-- 女性 -->
        <el-table-column
          prop="woman"
          :label="`${$i18n.locale === 'zn' ? '女性' : 'woman'}`"
          min-width="120"
        >
        </el-table-column>
        <!-- 14-22 -->
        <el-table-column prop="age1" :label="`14-22`" min-width="120">
        </el-table-column>
        <!-- 23-35 -->
        <el-table-column
          prop="age2"
          :label="`23-35`"
          min-width="120"
        ></el-table-column>
        <!-- 36-55 -->
        <el-table-column
          prop="age3"
          :label="`36-55`"
          min-width="120"
        ></el-table-column>
        <!-- 56-80 -->
        <el-table-column
          prop="age4"
          :label="`56-80`"
          min-width="120"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Label from "@/components/Label";
import Ripple from "@/components/Ripple";
export default {
  components: { Label, Ripple },
  data() {
    return {
      movingAmples: false,
      movingChannel: false,
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      timer5: null,
      timer6: null,
      timer7: null,
      timer8: null,
      timer9: null,
      timer10: null,
      leftLabel: [
        {
          left: 187,
          eLeft: 187,
          top: 2,
          eTop: 2,
          size: 14,
        },
        {
          left: 480,
          eLeft: 450,
          top: 4,
          eTop: 4,
          size: 14,
        },
        {
          left: 63,
          eLeft: 63,
          top: 12,
          eTop: 20,
          size: 14,
        },
        {
          left: 295,
          eLeft: 295,
          top: 18,
          eTop: 24,
          size: 14,
        },
        {
          left: 134,
          eLeft: 50,
          top: 60,
          eTop: 70,
          size: 20,
        },
        {
          left: 47,
          eLeft: 47,
          top: 110,
          eTop: 110,
          size: 14,
        },
        {
          left: 410,
          eLeft: 410,
          top: 120,
          eTop: 120,
          size: 14,
        },
        {
          left: 239,
          eLeft: 239,
          top: 130,
          eTop: 130,
          size: 12,
        },
        {
          left: 116,
          eLeft: 116,
          top: 150,
          eTop: 150,
          size: 12,
        },
        {
          left: 370,
          eLeft: 370,
          top: 155,
          eTop: 155,
          size: 12,
        },
      ],
      leftLabel2: [
        {
          left: 37,
          eLeft: 37,
          top: 2,
          eTop: 2,
          size: 13,
        },
        {
          left: 100,
          eLeft: 100,
          top: 4,
          eTop: 4,
          size: 13,
        },
        {
          left: 150,
          eLeft: 150,
          top: 54,
          eTop: 54,
          size: 13,
        },
        {
          left: 350,
          eLeft: 350,
          top: 18,
          eTop: 24,
          size: 13,
        },
        {
          left: 80,
          eLeft: 80,
          top: 60,
          eTop: 70,
          size: 14,
        },
        {
          left: 280,
          eLeft: 280,
          top: 110,
          eTop: 110,
          size: 13,
        },
        {
          left: 210,
          eLeft: 210,
          top: 120,
          eTop: 120,
          size: 13,
        },
        {
          left: 100,
          eLeft: 100,
          top: 130,
          eTop: 130,
          size: 12,
        },
        {
          left: 20,
          eLeft: 20,
          top: 150,
          eTop: 150,
          size: 12,
        },
        {
          left: 340,
          eLeft: 340,
          top: 155,
          eTop: 155,
          size: 12,
        },
      ],
      rightLabel: [
        {
          left: 82,
          eLeft: 82,
          top: 5,
          eTop: 5,
          size: 20,
        },
        {
          left: 360,
          eLeft: 360,
          top: 2,
          eTop: 2,
          size: 16,
        },
        {
          left: 480,
          eLeft: 480,
          top: 40,
          eTop: 40,
          size: 16,
        },
        {
          left: 210,
          eLeft: 210,
          top: 55,
          eTop: 55,
          size: 20,
        },
        {
          left: 309,
          eLeft: 309,
          top: 70,
          eTop: 70,
          size: 28,
        },
        {
          left: 64,
          eLeft: 64,
          top: 90,
          eTop: 90,
          size: 24,
        },
        {
          left: 155,
          eLeft: 155,
          top: 100,
          eTop: 100,
          size: 20,
        },
        {
          left: 223,
          eLeft: 223,
          top: 140,
          eTop: 140,
          size: 16,
        },
        {
          left: 450,
          eLeft: 450,
          top: 130,
          eTop: 130,
          size: 16,
        },
        {
          left: 361,
          eLeft: 361,
          top: 180,
          eTop: 180,
          size: 16,
        },
        {
          left: 64,
          eLeft: 64,
          top: 160,
          eTop: 160,
          size: 16,
        },
      ],
      rightLabel2: [
        {
          left: 42,
          eLeft: 42,
          top: 5,
          eTop: 5,
          size: 16,
        },
        {
          left: 140,
          eLeft: 140,
          top: 2,
          eTop: 2,
          size: 13,
        },
        {
          left: 340,
          eLeft: 340,
          top: 40,
          eTop: 40,
          size: 13,
        },
        {
          left: 240,
          eLeft: 240,
          top: 75,
          eTop: 75,
          size: 16,
        },
        {
          left: 129,
          eLeft: 129,
          top: 50,
          eTop: 50,
          size: 18,
        },
        {
          left: 64,
          eLeft: 64,
          top: 90,
          eTop: 90,
          size: 17,
        },
        {
          left: 155,
          eLeft: 155,
          top: 100,
          eTop: 100,
          size: 16,
        },
        {
          left: 189,
          eLeft: 189,
          top: 180,
          eTop: 180,
          size: 13,
        },
        {
          left: 300,
          eLeft: 300,
          top: 130,
          eTop: 130,
          size: 13,
        },
        {
          left: 361,
          eLeft: 361,
          top: 180,
          eTop: 180,
          size: 13,
        },
        {
          left: 4,
          eLeft: 4,
          top: 160,
          eTop: 160,
          size: 13,
        },
      ],
      owners: [
        {
          url: require("@/assets/sample/owners-1.png"),
          name: "name1",
          p: ["tips1.p1", "tips1.p2", "tips1.p3", "tips1.p4", "tips1.p5"],
        },
        {
          url: require("@/assets/sample/owners-2.png"),
          name: "name2",
          p: ["tips2.p1", "tips2.p2", "tips2.p3", "tips2.p4", "tips2.p5"],
        },
        {
          url: require("@/assets/sample/owners-3.png"),
          name: "name3",
          p: ["tips3.p1", "tips3.p2", "tips3.p3", "tips3.p4"],
        },
        {
          url: require("@/assets/sample/owners-4.png"),
          name: "name4",
          p: ["tips4.p1", "tips4.p2", "tips4.p3"],
        },
        {
          url: require("@/assets/sample/owners-5.png"),
          name: "name5",
          p: ["tips5.p1", "tips5.p2", "tips5.p3", "tips5.p4", "tips5.p5"],
        },
        {
          url: require("@/assets/sample/owners-6.png"),
          name: "name6",
          p: ["tips6.p1", "tips6.p2", "tips6.p3", "tips6.p4", "tips6.p5"],
        },
      ],
      owners1: [
        {
          url: require("@/assets/sample/owners-1.png"),
          name: "name1",
          p: ["tips1.p1", "tips1.p2", "tips1.p3", "tips1.p4", "tips1.p5"],
        },
        {
          url: require("@/assets/sample/owners-2.png"),
          name: "name2",
          p: ["tips2.p1", "tips2.p2", "tips2.p3", "tips2.p4", "tips2.p5"],
        },
        {
          url: require("@/assets/sample/owners-3.png"),
          name: "name3",
          p: ["tips3.p1", "tips3.p2", "tips3.p3", "tips3.p4"],
        },
      ],
      owners2: [
        {
          url: require("@/assets/sample/owners-4.png"),
          name: "name4",
          p: ["tips4.p1", "tips4.p2", "tips4.p3"],
        },
        {
          url: require("@/assets/sample/owners-5.png"),
          name: "name5",
          p: ["tips5.p1", "tips5.p2", "tips5.p3", "tips5.p4", "tips5.p5"],
        },
        {
          url: require("@/assets/sample/owners-6.png"),
          name: "name6",
          p: ["tips6.p1", "tips6.p2", "tips6.p3", "tips6.p4", "tips6.p5"],
        },
      ],
      positioningList: [
        {
          left: 224,
          top: 157,
          width1: 32,
          width2: 16,
        },
        {
          left: 209,
          top: 184,
          width1: 14,
          width2: 6,
        },
        {
          left: 330,
          top: 133,
          width1: 14,
          width2: 6,
        },
        {
          left: 287,
          top: 278,
          width1: 32,
          width2: 16,
        },
        {
          left: 748,
          top: 140,
          width1: 32,
          width2: 16,
        },
        {
          left: 507,
          top: 226,
          width1: 32,
          width2: 16,
        },
        {
          left: 648,
          top: 95,
          width1: 24,
          width2: 12,
        },
        {
          left: 752,
          top: 85,
          width1: 32,
          width2: 16,
        },
        {
          left: 839,
          top: 329,
          width1: 14,
          width2: 6,
        },
      ],
      positioningList2: [
        {
          left: 152,
          top: 106,
          width1: 21,
          width2: 11,
        },
        {
          left: 142,
          top: 125,
          width1: 9,
          width2: 4,
        },
        {
          left: 224,
          top: 90,
          width1: 9,
          width2: 4,
        },
        {
          left: 195,
          top: 189,
          width1: 21,
          width2: 11,
        },
        {
          left: 580,
          top: 95,
          width1: 21,
          width2: 11,
        },
        {
          left: 344,
          top: 153,
          width1: 21,
          width2: 11,
        },
        {
          left: 440,
          top: 64,
          width1: 16,
          width2: 8,
        },
        {
          left: 511,
          top: 57,
          width1: 21,
          width2: 11,
        },
        {
          left: 570,
          top: 223,
          width1: 9,
          width2: 4,
        },
      ],
      positioningList3: [
        {
          left: 76,
          top: 53,
          width1: 11,
          width2: 6,
        },
        {
          left: 71,
          top: 63,
          width1: 5,
          width2: 3,
        },
        {
          left: 112,
          top: 45,
          width1: 5,
          width2: 3,
        },
        {
          left: 96,
          top: 95,
          width1: 11,
          width2: 6,
        },
        {
          left: 290,
          top: 48,
          width1: 11,
          width2: 6,
        },
        {
          left: 172,
          top: 77,
          width1: 11,
          width2: 6,
        },
        {
          left: 220,
          top: 32,
          width1: 8,
          width2: 4,
        },
        {
          left: 255,
          top: 29,
          width1: 11,
          width2: 6,
        },
        {
          left: 285,
          top: 112,
          width1: 5,
          width2: 3,
        },
      ],
      numberList: [
        {
          Number: "400w+",
          titie: "title1",
        },
        { Number: "4000w+", titie: "title2" },
        { Number: "25+", titie: "title3" },
      ],
    };
  },
  methods: {
    scrollToTop() {
      if (this.movingAmples && this.movingChannel) {
        return;
      }
      const el = document.getElementById("phone");
      const rect = el.getBoundingClientRect();
      const vWidth = window.innerWidth || document.documentElement.clientWidth;
      const vHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (
        rect.right < 0 ||
        rect.bottom < 0 ||
        rect.left > vWidth ||
        rect.top > vHeight
      ) {
        // console.log("不在可视区域");
      } else {
        // console.log("在可视区域");
        this.movingAmples = true;
        if (document.getElementById("phone")) {
          document.getElementById("phone").className = "phone active";
        }
        this.timer1 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx charge");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx charge active";
          }
        }, 1000);
        this.timer2 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx people");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx people active";
          }
        }, 2000);
        this.timer3 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx slide");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx slide active";
          }
        }, 3000);
      }

      //第二个动画
      const el2 = document.getElementById("application");
      const rect2 = el2.getBoundingClientRect();

      if (
        rect2.right < 0 ||
        rect2.bottom < 0 ||
        rect2.left > vWidth ||
        rect2.top > vHeight
      ) {
        // console.log("不在可视区域");
      } else {
        // console.log("在可视区域");
        this.movingChannel = true;
        document.getElementById("application").className = "application active";
        this.timer5 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx-img toutiao");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx-img toutiao active";
          }
        }, 1000);
        this.timer6 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx-img baidu");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx-img baidu active";
          }
        }, 2000);
        this.timer7 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx-img qq");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx-img qq active";
          }
        }, 3000);
        this.timer8 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx-img jy");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx-img jy active";
          }
        }, 4000);
        this.timer10 = setTimeout(() => {
          const dom = document.getElementsByClassName("felx-img txxw");
          if (dom && dom.length !== 0) {
            dom[0].className = "felx-img txxw active";
          }
        }, 5000);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
    if (!this.movingAmples || !this.movingChannel) {
      this.scrollToTop();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop, true);
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    clearTimeout(this.timer5);
    clearTimeout(this.timer6);
    clearTimeout(this.timer7);
    clearTimeout(this.timer8);
    clearTimeout(this.timer9);
    clearTimeout(this.timer10);
  },
};
</script>

<style lang="scss" scoped>
.main {
  // 第一屏
  .sample-top {
    width: 100%;
    height: 414px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/sample/bg2.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    .sample-box {
      position: absolute;
      top: 0;
      width: calc(100% - 40px);
      padding: 0 20px;
      height: 100%;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-blod {
        margin-top: 183px;
        font-size: 40px;
        color: #fff;
        display: flex;
        font-weight: bolder;
        .left {
          margin-right: 28px;
        }
      }
      .title-tips {
        font-size: 14px;
        color: #fff;
        margin-top: 31px;
        text-align: center;
      }
    }
  }
  //   移动样本
  .moving-amples {
    width: calc(100% - 240px);
    margin-left: 120px;
    display: flex;
    align-items: center;
    margin-top: 80px;
    padding: 17px 0;
    .left {
      display: flex;
      flex-direction: column;
      width: 500px;
      flex-shrink: 0;
      .title-box {
        display: flex;
        margin-bottom: 60px;
        .title-left {
          color: #232323;
          font-size: 28px;
          font-weight: bolder;
        }
        .title-right {
          color: #232323;
          font-size: 28px;
          margin-left: 30px;
        }
      }
      .tips-box {
        font-size: 24px;
        color: #232323;
        margin-bottom: 25px;
      }
      .conten-box {
        color: #232323;
        font-size: 14px;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding-left: 52px;
      .icon {
        width: 40px;
        height: 40px;
        opacity: 0;
      }
      .icon-active {
        opacity: 1;
      }
      .active {
        opacity: 1 !important;
        transition: opacity 1.2s ease 1s;
      }
      .phone-box {
        width: 566px;
        height: 444px;
        position: relative;
        .phone {
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        .felx {
          position: absolute;
        }
        .charge {
          top: 116px;
          left: 222px;
          width: 34px;
          height: 34px;
          opacity: 0;
        }
        .people {
          top: 167px;
          left: 166px;
          width: 146px;
          height: 146px;
          opacity: 0;
        }
        .slide {
          top: 330px;
          left: 214px;
          width: 50px;
          height: 50px;
          opacity: 0;
        }
      }
    }
  }
  //   自有样本
  .own-samples {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 240px);
    margin-left: 120px;
    margin-top: 180px;
    position: relative;
    .left {
      min-height: 480px;
      width: calc(50% - 10px);
      background: linear-gradient(180deg, #fff6f5 0%, #fff5f5 100%);
      .left-title {
        font-size: 24px;
        margin-top: 63px;
        text-align: center;
        color: #232323;
        margin-bottom: 30px;
      }
      .left-tips {
        text-align: center;
        color: #666666;
        font-size: 14px;
        margin-bottom: 9px;
      }
    }
    .right {
      min-height: 480px;
      width: calc(50% - 10px);
      background: linear-gradient(180deg, #eef9ff 0%, #edf9ff 100%);
      .right-title {
        font-size: 24px;
        margin-top: 63px;
        text-align: center;
        color: #232323;
        margin-bottom: 30px;
      }
      .right-tips {
        text-align: center;
        color: #666666;
        font-size: 14px;
        margin-bottom: 9px;
      }
    }
    .own-box {
      position: absolute;
      width: 264px;
      height: 264px;
      top: -132px;
      left: 50%;
      transform: translateX(-50%);
      .rotate-box {
        width: 100%;
        height: 100%;
        position: relative;
        .rotate-img {
          width: 100%;
          height: 100%;
          background-image: url("../assets/sample/rotate.png");
          background-size: 100% 100%;
          animation: rotation 5s linear infinite;
        }
        .round {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 160px;
          height: 160px;
          border-radius: 80px;
          background: linear-gradient(180deg, #6eb1ff 0%, #006cea 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 22px;
          .title-box {
            margin-top: 15px;
          }
        }
      }
    }
    .box-label {
      margin-top: 10px;
      height: 200px;
      margin-top: 47px;
      position: relative;
    }
    .box-label2 {
      display: none;
    }
  }
  // 多渠道流量投放
  .multi-channel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 180px;
    position: relative;
    background-color: #f7fbff;
    padding: 40px 0;
    .left {
      width: calc(50% -120px);
      flex-shrink: 0;
      margin-left: 120px;
      display: flex;
      justify-content: center;
      position: relative;
      .application {
        width: 495px;
        height: 496px;
        opacity: 0;
      }
      .active {
        opacity: 1 !important;
        transition: opacity 1.2s ease 1s;
      }
      .felx-img {
        position: absolute;
        opacity: 0;
      }
      .baidu {
        width: 74px;
        height: 74px;
        left: 6px;
        top: 60px;
      }
      .toutiao {
        width: 68px;
        height:68px;
        left: 242px;
        top: 26px;
      }
      .qq {
        width: 65px;
        height: 65x;
        left: 453px;
        top: 121px;
      }
      .jy {
        width: 48px;
        height: 48px;
        left: 55px;
        top: 229px;
      }
      .jd {
        width: 58px;
        height: 58px;
        left: 124px;
        top: 396px;
      }
      .txxw {
        width: 68px;
        height: 68px;
        left: 408px;
        top: 367px;
      }
    }
    .right {
      width: calc(50% -120px);
      flex-shrink: 0;
      margin-right: 120px;
      text-align: right;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 28px;
        color: #323233;
        margin-bottom: 14px;
      }
      .tips-box {
        max-width: 500px;
        font-size: 16px;
        color: #323233;
        margin-top: 53px;
        word-wrap: break-word;
        .tips {
          word-wrap: break-word;
        }
      }
    }
  }
  //车主样本
  .car-owners {
    width: 100%;
    height: 535px;
    background-image: url("../assets/sample/sample1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: 78px;
    position: relative;
    .title {
      text-align: center;
      padding-top: 95px;
      color: #fff;
      font-size: 40px;
    }
    .tips {
      text-align: center;
      margin-top: 22px;
      color: #fff;
      font-size: 16px;
    }
    .card-boxs {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      bottom: -84px;
      .card-item {
        width: 138px;
        height: 224px;
        background: #0b7cff;
        border-radius: 8px 8px 8px 8px;
        margin-right: 16px;
        padding: 24px;
        position: relative;
        .name {
          color: #fff;
          font-size: 14px;
          margin-bottom: 28px;
        }
        p {
          color: #fff;
          font-size: 14px;
          margin-bottom: 10px;
        }
        .dian {
          color: #fff;
          font-size: 14px;
        }
        img {
          position: absolute;
          width: 30px;
          height: 30px;
          bottom: 20px;
          right: 20px;
        }
      }
      .card-item:last-child {
        margin-right: 0;
      }
    }
    .card-boxs2 {
      display: none;
      position: absolute;
      flex-direction: column;
      width: 100%;
      bottom: -284px;
      .card-fa {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .card-item {
          width: 138px;
          height: 224px;
          background: #0b7cff;
          border-radius: 8px 8px 8px 8px;

          padding: 24px;
          position: relative;
          .name {
            color: #fff;
            font-size: 14px;
            margin-bottom: 28px;
          }
          p {
            color: #fff;
            font-size: 14px;
            margin-bottom: 10px;
          }
          .dian {
            color: #fff;
            font-size: 14px;
          }
          img {
            position: absolute;
            width: 30px;
            height: 30px;
            bottom: 20px;
            right: 20px;
          }
        }
      }
      .card-fa:last-child {
        margin-top: 20px;
      }
    }
    .card-boxs3 {
      display: none;
    }
  }
  .car-owners2 {
    display: none;
  }
  //样本数量
  .samples-number {
    margin-top: 159px;
    width: calc(100% - 240px);
    margin-left: 120px;
    .title {
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      text-align: center;
    }
    .langen {
      font-size: 22px;
      color: #999999;
      margin-top: 11px;
      text-align: center;
    }
    .map-box {
      width: 1026px;
      height: 434px;
      margin-top: 60px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
      }
      .positioning {
        position: absolute;
        z-index: 99;
      }
      .positioning2 {
        position: absolute;
        z-index: 99;
        display: none;
      }
      .positioning3 {
        position: absolute;
        z-index: 99;
        display: none;
      }
    }
    .number-box {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      .title-box {
        margin-right: 250px;
        .number {
          color: #f83838;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 2px;
          text-align: center;
        }
        .title {
          font-size: 18px;
          color: #232323;
          text-align: center;
          margin-top: 24px;
        }
      }
      .title-box:last-child {
        margin-right: 0;
      }
    }
  }
  // 部分国家样本数据
  .some-countries {
    width: calc(100% - 240px);
    margin-left: 120px;
    margin-bottom: 56px;
    margin-top: 165px;
    .title {
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      text-align: center;
    }
    .langen {
      font-size: 22px;
      color: #999999;
      margin-top: 11px;
      text-align: center;
    }
    .el-table {
      margin-top: 56px;
      img {
        width: 69px;
        height: 32px;
      }
    }
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style scoped>
.el-table >>> .el-table__header {
  background-color: #fff;
}
.el-table >>> .el-table__header .cell {
  color: #000;
  font-size: 16px;
}
.el-table >>> .el-table__body .cell {
  color: #333;
  font-size: 14px;
}
.el-table >>> .el-table__cell {
  padding: 8px 0;
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 1400px) {
  .main {
    .own-samples {
      width: calc(100% - 80px);
      margin-left: 40px;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .main {
    //   移动样本
    .moving-amples {
      width: calc(100% - 240px);
      margin-left: 120px;
      display: flex;
      align-items: center;
      margin-top: 80px;
      padding: 17px 0;
      .left {
        display: flex;
        flex-direction: column;
        width: 400px;
        flex-shrink: 0;
      }
      .right {
        padding-left: 30px;
        .phone-box {
          width: 400px;
          height: 313px;
          .charge {
            top: 81px;
            left: 156px;
            width: 24px;
            height: 24px;
            opacity: 0;
          }
          .people {
            top: 118px;
            left: 117px;
            width: 103px;
            height: 103px;
            opacity: 0;
          }
          .slide {
            top: 233px;
            left: 151px;
            width: 35px;
            height: 35px;
            opacity: 0;
          }
        }
      }
    }
    //自由样本
    .own-samples {
      display: flex;
      flex-direction: column;
      margin-top: 300px;
      .left {
        width: 100%;
      }
      .right {
        min-height: 480px;
        width: 100%;
        margin-top: 20px;
        .right-title {
          font-size: 24px;
          margin-top: 63px;
          text-align: center;
          color: #232323;
          margin-bottom: 30px;
        }
        .right-tips {
          text-align: center;
          color: #666666;
          font-size: 14px;
          margin-bottom: 9px;
        }
      }
      .own-box {
        position: absolute;
        width: 264px;
        height: 264px;
        top: -300px;
        left: 50%;
        transform: translateX(-50%);
        .rotate-box {
          width: 100%;
          height: 100%;
          position: relative;
          .rotate-img {
            width: 100%;
            height: 100%;
            background-image: url("../assets/sample/rotate.png");
            background-size: 100% 100%;
          }
          .round {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 160px;
            height: 160px;
            border-radius: 80px;
            background: linear-gradient(180deg, #6eb1ff 0%, #006cea 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 22px;
            .title-box {
              margin-top: 15px;
            }
          }
        }
      }
      .box-label {
        margin-top: 10px;
        height: 200px;
        margin-top: 47px;
        width: 600px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .multi-channel {
      .left {
        width: 400px;
        margin-left: 40px;
        .application {
          width: 400px;
          height: 400px;
          opacity: 0;
        }
        .felx-img {
          position: absolute;
          opacity: 0;
        }
        .baidu {
          width: 59px;
          height: 59px;
          left: 5px;
          top: 48px;
        }
        .toutiao {
          width: 55px;
          height: 55px;
          left: 195px;
          top: 20px;
        }
        .qq {
          width: 52px;
          height: 52px;
          left: 366px;
          top: 97px;
        }
        .jy {
          width: 39px;
          height: 39px;
          left: 44px;
          top: 184px;
        }
        .jd {
          width: 42px;
          height: 42px;
          left: 90px;
          top: 289px;
        }
        .txxw {
          width: 55px;
          height: 55px;
          left: 329px;
          top: 296px;
        }
      }
      .right {
        width: calc(100% - 480px);
        margin-right: 40px;
        .tips-box {
          width: 100%;
          max-width: 100%;
          text-align: right;
        }
      }
    }
    .car-owners {
      .card-boxs {
        display: none;
      }
      .card-boxs2 {
        display: flex;
      }
    }
    .samples-number {
      margin-top: 400px;
    }
  }
}

@media only screen and (max-width: 1026px) {
  .main {
    .samples-number {
      .map-box {
        width: 700px;
        height: 296px;
        .positioning {
          display: none;
        }
        .positioning2 {
          display: block;
        }
      }
      .number-box {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .title-box {
          margin-right: 100px;
          .number {
            color: #f83838;
            font-size: 40px;
            font-weight: bold;
            letter-spacing: 2px;
            text-align: center;
          }
          .title {
            font-size: 18px;
            color: #232323;
            text-align: center;
            margin-top: 24px;
          }
        }
        .title-box:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .main {
    // 第一屏
    .sample-top {
      .sample-box {
        .title-blod {
          margin-top: 140px;
          font-size: 30px;
          .left {
            margin-right: 28px;
          }
        }
      }
    }
    //   移动样本
    .moving-amples {
      width: calc(100% - 80px);
      margin-left: 40px;
    }
    .multi-channel {
      display: block;
      padding-bottom: 550px;
      padding-top: 50px;
      .left {
        width: 400px;
        margin-left: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 300px;
        .application {
          width: 400px;
          height: 400px;
          opacity: 0;
        }
        .felx-img {
          position: absolute;
          opacity: 0;
        }
        .baidu {
          width: 59px;
          height: 59px;
          left: 5px;
          top: 48px;
        }
        .toutiao {
          width: 55px;
          height: 55px;
          left: 195px;
          top: 20px;
        }
        .qq {
          width: 52px;
          height: 52px;
          left: 366px;
          top: 97px;
        }
        .jy {
          width: 39px;
          height: 39px;
          left: 44px;
          top: 184px;
        }
        .jd {
          width: 42px;
          height: 42px;
          left: 90px;
          top: 289px;
        }
        .txxw {
          width: 55px;
          height: 55px;
          left: 329px;
          top: 296px;
        }
      }
      .right {
        width: calc(100% - 40px);
        padding: 0 20px;
        margin-right: 0px;
        text-align: center;
        .title {
          font-size: 20px;
        }
        .tips-box {
          width: 100%;
          text-align: center;
          margin-top: 20px;
          font-size: 14px;
        }
      }
    }
    .car-owners {
      .title {
        font-size: 20px;
      }
      .tips {
        font-size: 14px;
      }
      .card-boxs {
        display: none;
      }
      .card-boxs2 {
        display: flex;
      }
    }
    .some-countries {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-bottom: 56px;
      margin-top: 165px;
    }
  }
}

@media only screen and (max-width: 870px) {
  .main {
    //   移动样本
    .moving-amples {
      width: calc(100% - 80px);
      margin-left: 40px;
      flex-direction: column;
      margin-top: 40px;
      .left {
        width: 100%;
        .title-box {
          justify-content: center;
        }
        .tips-box {
          text-align: center;
        }
        .conten-box {
          text-align: center;
        }
      }
      .right {
        width: 100%;
        padding: 0;
        justify-content: center;
        margin-top: 34px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .main {
    // 第一屏
    .sample-top {
      .sample-box {
        align-items: flex-start;
        .title-blod {
          display: flex;
          flex-wrap: wrap;
          margin-top: 140px;
          font-size: 18px;
          text-align: left;
          .left {
            margin-right: 28px;
            text-align: left;
            margin-top: 20px;
          }
          .right {
            margin-top: 20px;
          }
        }
        .title-tips {
          text-align: left;
        }
      }
    }
    //   移动样本
    .moving-amples {
      width: calc(100% - 40px);
      margin-left: 20px;
      .left {
        width: 100%;
        .title-box {
          justify-content: center;
          .title-left {
            font-size: 20px;
          }
          .title-right {
            font-size: 20px;
          }
        }
        .tips-box {
          text-align: center;
          font-size: 16px;
        }
        .conten-box {
          text-align: center;
          font-size: 12px;
        }
      }
      .right {
        width: 100%;
        padding: 0;
        justify-content: center;
        margin-top: 34px;
      }
    }
    //自由样本
    .own-samples {
      display: flex;
      flex-direction: column;
      margin-top: 300px;
      width: calc(100% - 20px);
      margin-left: 10px;
      .left {
        width: 100%;
      }
      .right {
        min-height: 480px;
        width: 100%;
        margin-top: 20px;
        .right-title {
          font-size: 24px;
          margin-top: 63px;
          text-align: center;
          color: #232323;
          margin-bottom: 30px;
        }
        .right-tips {
          text-align: center;
          color: #666666;
          font-size: 14px;
          margin-bottom: 9px;
        }
      }
      .own-box {
        position: absolute;
        width: 264px;
        height: 264px;
        top: -300px;
        left: 50%;
        transform: translateX(-50%);
        .rotate-box {
          width: 100%;
          height: 100%;
          position: relative;
          .rotate-img {
            width: 100%;
            height: 100%;
            background-image: url("../assets/sample/rotate.png");
            background-size: 100% 100%;
          }
          .round {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 160px;
            height: 160px;
            border-radius: 80px;
            background: linear-gradient(180deg, #6eb1ff 0%, #006cea 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 22px;
            .title-box {
              margin-top: 15px;
            }
          }
        }
      }
      .box-label {
        display: none;
      }
      .box-label2 {
        display: block;
        margin-top: 10px;
        height: 200px;
        margin-top: 47px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 400px;
      }
    }
    .car-owners {
      display: none;
    }
    .car-owners2 {
      display: block;
      width: 100%;
      background: linear-gradient(
        360deg,
        rgba(30, 38, 244, 0.2) 0%,
        #0b7cff 100%
      );
      margin-top: 10px;
      position: relative;
      .title {
        text-align: center;
        padding: 0 20px;
        padding-top: 50px;
        color: #fff;
        font-size: 18px;
      }
      .tips {
        text-align: center;
        margin-top: 30px;
        color: #fff;
        margin-bottom: 56px;
        font-size: 14px;
        padding: 0 20px;
      }
      .card-boxs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        .card-item {
          width: 138px;
          height: 224px;
          background: #0b7cff;
          border-radius: 8px 8px 8px 8px;
          padding: 24px;
          position: relative;
          margin-bottom: 20px;
          .name {
            color: #fff;
            font-size: 14px;
            margin-bottom: 28px;
          }
          p {
            color: #fff;
            font-size: 14px;
            margin-bottom: 10px;
          }
          .dian {
            color: #fff;
            font-size: 14px;
          }
          img {
            position: absolute;
            width: 30px;
            height: 30px;
            bottom: 20px;
            right: 20px;
          }
        }
      }
    }
    .samples-number {
      margin-top: 70px;
      .title {
        font-size: 20px;
      }
      .langen {
        font-size: 16px;
      }
      .map-box {
        width: 355px;
        height: 140px;
        .positioning {
          display: none;
        }
        .positioning2 {
          display: none;
        }
        .positioning3 {
          display: block;
        }
      }
      .number-box {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .title-box {
          margin-right: 40px;
          .number {
            font-size: 16px;
          }
          .title {
            font-size: 14px;
            margin-top: 22px;
          }
        }
        .title-box:last-child {
          margin-right: 0;
        }
      }
    }
    .some-countries {
      width: calc(100% - 40px);
      margin-left: 2ch;
      margin-bottom: 56px;
      margin-top: 100px;
      .title{
        font-size: 20px;
      }
       .langen {
        font-size: 16px;
      }
    }
  }
}
</style>