import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // nprogress样式文件
import i18n from "./lang";

//个性化配置
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

//当路由开始跳转时
router.beforeEach((to, from, next) => {
  //开启进度条
  if (i18n.locale === "zn") {
    document.title = to.meta.title;
  } else {
    document.title = to.meta.eTitle;
  }
  NProgress.start();
  next();
});

//当路由跳转结束后
router.afterEach((to, from) => {
  // 关闭进度条
  NProgress.done();
});
