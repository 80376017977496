<template>
  <div class="main">
    <!-- 第一屏 -->
    <div class="news-top">
      <div class="title">{{ $t("newsDetails.page1.title1") }}</div>
    </div>
    <!-- 主要内容 -->
    <div class="content" v-if="newItem">
      <div class="content-left">
        <div class="title">{{ newItem.btitle }}</div>
        <div class="time-box">
          <img class="clock" src="@/assets/detaild/clock.png" />
          <div>{{ newItem.bUpdateTime }}</div>
        </div>

        <div class="content-box" v-html="newItem.bcontent"></div>
      </div>
      <div class="content-right">
        <div class="right-box">
          <div class="go-list">
            <img @click="jump" src="@/assets/detaild/arrow.png" />
            <span @click="jump"> {{ $t("newsDetails.page1.title4") }}</span>
          </div>
          <div class="box-list">
            <div class="new-list">
              <div class="title">{{ $t("newsDetails.page1.title2") }}</div>
              <div
                class="list"
                v-for="(item, index) in superList"
                @click="changeNew(item.id)"
                :key="index"
              >
                <div class="list-contet">
                  <div class="list-left">
                    <div class="time-box">
                      <img class="clock" src="@/assets/detaild/clock.png" />
                      <span>{{ item.updateTime }}</span>
                    </div>
                    <div class="list-title">{{ item.title }}</div>
                  </div>
                  <img
                    class="list-right"
                    v-if="item.thumbUrl"
                    :src="item.thumbUrl"
                  />
                  <img class="list-right" v-else src="@/assets/news/img2.png" />
                </div>
                <div class="line"></div>
              </div>
              <div class="see-more">
                <span @click="jump">{{ $t("newsDetails.page1.title3") }}</span>
                <img
                  @click="jump"
                  class="arrow2"
                  src="@/assets/detaild/arrow2.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2" v-if="newItem">
      <div class="go-list">
        <img @click="jump" src="@/assets/detaild/arrow.png" />
        <span @click="jump"> {{ $t("newsDetails.page1.title4") }}</span>
      </div>
      <div class="title">{{ newItem.btitle }}</div>
      <div class="time-box">
        <img class="clock" src="@/assets/detaild/clock.png" />
        <div>{{ newItem.bUpdateTime }}</div>
      </div>
      <div class="content-box" v-html="newItem.bcontent"></div>
    </div>
  </div>
</template>
<script>
import { getBlogId, getSuper } from "@/api/app";
export default {
  data() {
    return {
      newItem: null,
      superList: [],
      id: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (!this.id) {
      return;
    }
    this.getBlogItem();

    getSuper().then((res) => {
      console.log("getSuper", res);
      if (res.status === 200) {
        this.superList = res.response;
      }
    });
  },
  methods: {
    jump() {
      this.$router.push("/news");
    },
    changeNew(id) {
      this.id = id;
      this.getBlogItem();
    },
    getBlogItem() {
      getBlogId({ id: this.id }).then((res) => {
        if (res.status === 200) {
          this.newItem = res.response;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  .news-top {
    width: 100%;
    height: 422px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/news/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    .title {
      font-size: 38px;
      color: #fff;
      text-align: center;
      padding-top: 126px;
    }
  }
  .content {
    width: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    display: flex;
    margin-top: 67px;
    padding-bottom: 100px;
    .content-left {
      width: 100%;
      .title {
        font-size: 24px;
        color: #232323;
        font-weight: bolder;
      }
      .time-box {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #232323;
        margin-top: 20px;
        .clock {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
      .content-box {
        font-size: 14px;
        color: #232323;
        line-height: 24px;
        margin-top: 58px;
      }
    }
    .content-right {
      flex-shrink: 0;
      width: 388px;
      height: auto;
      box-sizing: border-box;
      margin-left: 16px;
      .right-box {
        width: 100%;
        margin-top: 86px;
        .go-list {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          color: #232323;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            cursor: pointer;
          }
          span {
            cursor: pointer;
          }
        }
        .box-list {
          margin-top: 25px;
          width: 100%;
          background: #f7f7f7;
          border-radius: 4px;
          padding: 10px;
          box-sizing: border-box;
          .new-list {
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 15px;
            box-sizing: border-box;
            .title {
              font-size: 14px;
              font-weight: bolder;
              color: #232323;
            }
            .list {
              cursor: pointer;
              .list-contet {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 0 27px 0;
                .list-left {
                  display: flex;
                  flex-direction: column;
                  .time-box {
                    display: flex;
                    font-size: 14px;
                    color: #232323;
                    .clock {
                      width: 18px;
                      height: 18px;
                      margin-right: 10px;
                    }
                  }
                  .list-title {
                    font-size: 14px;
                    color: #232323;
                    margin-top: 16px;
                    margin-left: 10px;
                  }
                }
                .list-right {
                  width: 149px;
                  height: 78px;
                  flex-shrink: 0;
                }
              }
              .line {
                width: 100%;
                height: 0px;
                opacity: 1;
                border: 1px solid #eaeaea;
              }
            }
            .see-more {
              display: flex;
              align-items: center;
              margin: 31px 0 10px 0;

              .arrow2 {
                width: 14px;
                height: 14px;
                margin-left: 10px;
                cursor: pointer;
              }
              span {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .content2 {
    display: none;
    padding: 20px;
    box-sizing: border-box;
    .go-list {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      color: #232323;
      align-items: center;
      margin-top: 4px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        cursor: pointer;
      }
      span {
        cursor: pointer;
      }
    }
    .title {
      font-size: 24px;
      color: #232323;
      font-weight: bolder;
      margin-top: 24px;
    }
    .time-box {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #232323;
      margin-top: 24px;
      .clock {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    .content-box {
      font-size: 14px;
      color: #232323;
      line-height: 24px;
      margin-top: 30px;
    }
  }
}
</style>
<style scoped>
.main >>> .el-tabs__active-bar {
  background-color: #0b7cff;
}
.main >>> .el-tabs__item.is-active {
  color: #0b7cff;
}
.main >>> .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #ededed;
}

.main >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0b7cff;
}
.content-box >>> img {
  max-width: 100%;
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1200px) {
  .main {
    .content {
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      display: flex;
      margin-top: 67px;
      padding-bottom: 100px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .main {
    .news-top {
      .title {
        font-size: 38px;
        color: #fff;
        text-align: center;
        padding-top: 126px;
      }
    }
    .content {
      display: none;
    }
    .content2 {
      display: block;
    }
  }
}
@media only screen and (max-width: 750px) {
  .main {
    .news-top {
      height: 300px;
      .title {
        font-size: 20px;
        color: #fff;
        text-align: center;
        padding-top: 126px;
      }
    }
  }
}
</style>